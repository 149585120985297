import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { RadioGroup } from '@headlessui/react';
import { CalendarIcon, ClockIcon, LightBulbIcon } from '@heroicons/react/24/outline';
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import axios from '../../../axios';

const TimingStep = ({ formData, setFormData }) => {
  const { t } = useTranslation();
  const [selectedDate, setSelectedDate] = useState(formData.deadline || null);
  const [deadlineType, setDeadlineType] = useState(selectedDate ? 'deadline' : 'no-deadline');
  const [template, setTemplate] = useState(null);
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const formatRelativeDate = (date) => {
    if (!date) return '';
    const diff = Math.floor((new Date(date) - today) / (1000 * 60 * 60 * 24));
    return t('{{days}} days from now', { days: diff });
  };

  const getNextRepetitionDate = (periodType) => {
    const reminderDate = new Date();
    reminderDate.setDate(reminderDate.getDate() - 7); // Set to a week earlier

    switch (periodType) {
      case 'monthly':
        reminderDate.setMonth(reminderDate.getMonth() + 1);
        break;
      case 'quarterly':
        reminderDate.setMonth(reminderDate.getMonth() + 3);
        break;
      case 'yearly':
        reminderDate.setFullYear(reminderDate.getFullYear() + 1);
        break;
      default:
        return null;
    }
    return reminderDate.toLocaleDateString();
  };

  const getAvailableReminders = (deadline) => {
    if (!deadline) return [];

    const deadlineDate = new Date(deadline);
    const diffDays = Math.floor((deadlineDate - today) / (1000 * 60 * 60 * 24));

    return [
      { id: 'month', days: 30, label: t('1 month before'), available: diffDays >= 30, remaining: Math.max(0, diffDays - 30) },
      { id: 'week', days: 7, label: t('1 week before'), available: diffDays >= 7, remaining: Math.max(0, diffDays - 7) },
      { id: 'day', days: 1, label: t('24 hours before'), available: diffDays >= 1, remaining: Math.max(0, diffDays - 1) }
    ];
  };

  useEffect(() => {
    if (!selectedDate) {
      setFormData({ ...formData, reminders: [] });
    } else {
      const availableReminders = getAvailableReminders(selectedDate);
      const validReminders = formData.reminders.filter(reminder =>
        availableReminders.some(r => r.id === reminder && r.available)
      );
      setFormData({ ...formData, reminders: validReminders });
    }
  }, [selectedDate]);

  useEffect(() => {
    const fetchTemplate = async () => {
      if (!formData.templateId) return;

      try {
        const response = await axios.get(`/api/v1/data_collections/templates/${formData.templateId}`, {
          headers: { Authorization: `Bearer ${sessionStorage.getItem('authToken')}` }
        });
        console.log('Template data:', response.data); // Debug log
        setTemplate(response.data);

        // If recommended period type exists and no period type is selected yet
        if (response.data.recommended_period_type && !formData.periodType) {
          setFormData(prev => ({
            ...prev,
            periodType: response.data.recommended_period_type
          }));
        }
      } catch (error) {
        console.error('Error fetching template:', error);
      }
    };

    fetchTemplate();
  }, [formData.templateId]);

  const handleReminderToggle = (reminderId) => {
    const newReminders = formData.reminders.includes(reminderId)
      ? formData.reminders.filter(r => r !== reminderId)
      : [...formData.reminders, reminderId];
    setFormData({ ...formData, reminders: newReminders });
  };

  const handleDeadlineChange = (date) => {
    setSelectedDate(date);
    setFormData({ ...formData, deadline: date });
  };

  const handleDeadlineTypeChange = (type) => {
    setDeadlineType(type);
    setFormData({ ...formData, deadlineType: type });
    if (type === 'no-deadline') {
      handleDeadlineChange(null);
    }
  };

  return (
    <div className="space-y-8">
      {/* Deadline Section */}
      <div className="bg-white rounded-lg p-6">
        <h3 className="text-lg font-medium text-gray-900 mb-4">{t('Deadline')}</h3>

        <RadioGroup value={deadlineType} onChange={handleDeadlineTypeChange}>
          <div className="space-y-4">
            <RadioGroup.Option
              value="no-deadline"
              className={({ checked }) =>
                `${checked
                  ? 'bg-secondary-color text-white border-secondary-color ring-2 ring-secondary-color'
                  : 'bg-white text-gray-900 border-gray-300'
                } relative rounded-lg border p-4 cursor-pointer focus:outline-none`
              }
            >
              {({ checked }) => (
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <div className="text-sm">
                      <RadioGroup.Label as="p" className={`font-medium ${checked ? 'text-white' : 'text-gray-900'}`}>
                        {t('No Deadline')}
                      </RadioGroup.Label>
                      <RadioGroup.Description as="p" className={`text-sm ${checked ? 'text-gray-200' : 'text-gray-500'}`}>
                        {t('Collection will remain open until finished or manually closed')}
                      </RadioGroup.Description>
                    </div>
                  </div>
                  {checked && <CheckCircleIcon className="h-5 w-5 text-white" />}
                </div>
              )}
            </RadioGroup.Option>

            <RadioGroup.Option
              value="deadline"
              className={({ checked }) =>
                `${checked
                  ? 'bg-secondary-color text-white border-secondary-color ring-2 ring-secondary-color'
                  : 'bg-white text-gray-900 border-gray-300'
                } relative rounded-lg border p-4 cursor-pointer focus:outline-none`
              }
            >
              {({ checked }) => (
                <>
                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <div className="text-sm">
                        <RadioGroup.Label as="p" className={`font-medium ${checked ? 'text-white' : 'text-gray-900'}`}>
                          {t('Set Deadline')}
                        </RadioGroup.Label>
                        <RadioGroup.Description as="p" className={`text-sm ${checked ? 'text-gray-200' : 'text-gray-500'}`}>
                          {t('Collection will close on the specified date')}
                        </RadioGroup.Description>
                      </div>
                    </div>
                    {checked && <CheckCircleIcon className="h-5 w-5 text-white" />}
                  </div>
                  {checked && (
                    <div className="mt-4">
                      <input
                        type="date"
                        min={today.toISOString().split('T')[0]}
                        value={selectedDate || ''}
                        onChange={(e) => handleDeadlineChange(e.target.value)}
                        className="block w-full rounded-md border-gray-300 shadow-sm text-gray-800 focus:border-secondary-color focus:ring-secondary-color sm:text-sm"
                      />
                      {selectedDate && (
                        <p className="mt-2 text-sm text-white">{formatRelativeDate(selectedDate)}</p>
                      )}
                    </div>
                  )}
                </>
              )}
            </RadioGroup.Option>
          </div>
        </RadioGroup>
      </div>

      {/* Reminders Section */}
      {selectedDate && (
        <div className="bg-white rounded-lg p-6">
          <h3 className="text-lg font-medium text-gray-900 mb-4">{t('Reminders')}</h3>
          <div className="space-y-4">
            {getAvailableReminders(selectedDate).map(({ id, label, available }) => {
              // Calculate reminder date if available
              let reminderDate = null;
              if (available) {
                reminderDate = new Date(selectedDate);
                switch (id) {
                  case 'month':
                    reminderDate.setDate(reminderDate.getDate() - 30);
                    break;
                  case 'week':
                    reminderDate.setDate(reminderDate.getDate() - 7);
                    break;
                  case 'day':
                    reminderDate.setDate(reminderDate.getDate() - 1);
                    break;
                }
              }

              return (
                <div key={id} className="relative flex items-start">
                  <div className="flex h-5 items-center">
                    <input
                      type="checkbox"
                      checked={formData.reminders.includes(id)}
                      onChange={() => handleReminderToggle(id)}
                      disabled={!available}
                      className={`h-4 w-4 rounded border-gray-300 ${available
                          ? 'text-secondary-color focus:ring-secondary-color'
                          : 'text-gray-300 cursor-not-allowed'
                        }`}
                    />
                  </div>
                  <div className="ml-3 text-sm">
                    <label className={`font-medium ${available ? 'text-gray-700' : 'text-gray-400'}`}>
                      {label}
                      {available ? (
                        <span className="ml-2 text-gray-500">
                          {t('(reminder on {{date}})', {
                            date: reminderDate.toLocaleDateString()
                          })}
                        </span>
                      ) : (
                        <span className="ml-2 text-gray-400 italic">
                          {t('(deadline is closer than reminder)')}
                        </span>
                      )}
                    </label>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}

      {/* Repetition Section */}
      <div className="bg-white rounded-lg p-6">
        <div className="flex items-start">
          <div className="flex h-5 items-center">
            <input
              type="checkbox"
              checked={formData.periodType !== null}
              onChange={(e) => setFormData({
                ...formData,
                periodType: e.target.checked ?
                  (template?.recommended_period_type || 'monthly') :
                  null
              })}
              className="h-4 w-4 rounded border-gray-300 text-secondary-color focus:ring-secondary-color"
            />
          </div>
          <div className="ml-3">
            <label className="text-lg font-medium text-gray-900">
              <div className="flex items-center">
                {t('Repeat this data collection')}
                {template?.recommended_period_type && (
                  <span className="ml-2 inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-yellow-100 text-yellow-800">
                    <LightBulbIcon className="h-3 w-3 mr-1" />
                    {t('Recommended: {{period}}', { period: t(template.recommended_period_type) })}
                  </span>
                )}
              </div>
            </label>
            <p className="text-sm text-gray-500">
              {t('You will be reminded to repeat this collection periodically')}
            </p>
          </div>
        </div>

        {formData.periodType !== null && (
          <div className="mt-4 pl-4">
            <RadioGroup value={formData.periodType} onChange={(periodType) => setFormData({ ...formData, periodType })}>
              <div className="space-y-4">
                {['monthly', 'quarterly', 'yearly'].map((period) => (
                  <RadioGroup.Option
                    key={period}
                    value={period}
                    className={({ checked }) =>
                      `${checked ? 'bg-secondary-color border-secondary-color text-white' : 'bg-white border-gray-300'
                      } relative rounded-lg px-4 py-2 cursor-pointer border flex focus:outline-none`
                    }
                  >
                    {({ checked }) => (
                      <div className="flex items-center justify-between w-full">
                        <div className="flex flex-col">
                          <RadioGroup.Label className={`text-sm font-medium ${checked ? 'text-white' : 'text-gray-900'}`}>
                            {t(period)}
                          </RadioGroup.Label>
                          <span className={`text-xs ${checked ? 'text-gray-100' : 'text-gray-500'}`}>
                            {t('You will be reminded on: {{date}}', {
                              date: getNextRepetitionDate(period)
                            })}
                          </span>
                        </div>
                        {checked && <CheckCircleIcon className="h-5 w-5" />}
                      </div>
                    )}
                  </RadioGroup.Option>
                ))}
              </div>
            </RadioGroup>
          </div>
        )}
      </div>
    </div>
  );
};

export default TimingStep;