// src/components/collection_components/CollectionContext.js
import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import axios from '../../../axios';

const CollectionContext = createContext();

export const useCollection = () => useContext(CollectionContext);

export const CollectionProvider = ({ children }) => {
  const [collectionData, setCollectionData] = useState({
    reports: [],
    activeCollections: []
  });
  
  // Load initial form data from session storage
  const initialFormData = (() => {
    const saved = sessionStorage.getItem('collectionFormData');
    return saved ? JSON.parse(saved) : {
      templateId: null,
      templateName: '',
      viewers: [],
      respondents: [],
      isAnonymous: false,
      isMandatory: true,
      collectionType: 'individual',
      assignments: {},
      deadline: null,
      reminders: [],
      periodType: null,
      periodValue: ''
    };
  })();

  // Load initial step from session storage
  const [currentStep, setCurrentStep] = useState(() => {
    const savedStep = sessionStorage.getItem('currentCollectionStep');
    return savedStep ? parseInt(savedStep, 10) : 1;
  });
  
  const [formData, setFormData] = useState(initialFormData);

  // Save form data whenever it changes
  useEffect(() => {
    if (formData.templateId) { // Only save if we have actually started the process
      sessionStorage.setItem('collectionFormData', JSON.stringify(formData));
    }
  }, [formData]);

  // Save current step whenever it changes
  useEffect(() => {
    if (currentStep > 1) { // Only save if we're past the first step
      sessionStorage.setItem('currentCollectionStep', currentStep.toString());
    }
  }, [currentStep]);

  const clearFormData = useCallback(() => {
    sessionStorage.removeItem('collectionFormData');
    sessionStorage.removeItem('currentCollectionStep');
    setFormData({
      templateId: null,
      templateName: '',
      viewers: [],
      respondents: [],
      isAnonymous: false,
      isMandatory: true,
      collectionType: 'individual',
      assignments: {},
      deadline: null,
      reminders: [],
      periodType: null,
      periodValue: ''
    });
    setCurrentStep(1);
  }, []);

  // Fetch collection data
  const fetchData = useCallback(async () => {
    try {
      const response = await axios.get('/api/v1/data_collections', {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('authToken')}` }
      });
      setCollectionData({
        reports: response.data.reports,
        activeCollections: response.data.active_collections
      });
      return true;
    } catch (error) {
      console.error('Error fetching collection data:', error);
      throw error;
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const createCollection = useCallback(async () => {
    try {
      // Convert periodType to match database constraints
      const normalizePeriodType = (type) => {
        switch (type) {
          case 'monthly': return 'month';
          case 'quarterly': return 'quarter';
          case 'yearly': return 'year';
          default: return 'month';
        }
      };
  
      // Format period value based on type
      const getPeriodValue = (type) => {
        const now = new Date();
        switch (type) {
          case 'monthly':
          case 'month':
            return `${String(now.getMonth() + 1).padStart(2, '0')}/${now.getFullYear().toString().slice(-2)}`;
          case 'quarterly':
          case 'quarter':
            return `Q${Math.floor(now.getMonth() / 3) + 1}/${now.getFullYear().toString().slice(-2)}`;
          case 'yearly':
          case 'year':
            return now.getFullYear().toString();
          default:
            return `${String(now.getMonth() + 1).padStart(2, '0')}/${now.getFullYear().toString().slice(-2)}`;
        }
      };
  
      const data = {
        data_collection: {
          data_collection_template_id: formData.templateId,
          period_type: normalizePeriodType(formData.periodType),
          period_value: getPeriodValue(formData.periodType),
          starts_at: new Date().toISOString(),
          ends_at: formData.deadline ? new Date(formData.deadline).toISOString() : null,
          is_anonymous: Boolean(formData.isAnonymous),
          is_mandatory: Boolean(formData.isMandatory),
          collection_type: formData.collectionType
        },
        viewer_ids: formData.viewers || [],
        respondent_ids: formData.respondents || [],
        assignments: formData.assignments || {},
      };
  
      const response = await axios.post('/api/v1/data_collections', data, {
        headers: { 
          'Authorization': `Bearer ${sessionStorage.getItem('authToken')}`,
          'Content-Type': 'application/json'
        }
      });
      
      await fetchData(); // Refresh data after creation
      return response.data;
    } catch (error) {
      console.error('API Error Response:', error.response?.data);
      throw new Error(error.response?.data?.details?.join(', ') || error.response?.data?.error || 'Failed to create data collection');
    }
  }, [formData, fetchData]);

  return (
    <CollectionContext.Provider
      value={{
        collectionData,
        formData,
        setFormData,
        currentStep,
        setCurrentStep,
        createCollection,
        clearFormData,
        refetchData: fetchData
      }}
    >
      {children}
    </CollectionContext.Provider>
  );
};

export default CollectionContext;