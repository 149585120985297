import React from 'react';
import { getTranslatedValue } from '../../../utils/templateTranslations';

const RatingBlockQuestion = ({
  question,
  value = {},
  onChange,
  language,
  readOnly
}) => {
  const { range } = question;
  const items = question.items || []; // Provide default empty array if items is undefined
  const rangeValues = Array.from(
    { length: range[1] - range[0] + 1 },
    (_, i) => range[0] + i
  );

  const leftLabel = getTranslatedValue(question.left_label, language);
  const rightLabel = getTranslatedValue(question.right_label, language);

  const handleItemChange = (itemId, rating) => {
    onChange({
      ...value,
      [itemId]: rating
    });
  };

  return (
    <div className="space-y-4 mt-10">
      {/* Question Text and Labels */}
      <div className="flex w-full space-x-4">
        <div className="w-2/3 text-base font-semibold text-gray-900 text-left">
          {getTranslatedValue(question.text, language)}
        </div>
        <div className="w-1/3 flex justify-between">
          <span className="text-sm text-gray-600 text-left">{leftLabel}</span>
          <span className="text-sm text-gray-600 text-right">{rightLabel}</span>
        </div>
      </div>

      {/* Items */}
      <div className="space-y-4">
        {items.map((item) => {
          const itemId = item.id;
          const itemText = getTranslatedValue(item.text, language);
          const itemValue = value[itemId];

          return (
            <div key={itemId} className="flex w-full items-center space-x-4">
              {/* Item Text */}
              <div className="w-2/3 text-gray-800 text-left">
                {itemText}
              </div>
              {/* Ratings */}
              <div className="w-1/3 flex items-center justify-between">
                {rangeValues.map((number) => {
                  const isSelected = itemValue === number;
                  return (
                    <button
                      key={number}
                      type="button"
                      onClick={() => handleItemChange(itemId, number)}
                      disabled={readOnly}
                      className={`
                        w-10 h-10 rounded-full flex items-center justify-center
                        border text-sm font-medium transition-colors
                        ${readOnly
                          ? isSelected
                            ? 'bg-gray-400 text-white border-gray-400 cursor-default'
                            : 'bg-gray-200 text-gray-700 border-gray-200 cursor-default'
                          : isSelected
                            ? 'bg-secondary-color text-white border-secondary-color'
                            : 'bg-white text-gray-700 border-gray-300 hover:bg-gray-50'
                        }
                      `}
                    >
                      {number}
                    </button>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default RatingBlockQuestion;
