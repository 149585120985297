import React from 'react';
import Chart from 'react-apexcharts';
import { getTranslatedValue } from '../../../utils/templateTranslations';
import { useTranslation } from 'react-i18next';

const GroupedColumnChart = ({
  question,
  collection,
  comparisonData = [],
  language = 'en'
}) => {
  const { t } = useTranslation();

  // Function to process a single collection's response data
  const processCollectionData = (collectionData, isComparison = false) => {
    let responseData;
    if (isComparison) {
      responseData = collectionData.responses?.comparison?.cost_variance?.components || {};
    } else {
      const response = collectionData.responses?.find(response => 
        response.response_data?.cost_variance !== undefined
      )?.response_data?.cost_variance;
      responseData = response || {};
    }

    // Get values for planned and actual costs
    const plannedCosts = parseFloat(responseData.first?.value) || 0;
    const actualCosts = parseFloat(responseData.second?.value) || 0;

    return {
      plannedCosts,
      actualCosts
    };
  };

  // Process current and comparison data
  const currentData = processCollectionData(collection);
  const comparisonDataProcessed = comparisonData.map(comp => 
    processCollectionData(comp, true)
  );

  // Prepare series data
  const series = [
    {
      name: getTranslatedValue(question.labels.first, language),
      data: [
        currentData.plannedCosts,
        ...comparisonDataProcessed.map(comp => comp.plannedCosts)
      ]
    },
    {
      name: getTranslatedValue(question.labels.second, language),
      data: [
        currentData.actualCosts,
        ...comparisonDataProcessed.map(comp => comp.actualCosts)
      ]
    }
  ];

  // Prepare categories (x-axis labels)
  const categories = [
    t('Current'),
    ...comparisonData.map(comp => comp.collection_info.period_value)
  ];

  const options = {
    chart: {
      type: 'bar',
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        endingShape: 'flat',
        groupPadding: 0.2,
        borderRadius: 10,
        borderRadiusApplication: 'end',
      }
    },
    xaxis: {
      categories,
      labels: {
        style: {
          colors: '#6b7280',
          fontSize: '12px'
        }
      }
    },
    yaxis: {
      title: {
        text: getTranslatedValue(question.units, language),
        style: {
          color: '#6b7280'
        }
      },
      labels: {
        style: {
          colors: '#6b7280'
        },
        formatter: (value) => value.toLocaleString()
      }
    },
    colors: [
      '#264653', // Planned costs
      '#2A9D8F'  // Actual costs
    ],
    legend: {
      position: 'bottom',
      horizontalAlign: 'center',
      labels: {
        colors: '#6b7280'
      }
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val.toLocaleString();
      }
    },
    theme: {
      mode: 'light'
    },
    tooltip: {
      y: {
        formatter: (value) => `${value.toLocaleString()} ${getTranslatedValue(question.units, language)}`
      }
    }
  };

  return (
    <div className="w-full bg-white rounded-lg shadow-sm">
      <div className="p-6">
        <h3 className="text-lg font-semibold mb-6 text-gray-900">
          {getTranslatedValue(question.text, language)}
        </h3>
        <Chart
          options={options}
          series={series}
          type="bar"
          height={500}
          width="100%"
        />
      </div>
    </div>
  );
};

export default GroupedColumnChart;