import React, { useState } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import { getTranslatedValue } from '../../../utils/templateTranslations';

const IndividualResponsesTable = ({ responses, getQuestionText, getUserName, t, isReversed }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const getColorClass = (value, maxValue, minValue) => {
    if (isReversed) {
        if (value === maxValue) return 'text-red-600';
        if (value === minValue) return 'text-green-600';
        return 'text-gray-600';
    }
    if (value === maxValue) return 'text-green-600';
    if (value === minValue) return 'text-red-600';
    return 'text-gray-600';
};

  // Sort responses by value
  const allResponses = [...responses].sort((a, b) => b.value - a.value);

  // Find the actual maximum and minimum values
  const maxValue = Math.max(...allResponses.map(r => r.value));
  const minValue = Math.min(...allResponses.map(r => r.value));

  // Filter responses for max and min values
  const maxResponses = allResponses.filter(r => r.value === maxValue);
  const minResponses = allResponses.filter(r => r.value === minValue);
  const middleResponses = allResponses.filter(r => r.value !== maxValue && r.value !== minValue);

  const renderResponseRow = (response, index) => (
    <tr key={response.uniqueKey} 
        className={index % 2 === 0 ? 'bg-gray-50' : 'bg-white'}>
      <td className={`px-4 py-2 text-sm font-medium ${
        getColorClass(response.value, maxValue, minValue)
      }`}>
        {response.value}
      </td>
      <td className="px-4 py-2 text-sm">{getQuestionText(response.questionId)}</td>
      <td className="px-4 py-2 text-sm">{response.partName}</td>
      <td className="px-4 py-2 text-sm">
        {response.isAnonymous ? 
          t('Anonymous User {{number}}', { 
            number: parseInt(response.userId.split('_')[1]) + 1 
          }) 
          : getUserName(response.userId)
        }
      </td>
    </tr>
  );

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full divide-y divide-gray-200">
        <thead>
          <tr>
            <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">
              {t('Value')}
            </th>
            <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">
              {t('Question')}
            </th>
            <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">
              {t('Department')}
            </th>
            <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">
              {t('User')}
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {/* Maximum value responses */}
          {maxResponses.map((response, index) => renderResponseRow(response, index))}

          {/* Expandable middle section */}
          {middleResponses.length > 0 && (
            <>
              <tr>
                <td colSpan="4" className="px-4 py-2">
                  <button
                    onClick={() => setIsExpanded(!isExpanded)}
                    className="w-full flex items-center justify-center py-2 text-sm text-gray-600 hover:text-gray-900"
                  >
                    {isExpanded ? (
                      <>
                        {t('Show less')}
                        <ChevronUpIcon className="ml-2 h-4 w-4" />
                      </>
                    ) : (
                      <>
                        {t('Show all {{count}} responses', { count: middleResponses.length })}
                        <ChevronDownIcon className="ml-2 h-4 w-4" />
                      </>
                    )}
                  </button>
                </td>
              </tr>
              {isExpanded && middleResponses.map((response, index) => 
                renderResponseRow(response, maxResponses.length + index)
              )}
            </>
          )}

          {/* Minimum value responses */}
          {minResponses.map((response, index) => 
            renderResponseRow(response, maxResponses.length + middleResponses.length + index)
          )}
        </tbody>
      </table>
    </div>
  );
};

export default IndividualResponsesTable;