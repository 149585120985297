import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RadioGroup } from '@headlessui/react';
import { CheckCircleIcon, LightBulbIcon } from '@heroicons/react/24/solid';
import { UsersIcon, UserPlusIcon } from '@heroicons/react/24/outline';
import axios from '../../../axios';
import { getTranslatedValue } from '../../../utils/templateTranslations';

const AssignmentStep = ({ formData, setFormData }) => {
  const { t } = useTranslation();
  const [users, setUsers] = useState([]);
  const [template, setTemplate] = useState(null);
  const [userData, setUserData] = useState(null);

  // Fetch user data on component mount
  useEffect(() => {
    fetchUserData();
  }, []);

  const fetchUserData = async () => {
    try {
      const response = await axios.get('/api/v1/live_data/user_data', {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('authToken')}`
        }
      });
      setUserData(response.data);
      console.log('Fetched User Data:', response.data); // **Log 1**
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  // Fetch template and users when templateId and userData are available
  useEffect(() => {
    if (!formData.templateId || !userData) {
      console.log('Template ID or User Data not available yet.'); // **Log 2**
      return;
    }

    const fetchTemplate = async () => {
      try {
        const response = await axios.get(`/api/v1/data_collections/templates/${formData.templateId}`, {
          headers: { Authorization: `Bearer ${sessionStorage.getItem('authToken')}` }
        });
        setTemplate(response.data);
        console.log('Fetched Template Data:', response.data); // **Log 3**
      } catch (error) {
        console.error("Error fetching template:", error);
      }
    };

    const fetchUsers = async () => {
      try {
        const response = await axios.get('/api/v1/live_data/user_data', {
          headers: { Authorization: `Bearer ${sessionStorage.getItem('authToken')}` },
        });
        setUsers(response.data.entity.users);
        console.log('Fetched Users for Assignment:', response.data.entity.users); // **Log 4**
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchTemplate();
    fetchUsers();
  }, [formData.templateId, userData]);

  // Auto-assign respondents for anonymous collections
  useEffect(() => {
    if (formData.isAnonymous && template) {  // Only auto-assign for anonymous collections
      const allParts = template.template_data.parts.map(part => part.id);
      const assignments = {};

      allParts.forEach(partId => {
        assignments[partId] = {
          assignees: formData.respondents
        };
      });

      console.log('Auto-assigning respondents for anonymous collection:', assignments); // **Log 5**

      setFormData(prev => {
        const updatedFormData = {
          ...prev,
          collectionType: 'individual',  // Force individual for anonymous
          assignments
        };
        console.log('Updated formData after auto-assign:', updatedFormData); // **Log 6**
        return updatedFormData;
      });
    }
  }, [formData.isAnonymous, formData.respondents, template]);

  // Log formData whenever it changes
  useEffect(() => {
    console.log('Current formData:', formData); // **Log 7**
  }, [formData]);

  if (!userData) {
    return <div className="text-center">{t('Loading...')}</div>;
  }

  const userLang = userData.language
    ? userData.language.slice(0, 2)
    : 'en';

  const renderRecommendedAssignment = (partId) => {
    if (!template?.part_assignments?.[partId]) return null;

    return (
      <div className="inline-flex items-center ml-2 px-2 py-0.5 rounded text-xs font-medium bg-yellow-100 text-yellow-800">
        <LightBulbIcon className="h-3 w-3 mr-1" />
        <span>
          {t('Recommended assignee(s)')}: {getTranslatedValue(template.part_assignments[partId], userLang)}
        </span>
      </div>
    );
  };

  const handleTypeChange = (type) => {
    if (formData.isAnonymous) {
      console.log('Attempted to change collection type for anonymous collection.'); // **Log 8**
      return; // Prevent type change for anonymous collections
    }

    const updatedFormData = {
      ...formData,
      collectionType: type,
      assignments: {}  // Reset to empty object
    };
    console.log('Changing collection type to:', type, 'and resetting assignments:', updatedFormData.assignments); // **Log 9**
    setFormData(updatedFormData);
  };

  const handleAssignAllParts = () => {
    if (!template) {
      console.log('Template not loaded. Cannot assign all parts.'); // **Log 10**
      return;
    }

    const allParts = template.template_data.parts.map(part => part.id);
    const assignments = {};

    allParts.forEach(partId => {
      if (formData.collectionType === 'individual') {
        assignments[partId] = {
          assignees: formData.respondents,
        };
      } else {
        assignments[partId] = {
          editors: formData.respondents,
          submitters: formData.respondents
        };
      }
    });

    console.log('Assigning all parts:', assignments); // **Log 11**

    setFormData(prev => {
      const updatedFormData = {
        ...prev,
        assignments
      };
      console.log('Updated formData after assigning all parts:', updatedFormData); // **Log 12**
      return updatedFormData;
    });
  };

  const handleAssignmentChange = (partId, role, userId) => {
    if (formData.collectionType === 'individual') {
      // Remove the new variable declarations that were shadowing the outer ones
      const currentAssignments = formData.assignments[partId] || { assignees: [] };
      setFormData(prev => ({
        ...prev,
        assignments: {
          ...prev.assignments,
          [partId]: {
            assignees: currentAssignments.assignees.includes(userId)
              ? currentAssignments.assignees.filter(id => id !== userId)
              : [...currentAssignments.assignees, userId]
          }
        }
      }));
    } else {
      // For collaborative collections
      const currentAssignments = formData.assignments[partId] || {};
      const currentEditors = currentAssignments.editors || [];
      const currentSubmitters = currentAssignments.submitters || [];
      let newAssignments;

      if (role === 'submitter') {
        newAssignments = {
          editors: currentEditors.includes(userId)
            ? currentEditors
            : [...currentEditors, userId],
          submitters: currentSubmitters.includes(userId)
            ? currentSubmitters.filter(id => id !== userId)
            : [...currentSubmitters, userId]
        };
      } else {
        newAssignments = {
          editors: currentEditors.includes(userId)
            ? currentEditors.filter(id => id !== userId)
            : [...currentEditors, userId],
          submitters: currentEditors.includes(userId)
            ? currentSubmitters.filter(id => id !== userId)
            : currentSubmitters
        };
      }

      setFormData(prev => ({
        ...prev,
        assignments: {
          ...prev.assignments,
          [partId]: newAssignments
        }
      }));
    }
  };

  // Validation check for assignments
  const validateAssignments = () => {
    if (!template) return true;

    const isValid = template.template_data.parts.every((part) => {
      const partAssignments = formData.assignments[part.id] || {};
      if (formData.collectionType === 'individual') {
        return (partAssignments.assignees || []).length > 0;
      } else {
        return (partAssignments.submitters || []).length > 0;
      }
    });

    console.log('Validation result for assignments:', isValid); // **Log 16**
    return isValid;
  };

  if (formData.isAnonymous) {
    return (
      <div className="text-center p-8">
        <UsersIcon className="mx-auto h-12 w-12 text-gray-400" />
        <h3 className="mt-2 text-sm font-medium text-gray-900">
          {t('Anonymous Collection')}
        </h3>
        <p className="mt-1 text-sm text-gray-500">
          {t('All respondents will receive all parts of this anonymous collection')}
        </p>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      {/* Collection Type Selection */}
      <div>
        <RadioGroup value={formData.collectionType} onChange={handleTypeChange}>
          <RadioGroup.Label className="text-lg font-medium text-gray-900">
            {t('Collection Type')}
          </RadioGroup.Label>

          <div className="mt-4 grid grid-cols-1 gap-4">
            <RadioGroup.Option
              value="individual"
              className={({ checked }) =>
                `${checked
                  ? 'bg-secondary-color text-white border-secondary-color ring-2 ring-secondary-color'
                  : 'bg-white text-gray-900 border-gray-300'
                } relative rounded-lg border p-4 cursor-pointer focus:outline-none`
              }
            >
              {({ checked }) => (
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <div className="text-sm">
                      <RadioGroup.Label as="p" className={`font-medium ${checked ? 'text-white' : 'text-gray-900'}`}>
                        {t('Individual Entry')}
                      </RadioGroup.Label>
                      <RadioGroup.Description as="p" className={`text-sm ${checked ? 'text-gray-200' : 'text-gray-500'}`}>
                        {t('Everyone answers their assigned parts independently')}
                      </RadioGroup.Description>
                    </div>
                  </div>
                  {checked && <CheckCircleIcon className="h-5 w-5 text-white" />}
                </div>
              )}
            </RadioGroup.Option>

            <RadioGroup.Option
              value="collaborative"
              className={({ checked }) =>
                `${checked
                  ? 'bg-secondary-color text-white border-secondary-color ring-2 ring-secondary-color'
                  : 'bg-white text-gray-900 border-gray-300'
                } relative rounded-lg border p-4 cursor-pointer focus:outline-none`
              }
            >
              {({ checked }) => (
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <div className="text-sm">
                      <RadioGroup.Label as="p" className={`font-medium ${checked ? 'text-white' : 'text-gray-900'}`}>
                        {t('Collaborative Entry')}
                      </RadioGroup.Label>
                      <RadioGroup.Description as="p" className={`text-sm ${checked ? 'text-gray-200' : 'text-gray-500'}`}>
                        {t('Assigned people work together on parts')}
                      </RadioGroup.Description>
                    </div>
                  </div>
                  {checked && <CheckCircleIcon className="h-5 w-5 text-white" />}
                </div>
              )}
            </RadioGroup.Option>
          </div>
        </RadioGroup>
      </div>

      {/* Part Assignments */}
      {template && (
        <div className="bg-white rounded-lg p-6">
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-lg font-medium text-gray-900">{t('Assign Parts')}</h3>
            {formData.collectionType === 'individual' && (
              <button
                onClick={handleAssignAllParts}
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-primary-color hover:bg-primary-color/90"
              >
                <UserPlusIcon className="h-5 w-5 mr-2" />
                {t('Make everyone a respondent for all parts')}
              </button>
            )}
          </div>

          {template.template_data.parts.map((part) => (
            <div key={part.id} className="mb-8 last:mb-0">
              <div className="border rounded-lg p-4">
                <h4 className="text-sm font-medium text-gray-900 mb-2">
                  {getTranslatedValue(part.name, userLang)}
                </h4>
                {part.description && (
                  <p className="text-sm text-gray-500 mb-4">
                    {getTranslatedValue(part.description, userLang)}
                  </p>
                )}
                {renderRecommendedAssignment(part.id)}

                <div className="m-3 bg-white shadow rounded-md">
                  {users
                    .filter((user) => formData.respondents.includes(user.id))
                    .map((user) => {
                      const assignments = formData.assignments[part.id] || {};
                      const isAssignee = (assignments.assignees || []).includes(user.id);
                      const isEditor = (assignments.editors || []).includes(user.id);
                      const isSubmitter = (assignments.submitters || []).includes(user.id);

                      return (
                          <div key={user.id} className="flex py-2 px-2 items-center justify-between border-b border-gray-200 last:border-b-0">
                            <div className="flex items-center">
                              {user.avatar_url ? (
                                <img
                                  src={user.avatar_url}
                                  alt={`${user.first_name} ${user.last_name}`}
                                  className="h-8 w-8 rounded-full"
                                />
                              ) : (
                                <div className="h-8 w-8 rounded-full bg-gray-200 flex items-center justify-center">
                                  <span className="text-sm font-medium text-gray-600">
                                    {user.first_name[0]}
                                    {user.last_name[0]}
                                  </span>
                                </div>
                              )}
                              <span className="ml-3 text-sm font-medium text-gray-900">
                                {user.first_name} {user.last_name}
                              </span>
                            </div>

                            <div className="flex space-x-2">
                              {formData.collectionType === 'individual' ? (
                                <button
                                  type="button"
                                  onClick={() =>
                                    handleAssignmentChange(part.id, 'assignee', user.id)
                                  }
                                  className={`inline-flex items-center px-3 py-1 rounded-full text-sm font-medium ${isAssignee
                                    ? 'bg-secondary-color text-white'
                                    : 'bg-gray-100 text-gray-400 hover:bg-gray-200'
                                    }`}
                                >
                                  <CheckCircleIcon className="h-4 w-4 mr-1" />
                                  {t('Assignee')}
                                </button>
                              ) : (
                                <>
                                  <button
                                    type="button"
                                    onClick={() =>
                                      handleAssignmentChange(part.id, 'editor', user.id)
                                    }
                                    className={`inline-flex items-center px-3 py-1 rounded-full text-sm font-medium ${isEditor
                                      ? 'bg-secondary-color text-white'
                                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                                      }`}
                                  >
                                    <UsersIcon className="h-4 w-4 mr-1" />
                                    {t('Editor')}
                                  </button>
                                  <button
                                    type="button"
                                    onClick={() =>
                                      handleAssignmentChange(part.id, 'submitter', user.id)
                                    }
                                    className={`inline-flex items-center px-3 py-1 rounded-full text-sm font-medium ${isSubmitter
                                      ? 'bg-secondary-color text-white'
                                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                                      }`}
                                  >
                                    <CheckCircleIcon className="h-4 w-4 mr-1" />
                                    {t('Submitter')}
                                  </button>
                                </>
                              )}
                            </div>
                          </div>
                      );
                    })}
                </div>
              </div>
            </div>
          ))}
          {!validateAssignments() && (
            <div className="mt-4 p-4 bg-yellow-50 rounded-md">
              <p className="text-sm text-yellow-700">
                {formData.collectionType === 'individual'
                  ? t('Each part must have at least one respondent assigned')
                  : t('Each part must have at least one submitter assigned')
                }
              </p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default AssignmentStep;
