import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import { getTranslatedValue } from '../../../utils/templateTranslations';
import axios from '../../../axios';
import DataAnalysisSection from './DataAnalysisSection';

const BoxPlotChart = ({ question, collection, template }) => {
  const { t } = useTranslation();
  const [users, setUsers] = useState({});
  const [userLang, setUserLang] = useState('en');

  // Calculate color based on value within range
  const getColor = (value, range, reverse = false) => {
    const [min, max] = range;
    const range_size = max - min;
    
    // Define thresholds for color transitions
    const redThreshold = min + (range_size * 0.5);    // Bottom 50%
    const greenThreshold = max - (range_size * 0.5);  // Top 50%
    
    // For reversed rating, swap the color logic
    let colorValue = reverse
      ? max - (value - min) // Invert the value within the range
      : value;
    
    if (colorValue <= redThreshold) {
      // Red zone - interpolate from pure red to yellow
      const t = (colorValue - min) / (redThreshold - min);
      const r = 255;
      const g = Math.round(69 + (107 * t));  // From FF4560 to FEB019
      const b = Math.round(96 + (-71 * t));
      return `rgb(${r},${g},${b})`;
    } else if (colorValue >= greenThreshold) {
      // Green zone - interpolate from yellow to pure green
      const t = (colorValue - greenThreshold) / (max - greenThreshold);
      const r = Math.round(254 - (212 * t));  // From FEB019 to 2A9D8F
      const g = Math.round(176 - (19 * t));
      const b = Math.round(25 + (118 * t));
      return `rgb(${r},${g},${b})`;
    } else {
      // Yellow zone - interpolate through yellow shades
      const t = (colorValue - redThreshold) / (greenThreshold - redThreshold);
      const r = 254;
      const g = Math.round(176);
      const b = Math.round(25 + (t * 25));  // Slight variation in yellow
      return `rgb(${r},${g},${b})`;
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get('/api/v1/live_data/user_data', {
          headers: { Authorization: `Bearer ${sessionStorage.getItem('authToken')}` },
        });

        if (response.data.language) {
          setUserLang(response.data.language.slice(0, 2));
        }

        if (response.data.entity?.users) {
          const userMap = {};
          response.data.entity.users.forEach(user => {
            userMap[user.id] = `${user.first_name} ${user.last_name}`;
          });
          setUsers(userMap);
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, []);

  const findQuestionInTemplate = (questionId) => {
    if (!template) return null;
    for (const part of template.parts) {
      const question = part.questions.find(q => q.id === questionId);
      if (question) {
        return { question, part };
      }
    }
    return null;
  };

  const getPartName = (partId) => {
    const part = template.parts.find(p => p.id === partId);
    return part ? getTranslatedValue(part.name, userLang) : partId;
  };

  const getUserName = (userId) => {
    return users[userId] || `User ${userId}`;
  };

  const getQuestionText = (questionId) => {
    if (!questionId || !template) return '';

    const [mainQuestionId, itemId] = questionId.split('.');
    const found = findQuestionInTemplate(mainQuestionId);

    if (found && found.question.items) {
      const item = found.question.items.find((i) => i.id === itemId);
      if (item && item.text) {
        return getTranslatedValue(item.text, userLang);
      }
    }
    return questionId;
  };

  const chartData = React.useMemo(() => {
    if (!collection?.responses || !template) return null;

    // Find the question in template to get rating_reverse flag
    const templateQuestion = findQuestionInTemplate(question.id)?.question;
    const isReversed = templateQuestion?.rating_reverse || false;

    // Get all unique question IDs from all responses
    const questionIds = new Set();
    collection.responses.forEach(response => {
        Object.keys(response.response_data).forEach(id => questionIds.add(id));
    });

    const targetGroup = findQuestionInTemplate(question.id)?.question?.comparison_group;
    const relatedQuestionIds = Array.from(questionIds).filter((id) => {
        const foundQuestion = findQuestionInTemplate(id)?.question;
        return foundQuestion?.comparison_group === targetGroup;
    });

    const userPartAverages = {};

    // Process each part differently based on collection type
    if (collection.collection_type === 'collaborative') {
        relatedQuestionIds.forEach(questionId => {
            const partId = questionId.split('_')[0];
            const partName = getPartName(partId);

            // Find the latest non-draft response for this part
            const partResponse = collection.responses
                .filter(r => !r.is_draft && r.response_data[questionId])
                .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))[0];

            if (partResponse) {
                if (!userPartAverages[partName]) {
                    userPartAverages[partName] = {};
                }
                
                const values = partResponse.response_data[questionId];
                if (values) {
                    // For collaborative, we'll create a single "user" entry
                    userPartAverages[partName]['collaborative'] = {
                        sum: Object.values(values).reduce((acc, val) => acc + (typeof val === 'number' ? val : 0), 0),
                        count: Object.values(values).filter(val => typeof val === 'number').length
                    };
                }
            }
        });
    } else {
        // Original logic for individual collections
        collection.responses.forEach((response) => {
            const userId = response.user_id;

            relatedQuestionIds.forEach(questionId => {
                const partId = questionId.split('_')[0];
                const partName = getPartName(partId);

                if (!userPartAverages[partName]) {
                    userPartAverages[partName] = {};
                }
                if (!userPartAverages[partName][userId]) {
                    userPartAverages[partName][userId] = {
                        sum: 0,
                        count: 0
                    };
                }

                const values = response.response_data[questionId];
                if (values) {
                    Object.values(values).forEach(value => {
                        if (typeof value === 'number') {
                            userPartAverages[partName][userId].sum += value;
                            userPartAverages[partName][userId].count += 1;
                        }
                    });
                }
            });
        });
    }

    const boxPlotData = Object.entries(userPartAverages).map(([partName, userAverages]) => {
        const averages = Object.values(userAverages)
            .map(data => data.count > 0 ? data.sum / data.count : null)
            .filter(avg => avg !== null)
            .sort((a, b) => a - b);

        if (averages.length === 0) return null;

        const min = Math.min(...averages);
        const max = Math.max(...averages);
        const q1Index = Math.floor(averages.length * 0.25);
        const q2Index = Math.floor(averages.length * 0.5);
        const q3Index = Math.floor(averages.length * 0.75);

        const q1 = averages[q1Index] || min;
        const median = averages[q2Index] || min;
        const q3 = averages[q3Index] || max;

        const color = getColor(median, question.range || [-3, 3], isReversed);

        return {
            x: partName,
            y: [min, q1, median, q3, max],
            color: color,
            fillColor: color
        };
    }).filter(Boolean);

    boxPlotData.sort((a, b) => b.y[2] - a.y[2]);

    return {
        name: getTranslatedValue(question.comparison_group_name, userLang),
        data: boxPlotData,
        isReversed
    };
}, [collection, question, template, userLang]);

  if (!chartData || chartData.data.length === 0) {
    return (
      <div className="text-center p-4 text-gray-500">
        {t('No comparable data available for visualization')}
      </div>
    );
  }

  const series = [{
    type: 'boxPlot',
    data: chartData.data
  }];

  const range = question.range || [-3, 3];
  const [minRange, maxRange] = range;
  const tickAmount = Math.abs(maxRange - minRange);

  return (
    <div className="bg-white p-4 rounded-lg shadow">
      <h3 className="text-xl font-bold mb-4">
        {getTranslatedValue(question.comparison_group_name, userLang)}
      </h3>
      <p>
        {t('From “{{leftLabel}}“ [{{minRange}}] to “{{rightLabel}}“ [{{maxRange}}]', {
          leftLabel: getTranslatedValue(question.left_label, userLang),
          minRange: minRange,
          rightLabel: getTranslatedValue(question.right_label, userLang),
          maxRange: maxRange
        })}
      </p>
      <Chart
        options={{
            width: '100%',
          chart: {
            type: 'boxPlot',
            height: 500,
            toolbar: { show: false }
          },
          plotOptions: {
            bar: {
              horizontal: true,
              barHeight: '50%',
            },
            boxPlot: {
              colors: {
                upper: chartData.data.map(d => d.color),
                lower: chartData.data.map(d => d.color)
              }
            }
          },
          xaxis: {
            min: minRange,
            max: maxRange,
            tickAmount: tickAmount
          },
          yaxis: {
            labels: {
              style: {
                fontSize: '12px',
                fontFamily: 'inherit'
              }
            }
          },
          tooltip: {
            shared: true,
            custom: function ({ seriesIndex, dataPointIndex, w }) {
              const data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
              return `
                <div class="p-2">
                  <div class="font-bold mb-1">${data.x}</div>
                  <div>Min: ${data.y[0].toFixed(2)}</div>
                  <div>Q1: ${data.y[1].toFixed(2)}</div>
                  <div>Median: ${data.y[2].toFixed(2)}</div>
                  <div>Q3: ${data.y[3].toFixed(2)}</div>
                  <div>Max: ${data.y[4].toFixed(2)}</div>
                </div>
              `;
            }
          }
        }}
        series={series}
        type="boxPlot"
        height={400}
      />

      <div className="mt-6">
        <DataAnalysisSection
          responses={collection.responses}
          question={question}
          template={template}
          getQuestionText={getQuestionText}
          getUserName={getUserName}
          t={t}
          isReversed={chartData.isReversed}
          range={question.range || [-3, 3]}
          collectionType={collection.collection_type}
        />
      </div>
    </div>
  );
};

export default BoxPlotChart;