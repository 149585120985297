import React, { useState } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import { getTranslatedValue } from '../../../utils/templateTranslations';

const QuestionAverageTable = ({ responses, template, question, userLang, t, isReversed }) => {
  const [showAll, setShowAll] = useState(false);

  const getColorClass = (isHighest) => {
    if (isReversed) {
      return isHighest ? 'text-red-600' : 'text-green-600';
    }
    return isHighest ? 'text-green-600' : 'text-red-600';
  };

  // Get all questions that belong to the same comparison group and calculate their averages
  const calculateQuestionAverages = () => {
    const averages = {};
    let totalResponses = {};

    // Initialize with valid questions from the template
    template.parts.forEach(part => {
      part.questions.forEach(q => {
        if (q.comparison_group === question.comparison_group) {
          if (q.items) {
            // For rating blocks, initialize each item
            q.items.forEach(item => {
              const fullId = `${q.id}.${item.id}`;
              averages[fullId] = 0;
              totalResponses[fullId] = 0;
            });
          }
        }
      });
    });

    // Calculate sums and counts
    responses.forEach(response => {
      Object.entries(response.response_data).forEach(([questionId, data]) => {
        if (typeof data === 'object') {
          Object.entries(data).forEach(([itemId, value]) => {
            const fullId = `${questionId}.${itemId}`;
            if (fullId in averages) {
              averages[fullId] += value;
              totalResponses[fullId] += 1;
            }
          });
        }
      });
    });

    // Calculate averages and create result array
    const result = Object.entries(averages).map(([id, sum]) => ({
      questionId: id,
      average: totalResponses[id] > 0 ? sum / totalResponses[id] : 0
    }));

    return result.sort((a, b) => b.average - a.average);
  };

  const questionAverages = calculateQuestionAverages();
  const maxAverage = Math.max(...questionAverages.map(q => q.average));
  const minAverage = Math.min(...questionAverages.map(q => q.average));

  const highestAverages = questionAverages.filter(q => q.average === maxAverage);
  const lowestAverages = questionAverages.filter(q => q.average === minAverage);
  const middleAverages = questionAverages.filter(q => 
    q.average !== maxAverage && q.average !== minAverage
  );

  // Helper function to get question text
  const getQuestionText = (fullId) => {
    const [questionId, itemId] = fullId.split('.');
    
    for (const part of template.parts) {
      const question = part.questions.find(q => q.id === questionId);
      if (question) {
        if (itemId && question.items) {
          const item = question.items.find(i => i.id === itemId);
          if (item) {
            const partName = getTranslatedValue(part.name, userLang);
            const itemText = getTranslatedValue(item.text, userLang);
            return `${partName} - ${itemText}`;
          }
        }
        const partName = getTranslatedValue(part.name, userLang);
        return `${partName} - ${getTranslatedValue(question.text, userLang)}`;
      }
    }
    return fullId;
  };

  return (
    <table className="min-w-full divide-y divide-gray-200">
      <thead>
        <tr>
          <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">
            {t('Question')}
          </th>
          <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">
            {t('Average Score')}
          </th>
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-200">
        {highestAverages.map((q, i) => (
          <tr key={q.questionId} className={i % 2 === 0 ? 'bg-gray-50' : 'bg-white'}>
            <td className="px-4 py-2 text-sm">{getQuestionText(q.questionId)}</td>
            <td className={`px-4 py-2 text-sm font-medium ${getColorClass(true)}`}>
              {q.average.toFixed(2)}
            </td>
          </tr>
        ))}
        
        {middleAverages.length > 0 && (
          <>
            <tr>
              <td colSpan="2" className="px-4 py-2">
                <button
                  onClick={() => setShowAll(!showAll)}
                  className="w-full flex items-center justify-center py-2 text-sm text-gray-600 hover:text-gray-900"
                >
                  {showAll ? (
                    <>
                      {t('Show less')}
                      <ChevronUpIcon className="ml-2 h-4 w-4" />
                    </>
                  ) : (
                    <>
                      {t('Show all {{count}} questions', { count: middleAverages.length })}
                      <ChevronDownIcon className="ml-2 h-4 w-4" />
                    </>
                  )}
                </button>
              </td>
            </tr>
            {showAll && middleAverages.map((q, i) => (
              <tr key={q.questionId} className={i % 2 === 0 ? 'bg-gray-50' : 'bg-white'}>
                <td className="px-4 py-2 text-sm">{getQuestionText(q.questionId)}</td>
                <td className="px-4 py-2 text-sm">{q.average.toFixed(2)}</td>
              </tr>
            ))}
          </>
        )}

        {lowestAverages.map((q, i) => (
          <tr key={q.questionId} className={i % 2 === 0 ? 'bg-gray-50' : 'bg-white'}>
            <td className="px-4 py-2 text-sm">{getQuestionText(q.questionId)}</td>
            <td className={`px-4 py-2 text-sm font-medium ${getColorClass(false)}`}>
              {q.average.toFixed(2)}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default QuestionAverageTable;