// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

const CACHE_NAME = 'i18n-cache-v1';
const CACHE_DURATION = process.env.FRONTEND_URL === 'http://localhost:3001'
  ? 60 * 1000 // 1 minute in milliseconds for local development
  : 24 * 60 * 60 * 1000; // 24 hours in milliseconds for other environments

async function loadAndCacheTranslations(language) {
  try {
    const cache = await caches.open(CACHE_NAME);
    const cacheUrl = `/locales/${language}/translation.json`;
    
    const cachedResponse = await cache.match(cacheUrl);
    if (cachedResponse) {
      const cachedData = await cachedResponse.json();
      const timestamp = cachedResponse.headers.get('x-cache-timestamp');
      
      if (timestamp && (Date.now() - parseInt(timestamp)) < CACHE_DURATION) {
        return cachedData;
      }
    }

    const response = await fetch(cacheUrl);
    if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
    
    const translations = await response.json();
    const cacheResponse = new Response(JSON.stringify(translations), {
      headers: {
        'Content-Type': 'application/json',
        'x-cache-timestamp': Date.now().toString()
      }
    });
    
    await cache.put(cacheUrl, cacheResponse);
    return translations;
  } catch (error) {
    console.error(`Translation loading error (${language}):`, error);
    return null;
  }
}

// Initialize i18n before app renders
export const initI18n = async () => {
  const languages = ['en', 'de'];
  const translations = {};
  
  // Preload all translations
  for (const lang of languages) {
    translations[lang] = await loadAndCacheTranslations(lang);
  }

  await i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      fallbackLng: 'en',
      debug: false,
      resources: translations.en ? {
        en: { translation: translations.en },
        de: { translation: translations.de }
      } : undefined,
      interpolation: {
        escapeValue: false
      },
      react: {
        useSuspense: true, // Enable suspense
        bindI18n: 'languageChanged'
      }
    });

  return i18n;
};

export default i18n;