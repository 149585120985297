import React, { useState } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import { getTranslatedValue } from '../../../utils/templateTranslations';

const BlockAveragesTable = ({ averages, template, userLang, t, isReversed }) => {
  const [showAll, setShowAll] = useState(false);

  const maxAverage = Math.max(...averages.map(q => q.average));
  const minAverage = Math.min(...averages.map(q => q.average));

  const highestAverages = averages.filter(q => q.average === maxAverage);
  const lowestAverages = averages.filter(q => q.average === minAverage);
  const middleAverages = averages.filter(q => q.average !== maxAverage && q.average !== minAverage);

  const getColorClass = (isHighest) => {
    if (isReversed) {
      return isHighest ? 'text-red-600' : 'text-green-600';
    }
    return isHighest ? 'text-green-600' : 'text-red-600';
  };

  // Helper function to get translated part name
  const getQuestionText = (questionId) => {
    const partId = questionId.split('_')[0]; // Get the part ID from the question ID
    const part = template.parts.find(p => p.id === partId);
    if (part && part.name) {
      // The part.name should be an object with language keys
      return getTranslatedValue(part.name, userLang);
    }
    return partId;
  };

  return (
    <table className="min-w-full divide-y divide-gray-200">
      <thead>
        <tr>
          <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">
            {t('Part')}
          </th>
          <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">
            {t('Average Score')}
          </th>
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-200">
        {highestAverages.map((q, i) => (
          <tr key={q.questionId} className={i % 2 === 0 ? 'bg-gray-50' : 'bg-white'}>
            <td className="px-4 py-2 text-sm">{getQuestionText(q.questionId)}</td>
            <td className={`px-4 py-2 text-sm font-medium ${getColorClass(true)}`}>
              {q.average.toFixed(2)}
            </td>
          </tr>
        ))}

        {middleAverages.length > 0 && (
          <>
            <tr>
              <td colSpan="2" className="px-4 py-2">
                <button
                  onClick={() => setShowAll(!showAll)}
                  className="w-full flex items-center justify-center py-2 text-sm text-gray-600 hover:text-gray-900"
                >
                  {showAll ? (
                    <>
                      {t('Show less')}
                      <ChevronUpIcon className="ml-2 h-4 w-4" />
                    </>
                  ) : (
                    <>
                      {t('Show all {{count}} parts', { count: middleAverages.length })}
                      <ChevronDownIcon className="ml-2 h-4 w-4" />
                    </>
                  )}
                </button>
              </td>
            </tr>
            {showAll && middleAverages.map((q, i) => (
              <tr key={q.questionId} className={i % 2 === 0 ? 'bg-gray-50' : 'bg-white'}>
                <td className="px-4 py-2 text-sm">{getQuestionText(q.questionId)}</td>
                <td className="px-4 py-2 text-sm">{q.average.toFixed(2)}</td>
              </tr>
            ))}
          </>
        )}

        {lowestAverages.map((q, i) => (
          <tr key={q.questionId} className={i % 2 === 0 ? 'bg-gray-50' : 'bg-white'}>
            <td className="px-4 py-2 text-sm">{getQuestionText(q.questionId)}</td>
            <td className={`px-4 py-2 text-sm font-medium ${getColorClass(false)}`}>
              {q.average.toFixed(2)}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default BlockAveragesTable;