import React, { useState } from 'react';
import { getTranslatedValue } from '../../../utils/templateTranslations';
import { Popover } from '@headlessui/react';
import { ChatBubbleBottomCenterTextIcon } from '@heroicons/react/24/outline';

const getTrafficLightColor = (value, questionType, logic, optionsLength) => {
  if (questionType === 'boolean') {
    let boolVal = value;
    if (typeof boolVal === 'string') {
      const lowerVal = boolVal.toLowerCase();
      if (lowerVal === 'yes' || lowerVal === 'true') boolVal = true;
      else if (lowerVal === 'no' || lowerVal === 'false') boolVal = false;
      else boolVal = undefined;
    }

    if (logic === 'one_best') {
      return boolVal === true ? 'bg-green-600' : (boolVal === false ? 'bg-red-600' : 'bg-gray-400');
    } else {
      return boolVal === true ? 'bg-red-600' : (boolVal === false ? 'bg-green-600' : 'bg-gray-400');
    }
  }

  if (questionType === 'radio') {
    const index = typeof value === 'number' ? value : undefined;

    if (logic === 'one_best') {
      if (optionsLength === 3) {
        switch (index) {
          case 0: return 'bg-green-600';
          case 1: return 'bg-lime-400';
          case 2: return 'bg-red-600';
          default: return 'bg-gray-400';
        }
      } else {
        switch (index) {
          case 0: return 'bg-green-600';
          case 1: return 'bg-lime-400';
          case 2: return 'bg-yellow-400';
          case 3: return 'bg-orange-400';
          case 4: return 'bg-red-600';
          default: return 'bg-gray-400';
        }
      }
    } else if (logic === 'middle_best') {
      const middle = Math.floor(optionsLength / 2);
      if (typeof index !== 'number') return 'bg-gray-400';

      const distance = Math.abs(middle - index);
      switch (distance) {
        case 0: return 'bg-green-600';
        case 1: return 'bg-yellow-400';
        default: return 'bg-red-600';
      }
    }
  }

  return 'bg-gray-400';
};

const TrafficLightTable = ({
  questions,
  collection,
  comparisonData = [],
  language = 'en'
}) => {
  
  const findLatestResponseForQuestion = (questionId) => {
    for (let i = collection.responses.length - 1; i >= 0; i--) {
      const respVal = collection.responses[i]?.response_data?.[questionId];
      if (respVal !== undefined) {
        return respVal;
      }
    }
    return undefined;
  };

  const findLatestNoteForQuestion = (questionId) => {
    for (let i = collection.responses.length - 1; i >= 0; i--) {
      const resp = collection.responses[i];
      if (resp.notes_data && resp.notes_data[questionId]) {
        return resp.notes_data[questionId];
      }
    }
    return undefined;
  };

  const getComparisonValue = (comparisonItem, question) => {
    if (!comparisonItem) return undefined;

    if (typeof comparisonItem === 'number' || typeof comparisonItem === 'boolean' || typeof comparisonItem === 'string') {
      return comparisonItem;
    }

    if (comparisonItem && typeof comparisonItem === 'object') {
      return comparisonItem.resolved_value; 
    }

    return undefined;
  };

  return (
    <div className="bg-white rounded-lg shadow overflow-visible">
      <table className="min-w-full">
        <thead>
          <tr className="bg-gray-50">
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Question
            </th>
            <th className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"></th>
            <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Now
            </th>
            {comparisonData.map((comparison, idx) => (
              <th key={idx} className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                {comparison.collection_info.period_value}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {questions.map((question) => {
            const currentValue = findLatestResponseForQuestion(question.id);
            let currentNotes;
            if (question.notes) {
              currentNotes = findLatestNoteForQuestion(question.id);
            }

            let hoverText = '';
            if (question.type === 'radio' && typeof currentValue === 'number' && question.options) {
              const langOptions = question.options[language] || question.options['en'] || [];
              if (langOptions[currentValue] !== undefined) {
                hoverText = langOptions[currentValue];
              }
            }

            return (
              <tr key={question.id}>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {getTranslatedValue(question.text, language)}
                </td>
                <td className="px-2 py-4 whitespace-nowrap text-sm">
                  {question.notes && currentNotes ? (
                    <Popover className="relative flex items-center">
                      <Popover.Button className="text-gray-500 hover:text-gray-700">
                        <ChatBubbleBottomCenterTextIcon className="h-5 w-5" />
                      </Popover.Button>
                      <Popover.Panel anchor="left end" className="absolute z-10 w-64 bg-white p-3 rounded shadow-lg">
                        <div className="text-sm font-semibold text-gray-800 mb-1 text-wrap">
                          {getTranslatedValue(question.notes_label, language)}
                        </div>
                        <div className="text-sm text-gray-700 whitespace-pre-line text-wrap">
                          {currentNotes}
                        </div>
                      </Popover.Panel>
                    </Popover>
                  ) : null}
                </td>
                
                <TrafficLightHoverCell
                  value={currentValue}
                  question={question}
                  language={language}
                  hoverText={hoverText}
                />

                {comparisonData.map((comparison, idx) => {
                  const comparisonResponseData = comparison.responses?.comparison?.[question.id] 
                    || comparison.responses?.current?.[question.id];
                  const comparisonValue = getComparisonValue(comparisonResponseData, question);

                  let comparisonHoverText = '';
                  if (question.type === 'radio' && typeof comparisonValue === 'number' && question.options) {
                    const langOptions = question.options[language] || question.options['en'] || [];
                    if (langOptions[comparisonValue] !== undefined) {
                      comparisonHoverText = langOptions[comparisonValue];
                    }
                  }

                  return (
                    <TrafficLightHoverCell
                      key={idx}
                      value={comparisonValue}
                      question={question}
                      language={language}
                      hoverText={comparisonHoverText}
                    />
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

const TrafficLightHoverCell = ({ value, question, language, hoverText }) => {
  const [isHovered, setIsHovered] = useState(false);
  
  return (
    <td className="px-6 py-4 whitespace-nowrap relative"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
    >
      <Popover className="relative">
        <Popover.Button as="div">
          <div
            className={`h-5 w-5 rounded-full ${getTrafficLightColor(
              value,
              question.type,
              question.traffic_light_logic,
              question.options ? Object.values(question.options)[0].length : 2
            )}`}
          />
        </Popover.Button>
        {isHovered && hoverText && (
          <Popover.Panel static anchor="left end" className="absolute z-10 bg-white p-2 rounded shadow border text-sm w-auto">
            {hoverText}
          </Popover.Panel>
        )}
      </Popover>
    </td>
  );
};

export default TrafficLightTable;