import React, { useState, useEffect, useMemo } from 'react';
import Chart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import { getTranslatedValue } from '../../../utils/templateTranslations';
import axios from '../../../axios';

const RadarChart = ({ question, collection, template, comparisonData = [] }) => {
  const { t } = useTranslation();
  const [userLang, setUserLang] = useState('en');
  const [users, setUsers] = useState({});

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get('/api/v1/live_data/user_data', {
          headers: { Authorization: `Bearer ${sessionStorage.getItem('authToken')}` },
        });

        if (response.data.language) {
          setUserLang(response.data.language.slice(0, 2));
        }

        if (response.data.entity?.users) {
          const userMap = {};
          response.data.entity.users.forEach(user => {
            userMap[user.id] = `${user.first_name} ${user.last_name}`;
          });
          setUsers(userMap);
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, []);

  const getPartName = (partId) => {
    const part = template.parts.find(p => p.id === partId);
    return part ? getTranslatedValue(part.name, userLang) : partId;
  };

  const getUserName = (userId) => {
    return users[userId] || `User ${userId}`;
  };

  const getQuestionText = (fullId) => {
    const [questionId, itemId] = fullId.split('.');
    for (const part of template.parts) {
      const q = part.questions.find(q => q.id === questionId);
      if (q) {
        if (itemId && q.items) {
          const item = q.items.find(i => i.id === itemId);
          if (item) {
            const partName = getTranslatedValue(part.name, userLang);
            const itemText = getTranslatedValue(item.text, userLang);
            return `${partName} - ${itemText}`;
          }
        }
        const partName = getTranslatedValue(part.name, userLang);
        return `${partName} - ${getTranslatedValue(q.text, userLang)}`;
      }
    }
    return fullId;
  };

  const findQuestionInTemplate = (questionId) => {
    if (!template) return null;
    for (const part of template.parts) {
      const q = part.questions.find(q => q.id === questionId);
      if (q) {
        return { question: q, part };
      }
    }
    return null;
  };

  // Compute question averages for current collection from raw responses
  const questionAverages = useMemo(() => {
    const averages = {};
    let totalResponses = {};

    template.parts.forEach(part => {
      part.questions.forEach(q => {
        if (q.comparison_group === question.comparison_group) {
          if (q.items) {
            q.items.forEach(item => {
              const fullId = `${q.id}.${item.id}`;
              averages[fullId] = 0;
              totalResponses[fullId] = 0;
            });
          }
        }
      });
    });

    collection.responses.forEach(response => {
      Object.entries(response.response_data).forEach(([questionId, data]) => {
        if (typeof data === 'object') {
          Object.entries(data).forEach(([itemId, value]) => {
            const fullId = `${questionId}.${itemId}`;
            if (fullId in averages && typeof value === 'number') {
              averages[fullId] += value;
              totalResponses[fullId] += 1;
            }
          });
        }
      });
    });

    const result = Object.entries(averages)
      .map(([id, sum]) => ({
        questionId: id,
        average: totalResponses[id] > 0 ? sum / totalResponses[id] : 0,
        text: getQuestionText(id)
      }))
      .sort((a, b) => b.average - a.average);

    return result;
  }, [collection, template, question, userLang]);

  const userStats = useMemo(() => {
    const stats = {};
    const questionsInGroup = new Set();

    template.parts.forEach(part => {
      part.questions.forEach(q => {
        if (q.comparison_group === question.comparison_group) {
          questionsInGroup.add(q.id);
        }
      });
    });

    if (!collection.is_anonymous) {
      collection.responses.forEach(response => {
        const userId = response.user_id;
        if (!stats[userId]) {
          stats[userId] = { sum: 0, count: 0, maxScores: 0, minScores: 0 };
        }

        Object.entries(response.response_data).forEach(([questionId, data]) => {
          if (questionsInGroup.has(questionId) && typeof data === 'object') {
            Object.values(data).forEach(value => {
              if (typeof value === 'number') {
                stats[userId].sum += value;
                stats[userId].count += 1;
                if (value === 3) stats[userId].maxScores += 1;
                if (value === -3) stats[userId].minScores += 1;
              }
            });
          }
        });
      });

      return Object.entries(stats)
        .map(([userId, data]) => ({
          userId,
          average: data.count > 0 ? data.sum / data.count : 0,
          maxScores: data.maxScores,
          minScores: data.minScores,
          name: getUserName(userId)
        }))
        .sort((a, b) => b.average - a.average);
    } else {
      return [];
    }
  }, [collection, template, question, users]);

  const calculatePartAverages = (collectionData, questionIds, isComparison) => {
    const partAverages = {};

    questionIds.forEach(questionId => {
      const { question: q } = findQuestionInTemplate(questionId) || {};
      if (!q) return;

      const partId = questionId.split('_')[0];
      const partName = getPartName(partId);
      if (!partAverages[partName]) {
        partAverages[partName] = { sum: 0, count: 0 };
      }

      if (!isComparison) {
        // Current collection: raw responses
        if (Array.isArray(collectionData.responses)) {
          collectionData.responses.forEach(response => {
            const values = response.response_data[questionId];
            if (values && typeof values === 'object') {
              Object.values(values).forEach(value => {
                if (typeof value === 'number') {
                  partAverages[partName].sum += value;
                  partAverages[partName].count += 1;
                }
              });
            }
          });
        }
      } else {
        // Comparison data: aggregated
        const questionData = collectionData.responses?.comparison?.[questionId];
        if (questionData && q.type === 'rating_block' && questionData.item_averages) {
          const vals = Object.values(questionData.item_averages).filter(v => typeof v === 'number');
          if (vals.length > 0) {
            const avgVal = vals.reduce((a, b) => a + b, 0) / vals.length;
            partAverages[partName].sum += avgVal;
            partAverages[partName].count += 1;
          }
        } else if (questionData && q.type === 'rating' && questionData.average !== undefined) {
          partAverages[partName].sum += questionData.average;
          partAverages[partName].count += 1;
        }
      }
    });

    const result = {};
    Object.entries(partAverages).forEach(([partName, { sum, count }]) => {
      result[partName] = { average: count > 0 ? sum / count : 0 };
    });
    return result;
  };

  const chartData = useMemo(() => {
    if (!collection?.responses || !template) return null;

    const targetGroup = findQuestionInTemplate(question.id)?.question?.comparison_group;
    const questionIds = [];
    template.parts.forEach(part => {
      part.questions.forEach(q => {
        if (q.comparison_group === targetGroup) {
          questionIds.push(q.id);
        }
      });
    });

    const currentPartAverages = calculatePartAverages(
      { responses: collection.responses, collection_type: collection.collection_type },
      questionIds,
      false
    );

    const comparisonPartAveragesList = comparisonData.map(comp =>
      calculatePartAverages(comp, questionIds, true)
    );

    const allPartNames = new Set([
      ...Object.keys(currentPartAverages),
      ...comparisonPartAveragesList.flatMap(avg => Object.keys(avg))
    ]);

    const data = Array.from(allPartNames).map(partName => {
      const currentAverage = currentPartAverages[partName]?.average || 0;
      const comparisonValues = comparisonPartAveragesList.map(avg => avg[partName]?.average || 0);
      return {
        partName,
        currentAverage,
        comparisonAverages: comparisonValues
      };
    });

    data.sort((a, b) => b.currentAverage - a.currentAverage);

    return {
      series: [
        {
          name: t('Current'),
          data: data.map(d => d.currentAverage)
        },
        ...comparisonData.map((comp, idx) => ({
          name: comp.collection_info?.period_value || `${t('Comparison')} ${idx+1}`,
          data: data.map(d => d.comparisonAverages[idx])
        }))
      ],
      labels: data.map(d => d.partName)
    };
  }, [collection, comparisonData, question, template, userLang, t]);

  console.log("RadarChart - question:", question);
  console.log("RadarChart - collection:", collection);
  console.log("RadarChart - template:", template);
  console.log("RadarChart - comparisonData:", comparisonData);

  if (!chartData) return null;

  const range = question.range || [-3, 3];
  const [minRange, maxRange] = range;
  const tickAmount = Math.abs(maxRange - minRange);

  const options = {
    chart: {
      type: 'radar',
      toolbar: { show: false }
    },
    xaxis: {
      categories: chartData.labels,
      labels: {
        show: true,
        style: { fontSize: '12px', fontFamily: 'inherit' }
      }
    },
    yaxis: {
      min: minRange,
      max: maxRange,
      tickAmount: tickAmount
    },
    markers: { size: 4 },
    fill: { opacity: 0.3 },
    stroke: { width: 3 },
    colors: ['#2A9D8F', '#E9C46A', '#F4A261', '#264653'],
    legend: {
      show: true,
      position: 'top',
      horizontalAlign: 'right'
    },
    plotOptions: {
      radar: {
        polygons: {
          strokeColors: '#e2e8f0',
          strokeWidth: 1,
          connectorColors: '#e2e8f0'
        }
      }
    },
    tooltip: {
      y: { formatter: (value) => value.toFixed(1) }
    },
    theme: {
      mode: 'light',
      palette: 'palette1'
    }
  };

  // New helper to get comparison averages for a specific question from comparisonData
  const getComparisonAveragesForQuestion = (fullQuestionId) => {
    const [qId, iId] = fullQuestionId.split('.');
    const { question: q } = findQuestionInTemplate(qId) || {};
    if (!q) return [];

    return comparisonData.map(comp => {
      const qData = comp.responses?.comparison?.[qId];
      if (!qData) return null;

      // rating_block: use item_averages[iId]
      if (q.type === 'rating_block' && qData.item_averages && qData.item_averages[iId] !== undefined) {
        return qData.item_averages[iId];
      }

      // rating: use qData.average (if itemId doesn't apply)
      if (q.type === 'rating' && qData.average !== undefined && !iId) {
        return qData.average;
      }

      return null;
    });
  };

  const renderQuestionRow = (item, isTop, comparisonCount) => {
    switch (comparisonCount) {
      case 0:
        return (
          <div key={item.questionId} className="grid grid-cols-[auto,50px] gap-2 items-center text-sm py-1">
            {/* Question Text */}
            <span className="truncate pr-4 text-left">{item.text}</span>
  
            {/* Current Value */}
            <span
              className={`font-medium ${
                isTop ? "text-green-600" : "text-red-600"
              } text-right`}
            >
              {item.average.toFixed(2)}
            </span>
          </div>
        );
  
      case 1:
        return (
          <div key={item.questionId} className="grid grid-cols-[auto,50px,50px] gap-2 items-center text-sm py-1">
            {/* Question Text */}
            <span className="truncate pr-4 text-left">{item.text}</span>
  
            {/* Current Value */}
            <span
              className={`font-medium ${
                isTop ? "text-green-600" : "text-red-600"
              } text-right`}
            >
              {item.average.toFixed(2)}
            </span>
  
            {/* Comparison Value */}
            <span className="text-xs font-medium text-gray-600 text-right">
              {getComparisonAveragesForQuestion(item.questionId)[0]?.toFixed(2) || "—"}
            </span>
          </div>
        );
  
      case 2:
        return (
          <div key={item.questionId} className="grid grid-cols-[auto,50px,50px,50px] gap-2 items-center text-sm py-1">
            {/* Question Text */}
            <span className="truncate pr-4 text-left">{item.text}</span>
  
            {/* Current Value */}
            <span
              className={`font-medium ${
                isTop ? "text-green-600" : "text-red-600"
              } text-right`}
            >
              {item.average.toFixed(2)}
            </span>
  
            {/* Comparison Values */}
            {getComparisonAveragesForQuestion(item.questionId).map((avg, idx) => (
              <span key={idx} className="text-xs font-medium text-gray-600 text-right">
                {avg?.toFixed(2) || "—"}
              </span>
            ))}
          </div>
        );
  
      default:
        return null;
    }
  };
  
  return (
    <div className="bg-white p-4 rounded-lg shadow">
      <h3 className="text-xl font-bold mb-4">
        {getTranslatedValue(question.comparison_group_name, userLang)}
      </h3>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {/* Chart Section */}
        <div className="w-full">
          <Chart
            options={options}
            series={chartData.series}
            type="radar"
            height="100%"
            width="100%"
          />
        </div>
  
        {/* Summary Section */}
      <div className="w-full space-y-6 pr-4">
        {/* Biggest Takeaways */}
        <div>
          <h4 className="text-lg font-semibold mb-3">{t('Biggest Takeaways')}</h4>
          <div className="space-y-6">
            {/* Top 5 */}
            <div>
              <div className={`grid ${comparisonData.length === 0
                ? "grid-cols-[auto,50px]"
                : comparisonData.length === 1
                ? "grid-cols-[auto,50px,50px]"
                : "grid-cols-[auto,50px,50px,50px]"
              } gap-2 items-center mb-2`}
              >
                <h5 className="text-sm font-medium text-gray-500 text-left">
                  {t('Top 5')}
                </h5>
                {comparisonData.length > 0 && (
                  <span className="text-xs font-medium text-gray-600 text-right">
                    Current
                  </span>
                )}
                {comparisonData.map((comp, idx) => (
                  <span
                    key={idx}
                    className="text-xs font-medium text-gray-600 text-right"
                  >
                    {comp.collection_info?.period_value || `Comp ${idx + 1}`}
                  </span>
                ))}
              </div>
              {questionAverages.slice(0, 5).map((item) =>
                renderQuestionRow(item, true, comparisonData.length)
              )}
            </div>
            {/* Bottom 5 */}
            <div>
              <div className={`grid ${comparisonData.length === 0
                ? "grid-cols-[auto,50px]"
                : comparisonData.length === 1
                ? "grid-cols-[auto,50px,50px]"
                : "grid-cols-[auto,50px,50px,50px]"
              } gap-2 items-center mb-2`}
              >
                <h5 className="text-sm font-medium text-gray-500 text-left">
                  {t('Bottom 5')}
                </h5>
                {comparisonData.length > 0 && (
                  <span className="text-xs font-medium text-gray-600 text-right">
                    Current
                  </span>
                )}
                {comparisonData.map((comp, idx) => (
                  <span
                    key={idx}
                    className="text-xs font-medium text-gray-600 text-right"
                  >
                    {comp.collection_info?.period_value || `Comp ${idx + 1}`}
                  </span>
                ))}
              </div>
              {questionAverages.slice(-5).reverse().map((item) =>
                renderQuestionRow(item, false, comparisonData.length)
              )}
            </div>
          </div>
        </div>

          {/* Top Debaters */}
          {userStats.length > 0 && collection.is_anonymous === false && (
            <div>
              <h4 className="text-lg font-semibold mb-3">{t('Top Debaters')}</h4>
              <div className="space-y-3">
                {/* Highest Scorer */}
                <div className="p-3 bg-green-50 rounded-lg">
                  <div className="font-medium text-green-700">{userStats[0].name}</div>
                  <div className="text-sm text-green-600">
                    {t('Average')}: {userStats[0].average.toFixed(2)}
                    <span className="text-green-500 text-xs ml-2">
                      ({userStats[0].maxScores} {t('max scores')})
                    </span>
                  </div>
                </div>
                {/* Lowest Scorer */}
                <div className="p-3 bg-red-50 rounded-lg">
                  <div className="font-medium text-red-700">{userStats[userStats.length - 1].name}</div>
                  <div className="text-sm text-red-600">
                    {t('Average')}: {userStats[userStats.length - 1].average.toFixed(2)}
                    <span className="text-red-500 text-xs ml-2">
                      ({userStats[userStats.length - 1].minScores} {t('min scores')})
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RadarChart;