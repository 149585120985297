import React, { useState, useEffect } from 'react';
import { useNavigate, useBeforeUnload } from 'react-router-dom';
import TemplateStep from './collection_components/TemplateStep';
import PeopleStep from './collection_components/PeopleStep';
import AssignmentStep from './collection_components/AssignmentStep';
import TimingStep from './collection_components/TimingStep';
import FinishStep from './collection_components/FinishStep';
import ProgressTracker from './collection_components/ProgressTracker';
import { useCollection } from './collection_components/CollectionContext';
import { useTranslation } from 'react-i18next';
import axios from '../../axios';

const CreateCollection = ({ onComplete }) => {
  const navigate = useNavigate();
  const { formData, setFormData, currentStep, setCurrentStep, createCollection, clearFormData } = useCollection();
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(null);
  const { t } = useTranslation();
  const [template, setTemplate] = useState(null);

  // Fetch the template when templateId changes
  useEffect(() => {
    if (!formData.templateId) return;

    const fetchTemplate = async () => {
      try {
        const response = await axios.get(`/api/v1/data_collections/templates/${formData.templateId}`, {
          headers: { Authorization: `Bearer ${sessionStorage.getItem('authToken')}` },
        });
        setTemplate(response.data);
      } catch (error) {
        console.error('Error fetching template:', error);
      }
    };

    fetchTemplate();
  }, [formData.templateId]);

  // Validation function for assignments
  const validateAssignments = () => {
    if (!template || !template.template_data || !template.template_data.parts) return false;

    return template.template_data.parts.every((part) => {
      const partAssignments = formData.assignments[part.id] || {};
      if (formData.collectionType === 'individual') {
        return (partAssignments.assignees || []).length > 0;
      } else {
        return (partAssignments.submitters || []).length > 0;
      }
    });
  };

  // Overall validation function for the current step
  const isNextEnabled = () => {
    switch (currentStep) {
      case 1: // Template Step
        return formData.templateId !== null;
      case 2: // People Step
        return (
          formData.viewers && formData.viewers.length > 0 &&
          formData.respondents && formData.respondents.length > 0
        );
      case 3: // Assignment Step
        return validateAssignments();
      case 4: // Timing Step
        return (
          formData.deadlineType !== 'deadline' ||
          (formData.deadline && formData.deadline !== '')
        );
      default:
        return true;
    }
  };

  const steps = [
    { id: 1, name: 'Template', component: TemplateStep },
    { id: 2, name: 'People', component: PeopleStep },
    { id: 3, name: 'Assignment', component: AssignmentStep },
    { id: 4, name: 'Timing', component: TimingStep },
    { id: 5, name: 'Finish', component: FinishStep },
  ];

  const CurrentStepComponent = steps[currentStep - 1]?.component;

  // Warn user when trying to leave with unsaved changes
  useBeforeUnload(
    React.useCallback(
      (event) => {
        if (formData.templateId && !saving) {
          event.preventDefault();
          return (event.returnValue = 'You have unsaved changes. Are you sure you want to leave?');
        }
      },
      [formData.templateId, saving]
    )
  );

  // Handle navigation away from component
  useEffect(() => {
    return () => {
      // Only clear if we're not saving (prevents clearing during successful save)
      if (!saving) {
        clearFormData();
      }
    };
  }, [saving, clearFormData]);

  const handleNext = () => {
    if (currentStep < steps.length) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handleBack = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleSave = async () => {
    setSaving(true);
    setError(null);
    try {
      await createCollection();
      clearFormData();
      onComplete?.();
      navigate('/reports');
    } catch (err) {
      setError(err.message);
      setSaving(false);
    }
  };

  if (!CurrentStepComponent) {
    return <div>Error: Invalid Step</div>;
  }

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">{t('Create New Data Collection')}</h1>
      <ProgressTracker
        steps={steps}
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
      />
      <div className="mt-8">
        <CurrentStepComponent
          formData={formData}
          setFormData={setFormData}
          onSave={handleSave}
          saving={saving}
          error={error}
        />
      </div>
      <div className="mt-8 flex justify-between">
        <button
          onClick={handleBack}
          disabled={currentStep === 1 || saving}
          className={`px-4 py-2 text-sm font-medium rounded-md ${currentStep === 1 || saving
              ? 'text-gray-400 cursor-not-allowed'
              : 'text-gray-700 hover:text-gray-900'
            }`}
        >
          {t('Back')}
        </button>
        {currentStep === steps.length ? (
          // Save & Close button remains unchanged
          <button
            onClick={handleSave}
            disabled={saving}
            className={`px-4 py-2 text-sm font-medium rounded-md text-white bg-primary-color hover:bg-secondary-color ${saving ? 'opacity-50 cursor-not-allowed' : ''
              }`}
          >
            {saving ? t('Saving...') : t('Save & Close')}
          </button>
        ) : (
          <button
            onClick={handleNext}
            disabled={saving || !isNextEnabled()}
            className={`px-4 py-2 text-sm font-medium rounded-md text-white bg-primary-color hover:bg-secondary-color ${saving || !isNextEnabled() ? 'opacity-50 cursor-not-allowed' : ''
              }`}
          >
            {t('Next')}
          </button>
        )}
      </div>
    </div>
  );
};

export default CreateCollection;