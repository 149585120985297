import React from 'react';
import { getTranslatedValue } from '../../../utils/templateTranslations';

const MultipleNumbersQuestion = ({
    question,
    value = {},
    onChange,
    notes,
    onNotesChange,
    showNotes,
    notesLabel,
    language,
    readOnly
}) => {
    const labels = question?.labels || {};
    const labelKeys = Object.keys(labels);
    const unit = getTranslatedValue(question?.units || {}, language);

    const handleNumberChange = (labelKey, newValue) => {
        const val = parseFloat(newValue);
        onChange({
            ...value,
            [labelKey]: {
                ...value[labelKey],
                value: !isNaN(val) ? val : ''
            }
        });
    };

    const handleTextChange = (labelKey, newText) => {
        onChange({
            ...value,
            [labelKey]: {
                ...value[labelKey],
                text: newText
            }
        });
    };

    if (labelKeys.length === 0) {
        return <div className="text-red-600">Invalid question configuration: no labels found</div>;
    }

    return (
        <div className="space-y-4 mt-10">
            <div className="text-base font-medium text-gray-900">
                {getTranslatedValue(question?.text || {}, language)}
            </div>

            <div className="grid grid-cols-12 gap-4">
                {/* Rows */}
                {labelKeys.map((labelKey) => {
                    const hasTextField = labels[labelKey].has_text_field;
                    
                    return (
                        <React.Fragment key={labelKey}>
                            {/* Label */}
                            <div className="col-span-3 flex items-center">
                                <span className="text-sm text-gray-700">
                                    {getTranslatedValue(labels[labelKey], language)}
                                </span>
                            </div>

                            {/* Text Field (if applicable) */}
                            <div className="col-span-5 flex items-center">
                                {hasTextField && (
                                    <input
                                        type="text"
                                        value={value[labelKey]?.text ?? ''}
                                        onChange={(e) => handleTextChange(labelKey, e.target.value)}
                                        placeholder={language === 'de' ? 'Bezeichnung' : 'Description'}
                                        disabled={readOnly}
                                        className={`block w-full rounded-md shadow-sm sm:text-sm ${
                                            readOnly
                                                ? 'bg-gray-200 text-gray-700 border-gray-200 cursor-default'
                                                : 'border-gray-300 focus:border-secondary-color focus:ring-secondary-color'
                                        }`}
                                    />
                                )}
                            </div>

                            {/* Number Input and Unit */}
                            <div className="col-span-4 flex items-center space-x-2">
                                <input
                                    type="number"
                                    value={value[labelKey]?.value ?? ''}
                                    onChange={(e) => handleNumberChange(labelKey, e.target.value)}
                                    disabled={readOnly}
                                    className={`block w-full rounded-md shadow-sm sm:text-sm ${
                                        readOnly
                                            ? 'bg-gray-200 text-gray-700 border-gray-200 cursor-default'
                                            : 'border-gray-300 focus:border-secondary-color focus:ring-secondary-color'
                                    }`}
                                />
                                {unit && <span className="text-gray-700">{unit}</span>}
                            </div>
                        </React.Fragment>
                    );
                })}
            </div>

            {showNotes && (
                <div className="mt-4">
                    <label className="block text-sm font-medium text-gray-700">
                        {notesLabel}
                    </label>
                    <textarea
                        value={notes || ''}
                        onChange={(e) => onNotesChange(e.target.value)}
                        rows={3}
                        disabled={readOnly}
                        className={`mt-1 block w-full rounded-md shadow-sm sm:text-sm ${
                            readOnly
                                ? 'bg-gray-200 text-gray-700 border-gray-200 cursor-default'
                                : 'border-gray-300 focus:border-secondary-color focus:ring-secondary-color'
                        }`}
                    />
                </div>
            )}
        </div>
    );
};

export default MultipleNumbersQuestion;