import React from 'react';
import { getTranslatedValue } from '../../../utils/templateTranslations';

const TextQuestion = ({
  question,
  value,
  onChange,
  notes,
  onNotesChange,
  showNotes,
  notesLabel,
  language,
  readOnly
}) => {
  return (
    <div className="space-y-4 mt-10">
      <div className="text-base font-medium text-gray-900">
        {getTranslatedValue(question.text, language)}
      </div>

      <textarea
        value={value || ''}
        onChange={(e) => onChange(e.target.value)}
        rows={4}
        disabled={readOnly}
        className={`mt-1 block w-full rounded-md shadow-sm sm:text-sm ${readOnly
          ? 'bg-gray-200 text-gray-700 border-gray-200 cursor-default'
          : 'border-gray-300 focus:border-secondary-color focus:ring-secondary-color'
        }`}
      />

      {showNotes && (
        <div>
          <label className="block text-sm font-medium text-gray-700">
            {notesLabel}
          </label>
          <textarea
            value={notes || ''}
            onChange={(e) => onNotesChange(e.target.value)}
            rows={3}
            disabled={readOnly}
            className={`mt-1 block w-full rounded-md shadow-sm sm:text-sm ${readOnly
              ? 'bg-gray-200 text-gray-700 border-gray-200 cursor-default'
              : 'border-gray-300 focus:border-secondary-color focus:ring-secondary-color'
            }`}
          />
        </div>
      )}
    </div>
  );
};

export default TextQuestion;
