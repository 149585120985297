import React from 'react';
import { getTranslatedValue } from '../../../utils/templateTranslations';

const RatingQuestion = ({
  question,
  value,
  onChange,
  notes,
  onNotesChange,
  showNotes,
  notesLabel,
  language,
  readOnly
}) => {
  const range = Array.from(
    { length: question.range[1] - question.range[0] + 1 },
    (_, i) => question.range[0] + i
  );

  const leftLabel = getTranslatedValue(question.left_label, language);
  const rightLabel = getTranslatedValue(question.right_label, language);

  return (
    <div className="space-y-4 mt-10">
      <div className="text-base font-medium text-gray-900">
        {getTranslatedValue(question.text, language)}
      </div>

      <div className="flex items-center space-x-2">
        {/* Left Label */}
        {leftLabel && (
          <span className="text-sm text-gray-600 w-24 text-right pr-2">
            {leftLabel}
          </span>
        )}

        {/* Rating Buttons */}
        <div className="flex space-x-2">
          {range.map((number) => {
            const isSelected = value === number;
            return (
              <button
                key={number}
                type="button"
                onClick={() => onChange(number)}
                disabled={readOnly}
                className={`
                  w-10 h-10 rounded-full flex items-center justify-center
                  border text-sm font-medium transition-colors
                  ${readOnly
                    ? isSelected
                      ? 'bg-gray-400 text-white border-gray-400 cursor-default'
                      : 'bg-gray-200 text-gray-700 border-gray-200 cursor-default'
                    : isSelected
                      ? 'bg-secondary-color text-white border-secondary-color'
                      : 'bg-white text-gray-700 border-gray-300 hover:bg-gray-50'
                  }
                `}
              >
                {number}
              </button>
            );
          })}
        </div>

        {/* Right Label */}
        {rightLabel && (
          <span className="text-sm text-gray-600 w-24 text-left pl-2">
            {rightLabel}
          </span>
        )}
      </div>

      {showNotes && (
        <div className="mt-4">
          <label className="block text-sm font-medium text-gray-700">
            {notesLabel}
          </label>
          <textarea
            value={notes || ''}
            onChange={(e) => onNotesChange(e.target.value)}
            rows={3}
            disabled={readOnly}
            className={`mt-1 block w-full rounded-md shadow-sm sm:text-sm ${readOnly
              ? 'bg-gray-200 text-gray-700 border-gray-200 cursor-default'
              : 'border-gray-300 focus:border-secondary-color focus:ring-secondary-color'
            }`}
          />
        </div>
      )}
    </div>
  );
};

export default RatingQuestion;