import React from 'react';
import { getTranslatedValue } from '../../../utils/templateTranslations';
import { ExclamationTriangleIcon, LifebuoyIcon, GiftIcon } from '@heroicons/react/24/outline';

const ColorTextChart = ({
  question,
  collection,
  comparisonData = [],
  language = 'en'
}) => {
  const getAnalysisTypeColor = (analysisType) => {
    switch (analysisType) {
      case 'text_red':
        return {
          bg: 'bg-red-50',
          text: 'text-red-700',
          icon: ExclamationTriangleIcon,
          iconColor: 'text-red-400'
        };
      case 'text_yellow':
        return {
          bg: 'bg-yellow-50',
          text: 'text-yellow-700',
          icon: LifebuoyIcon,
          iconColor: 'text-yellow-400'
        };
      case 'text_green':
        return {
          bg: 'bg-green-50',
          text: 'text-green-700',
          icon: GiftIcon,
          iconColor: 'text-green-400'
        };
      default:
        return {
          bg: 'bg-gray-50',
          text: 'text-gray-700',
          icon: null,
          iconColor: 'text-gray-400'
        };
    }
  };

  const findResponse = (collectionData) => {
    return collectionData.responses?.find(response => 
      response.response_data?.[question.id] !== undefined
    )?.response_data?.[question.id];
  };

  const currentResponse = findResponse(collection) || '';
  const analysisType = question.analysis_types?.[0];
  const { bg, text, icon: Icon, iconColor } = getAnalysisTypeColor(analysisType);

  return (
    <div className={`w-full rounded-lg shadow-sm ${bg} p-6`}>
      <div className="flex items-start gap-4">
        {Icon && (
          <div className={`${iconColor} flex-shrink-0 mt-1`}>
            <Icon className="h-6 w-6" />
          </div>
        )}
        <div className="flex-grow">
          <h3 className={`text-base font-semibold mb-4 ${text}`}>
            {getTranslatedValue(question.text, language)}
          </h3>
          <div className="space-y-4">
            <p className={`whitespace-pre-wrap ${text}`}>
              {currentResponse || '-'}
            </p>
            {comparisonData.map((comp, index) => {
              const comparisonResponse = comp.responses?.comparison?.[question.id]?.examples?.[0];
              if (!comparisonResponse) return null;
              
              return (
                <div key={index} className="mt-4 pt-4 border-t border-gray-200">
                  <p className="text-sm text-gray-500 mb-2">
                    {comp.collection_info.period_value}:
                  </p>
                  <p className={`whitespace-pre-wrap ${text}`}>
                    {comparisonResponse}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ColorTextChart;