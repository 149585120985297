import React, { useState, useMemo } from 'react';
import { Popover, Switch } from '@headlessui/react';
import { UsersIcon } from '@heroicons/react/24/solid';

const ComparisonSelector = ({
  comparableCollections,
  onComparisonSelect,
  selectedComparisons = [],
  currentPeriodValue,
  currentPeriodType,
  t
}) => {
  // Always call hooks at the top-level, unconditionally
  const [showComparison, setShowComparison] = useState(false);
  const [selectedComparisonIds, setSelectedComparisonIds] = useState(selectedComparisons);

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString();
  };

  const parsePeriodValue = (periodValue, periodType) => {
    if (periodType === 'monthly') {
      const [year, month] = periodValue.split('-').map(Number);
      return { year, month, quarter: null };
    } else if (periodType === 'quarterly') {
      const year = parseInt(periodValue.substring(0, 4), 10);
      const q = parseInt(periodValue.substring(5, 6), 10);
      return { year, month: null, quarter: q };
    } else {
      const year = parseInt(periodValue, 10);
      return { year, month: null, quarter: null };
    }
  };

  const getExpectedPreviousValues = (current, periodType) => {
    if (periodType === 'monthly') {
      const date = new Date(current.year, current.month - 1, 1);
      const prevMonthDate = new Date(date.getFullYear(), date.getMonth() - 1, 1);
      const prevYearDate = new Date(date.getFullYear() - 1, date.getMonth(), 1);
      const prevPeriodValue = `${prevMonthDate.getFullYear()}-${String(prevMonthDate.getMonth() + 1).padStart(2, '0')}`;
      const yearAgoPeriodValue = `${prevYearDate.getFullYear()}-${String(prevYearDate.getMonth() + 1).padStart(2, '0')}`;
      return [prevPeriodValue, yearAgoPeriodValue];
    } else if (periodType === 'quarterly') {
      const currentQ = current.quarter;
      const currentY = current.year;
      let prevY = currentY;
      let prevQ = currentQ - 1;
      if (prevQ < 1) {
        prevY -= 1;
        prevQ = 4;
      }
      const prevPeriodValue = `${prevY}Q${prevQ}`;
      const yearAgoY = currentY - 1;
      const yearAgoPeriodValue = `${yearAgoY}Q${currentQ}`;
      return [prevPeriodValue, yearAgoPeriodValue];
    } else {
      const prevYear = current.year - 1;
      const prevPeriodValue = `${prevYear}`;
      const yearAgoPeriodValue = `${current.year - 2}`;
      return [prevPeriodValue, yearAgoPeriodValue];
    }
  };

  // If comparableCollections is null or empty, define a safe fallback
  const safeCollections = comparableCollections || [];

  const currentParsed = useMemo(
    () => parsePeriodValue(currentPeriodValue, currentPeriodType),
    [currentPeriodValue, currentPeriodType]
  );
  const [expectedPrev, expectedYearAgo] = useMemo(
    () => getExpectedPreviousValues(currentParsed, currentPeriodType),
    [currentParsed, currentPeriodType]
  );

  const recommendedCollections = useMemo(() => {
    if (safeCollections.length === 0) return [];

    const findClosest = (targetValue) => {
      let targetDate;
      if (currentPeriodType === 'monthly') {
        const [yr, mo] = targetValue.split('-').map(Number);
        targetDate = new Date(yr, mo - 1, 15);
      } else if (currentPeriodType === 'quarterly') {
        const year = parseInt(targetValue.substring(0, 4), 10);
        const q = parseInt(targetValue.substring(5, 6), 10);
        const monthOffset = (q - 1) * 3;
        targetDate = new Date(year, monthOffset + 1, 15);
      } else {
        const year = parseInt(targetValue, 10);
        targetDate = new Date(year, 6, 1);
      }

      let chosen = null;
      let minDiff = Infinity;
      safeCollections.forEach(c => {
        const cDate = new Date(c.starts_at);
        const diff = Math.abs(cDate - targetDate);
        if (diff < minDiff) {
          minDiff = diff;
          chosen = c;
        }
      });
      return chosen;
    };

    const recommended1 = findClosest(expectedPrev);
    const recommended2 = findClosest(expectedYearAgo);
    return [recommended1, recommended2].filter(Boolean);
  }, [safeCollections, currentPeriodType, expectedPrev, expectedYearAgo]);

  const handleToggleComparison = () => {
    setShowComparison(!showComparison);
    if (!showComparison) {
      // Reset selected comparisons when enabling or disabling
      setSelectedComparisonIds([]);
      onComparisonSelect([]);
    }
  };

  const handleCheckboxChange = (access_token) => {
    let newSelection;
    if (selectedComparisonIds.includes(access_token)) {
      newSelection = selectedComparisonIds.filter(id => id !== access_token);
    } else {
      if (selectedComparisonIds.length >= 2) return; // Restrict to max 2 selections
      newSelection = [...selectedComparisonIds, access_token];
    }
    setSelectedComparisonIds(newSelection);
    onComparisonSelect(newSelection);
  };

  const isRecommended = (c) => recommendedCollections.some(r => r && r.access_token === c.access_token);

  // After all hooks, now we can return early if there are no collections
  if (safeCollections.length === 0) {
    return null;
  }

  return (
    <div className="bg-white rounded-lg shadow p-6 mb-8">
      <div className="flex items-center justify-start">
        <Switch
          checked={showComparison}
          onChange={handleToggleComparison}
          className={`${showComparison ? 'bg-secondary-color' : 'bg-gray-200'
            } relative inline-flex h-6 w-11 items-center rounded-full mr-3`}
        >
          <span
            className={`${showComparison ? 'translate-x-6' : 'translate-x-1'
              } inline-block h-4 w-4 transform bg-white rounded-full transition`}
          />
        </Switch>
        <h3 className="text-lg font-medium leading-6 text-gray-900">
          {t('Compare with Previous Reports')}
        </h3>
      </div>

      {showComparison && (
        <table className="min-w-full divide-y divide-gray-300 text-sm mt-6">
          <thead className="bg-gray-50">
            <tr>
              <th className="w-12 py-3 px-2" />
              <th className="py-3 px-2 text-left font-medium text-gray-700">{t('Period')}</th>
              <th className="py-3 px-2 text-left font-medium text-gray-700">{t('Started At')}</th>
              <th className="py-3 px-2 text-left font-medium text-gray-700">{t('Created By')}</th>
              <th className="py-3 px-2 text-left font-medium text-gray-700">{t('Data Sources')}</th>
              <th className="w-20 py-3 px-2" />
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {safeCollections.map((collection) => {
              const checked = selectedComparisonIds.includes(collection.access_token);
              const recommended = isRecommended(collection);

              return (
                <tr key={collection.access_token} className={recommended ? 'bg-secondary-color/10' : ''}>
                  <td className="py-2 px-2">
                    <div className="flex items-center">
                      <input
                        type="checkbox"
                        checked={checked}
                        onChange={() => handleCheckboxChange(collection.access_token)}
                        className="h-4 w-4 rounded border-gray-300 text-secondary-color focus:ring-secondary-color"
                      />
                    </div>
                  </td>
                  <td className="py-2 px-2">{collection.period_value}</td>
                  <td className="py-2 px-2">{formatDate(collection.starts_at)}</td>
                  <td className="py-2 px-2">{collection.created_by?.name || t('Unknown')}</td>
                  <td className="py-2 px-2">
                    <div className="flex items-center">
                      <Popover className="relative">
                        <Popover.Button className="flex items-center text-primary-color hover:text-secondary-color">
                          <UsersIcon className="h-4 w-4 text-primary-color mr-1" />
                          <span>{collection.respondents.length}</span>
                        </Popover.Button>
                        <Popover.Panel className="absolute z-10 w-64 bg-white p-3 rounded shadow-lg">
                          <div className="flow-root">
                            {collection.respondents.map((respondent, idx) => (
                              <div key={idx} className="py-1">
                                <span className="text-gray-700">{respondent.user_name}</span>
                              </div>
                            ))}
                          </div>
                        </Popover.Panel>
                      </Popover>
                    </div>
                  </td>
                  <td className="py-2 px-2">
                    {recommended && (
                      <span className="inline-block bg-secondary-color text-white text-left text-xs px-2 py-1 rounded">
                        {t('Recommended')}
                      </span>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default ComparisonSelector;