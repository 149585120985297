import React, { useState, useContext } from 'react';
import axios from '../../axios'; // Import the configured Axios instance
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import { EnvelopeIcon, EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline'; // Import the eye icons from Heroicons
import { useTranslation } from 'react-i18next';

export default function Login() {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [otp, setOtp] = useState(new Array(6).fill(''));
  const [showOtp, setShowOtp] = useState(false);
  const [message, setMessage] = useState('');
  const [showEnvelope, setShowEnvelope] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const { login } = useContext(AuthContext);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post('/api/v1/users/sign_in', {
        user: {
          email,
          password,
        }
      });

      if (response.data.message === 'OTP sent') {
        sessionStorage.setItem('email', email);
        setShowOtp(true);
        setMessage(t('otpSent'));
        setShowEnvelope(true);
      }
    } catch (error) {
      console.error('Error logging in:', error.response?.data || error.message);
      setMessage(t('loginError'));
    }
  };

  const handleOtpSubmit = async (event) => {
    event.preventDefault();
    try {
      const otpCode = otp.join('');
      const response = await axios.post('/users/two_factor_auth/verify_otp', {
        otp_attempt: otpCode,
      });
  
      if (response.data.message === 'OTP verified successfully') {
        const userData = response.data.user;
        
        console.log('Login - Payment status check:', {
          hasStripeCustomerId: !!userData.entity?.stripe_customer_id,
          subscriptionStatus: userData.entity?.subscription_status,
          paymentPending: localStorage.getItem('paymentPending'),
          returnToPayment: localStorage.getItem('returnToPayment')
        });
  
        // Only redirect to payment if actually needed
        const needsPayment = !userData.entity?.stripe_customer_id || 
                            userData.entity?.subscription_status === 'pending' ||
                            userData.entity?.subscription_status === 'pending_activation';
  
        if (needsPayment) {
          console.log('Login - User needs payment, creating checkout session');
          try {
            localStorage.setItem('authToken', userData.authentication_token);
            
            const checkoutResponse = await axios.post(
              '/api/v1/stripe/create_checkout_session',
              {
                success_url: `${window.location.origin}/registration-success?payment_status=succeeded`,
                cancel_url: `${window.location.origin}/registration-success?payment_status=failed`,
                tier: 'founder'
              },
              {
                headers: { Authorization: `Bearer ${userData.authentication_token}` }
              }
            );
  
            window.location.href = checkoutResponse.data.url;
          } catch (error) {
            console.error('Checkout session creation failed:', error.response?.data);
            
            if (error.response?.status === 422) {
              // If the user actually doesn't need payment anymore, proceed with login
              if (error.response.data.error === 'Invalid subscription status') {
                console.log('Login - User actually has valid subscription, proceeding with login');
                login(userData);
                const returnTo = location.state?.returnTo;
                navigate(returnTo || '/login-successful');
                return;
              }
            }
            
            // Otherwise show error and return to login
            setMessage(t('errors.payment_setup_failed'));
            navigate('/login');
            return;
          }
        } else {
          console.log('Login - User has valid subscription, proceeding with login');
          login(userData);
          const returnTo = location.state?.returnTo;
          navigate(returnTo || '/login-successful');
        }
      }
    } catch (error) {
      console.error('Error verifying OTP:', error.response?.data || error.message);
      setMessage(t('otpError'));
    }
  };

  const handleOtpChange = (element, index) => {
    const value = element.value;
    if (/^[0-9]$/.test(value) || value === '') {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      // Focus on next input field if not last one and if value is not empty
      if (value !== '' && index < 5) {
        document.getElementById(`otp-${index + 1}`).focus();
      }
      // Focus on previous input field if value is empty and if index is greater than 0
      if (value === '' && index > 0) {
        document.getElementById(`otp-${index - 1}`).focus();
      }
    }
  };

  const handlePaste = (e) => {
    const paste = e.clipboardData.getData('text');
    const newOtp = paste.split('').slice(0, 6); // Take only first 6 digits
    setOtp(newOtp);
    newOtp.forEach((value, index) => {
      document.getElementById(`otp-${index}`).value = value;
    });
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
      <div className="min-h-screen bg-gray-100 flex flex-col items-center justify-center p-6">
        <div className="flex justify-center mb-6">
          <img src="/logo_twocolor.svg" alt="Cenedril Icon" className="h-16 mb-4" />
        </div>
        <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md">
          <h2 className="text-2xl font-bold mb-6 text-center">{t('signIn')}</h2>
          <form onSubmit={showOtp ? handleOtpSubmit : handleSubmit}>
            {!showOtp ? (
              <>
                <div className="space-y-4">
                  <div>
                    <label htmlFor="email" className="block text-sm font-medium text-tertiary-color">{t('email')}</label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-color focus:ring-primary-color sm:text-sm px-3 py-2"
                    />
                  </div>
                  <div className="relative">
                    <label htmlFor="password" className="block text-sm font-medium text-tertiary-color">{t('password')}</label>
                    <input
                      type={showPassword ? 'text' : 'password'}
                      name="password"
                      id="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-color focus:ring-primary-color sm:text-sm px-3 py-2 pr-10"
                    />
                    <button
                      type="button"
                      onClick={togglePasswordVisibility}
                      className="absolute top-1/2 right-0 transform -y-1/2 px-3 flex items-center text-sm leading-5"
                    >
                      {showPassword ? <EyeSlashIcon className="h-5 w-5 text-gray-500" /> : <EyeIcon className="h-5 w-5 text-gray-500" />}
                    </button>
                  </div>
                </div>
                {message && (
                  <div className="mt-4 text-center text-red-500 text-sm">
                    {message}
                  </div>
                )}
                <div className="mt-4 flex items-center justify-between">
                  <Link to="/forgot-password" className="text-sm text-primary-color">
                    {t('forgotPassword')}
                  </Link>
                  <div className="flex items-center gap-x-4">
                    <button type="button" className="text-sm text-primary-color" onClick={() => navigate('/')}>
                      {t('cancel')}
                    </button>
                    <button
                      type="submit"
                      className="rounded-md bg-primary-color px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-secondary-color focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-secondary-color"
                    >
                      {t('signIn')}
                    </button>
                  </div>
                </div>
              </>
            ) : (
              <>
                {showEnvelope && (
                  <div className="mt-4 flex items-center justify-center text-secondary-color animate-fly-in">
                    <EnvelopeIcon className="h-5 w-5 mr-2" />
                    <p className="text-center text-sm font-semibold">{message}</p>
                  </div>
                )}
                <div className="space-y-4 text-center mt-2">
                  <div className="flex justify-center space-x-2" onPaste={handlePaste}>
                    {otp.map((data, index) => (
                      <input
                        key={index}
                        type="text"
                        id={`otp-${index}`}
                        name={`otp-${index}`}
                        value={data}
                        onChange={(e) => handleOtpChange(e.target, index)}
                        className="md:w-12 w-10 h-12 text-center rounded-md border-gray-300 shadow-sm focus:border-primary-color focus:ring-primary-color sm:text-lg font-bold"
                        maxLength="1"
                      />
                    ))}
                  </div>
                </div>
                <div className="mt-6 flex flex-col items-center gap-y-2">
                  <button
                    type="submit"
                    className={`rounded-md bg-primary-color px-6 py-3 text-sm font-semibold text-white shadow-sm hover:bg-secondary-color focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-secondary-color ${otp.includes('') ? 'opacity-50 cursor-not-allowed' : ''}`}
                    disabled={otp.includes('')}
                  >
                    {t('verifyOtp')}
                  </button>
                  <button
                    type="button"
                    className="text-sm leading-6 text-tertiary-color"
                    onClick={() => navigate('/')}
                  >
                    {t('cancel')}
                  </button>
                </div>
              </>
            )}
          </form>
      </div>
    </div>
  );
}
