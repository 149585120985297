import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  MdOutlineSecurity,
  MdOutlineLockPerson,
  MdOutlineRuleFolder,
} from 'react-icons/md';
import { LuHeartHandshake } from 'react-icons/lu';
import { BsBarChartSteps } from 'react-icons/bs';
import { FaRegLightbulb } from 'react-icons/fa';

const Wizards = () => {
  const { t } = useTranslation();

  const actions = [
    {
      title: t('Startup Support'),
      href: '/startup-wizard',
      description: t('Startup Support Description'),
      iconSrc: 'https://cdn.lordicon.com/fttvwdlw.json',
      iconColors: 'primary:#2a9d8f,secondary:#2a9d8f',
      iconBackground: 'bg-teal-50',
      iconForeground: 'text-teal-700',
      enabled: true,
      isLordIcon: true,
    },
    {
      title: t('Financing'),
      href: '#',
      description: t('Find capital for your startup efficiently. Use this assistant to analyze different financing methods and prepare all the necessary information and documents.'),
      iconSrc: 'https://cdn.lordicon.com/eaegfqtv.json',
      iconColors: 'primary:#109121,secondary:#109121',
      iconBackground: 'bg-green-100',
      iconForeground: 'text-green-700',
      enabled: false,
      isLordIcon: true,
    },
    {
      title: t('Know Yourself'),
      href: '/know-yourself',
      description: t('Know Yourself Description'),
      icon: FaRegLightbulb,
      iconBackground: 'bg-yellow-50',
      iconForeground: 'text-yellow-700',
      enabled: true,
    },
    {
      title: t('Information Security'),
      href: '#',
      description: t('Information Security Description'),
      icon: MdOutlineSecurity,
      iconBackground: 'bg-purple-50',
      iconForeground: 'text-purple-700',
      enabled: false,
    },
    {
      title: t('Data Privacy'),
      href: '#',
      description: t('Data Privacy Description'),
      icon: MdOutlineLockPerson,
      iconBackground: 'bg-sky-50',
      iconForeground: 'text-sky-700',
      enabled: false,
    },
    {
      title: t('Legal, Tax & Financial Due Diligence'),
      href: '#',
      description: t('Legal, Tax & Financial Due Diligence Description'),
      icon: MdOutlineRuleFolder,
      iconBackground: 'bg-yellow-50',
      iconForeground: 'text-yellow-700',
      enabled: false,
    },
    {
      title: t('Vision & Culture'),
      href: '#',
      description: t('Vision & Culture Description'),
      icon: LuHeartHandshake,
      iconBackground: 'bg-rose-50',
      iconForeground: 'text-rose-700',
      enabled: false,
    },
    {
      title: t('Quality Management System'),
      href: '#',
      description: t('Quality Management System Description'),
      icon: BsBarChartSteps,
      iconBackground: 'bg-indigo-50',
      iconForeground: 'text-indigo-700',
      enabled: false,
    },
  ];

  useEffect(() => {
    const loadLordIcon = () => {
      const script = document.createElement('script');
      script.src = 'https://cdn.lordicon.com/lusqsztk.js';
      script.async = true;
      document.body.appendChild(script);
      return script;
    };

    const script = loadLordIcon();
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  return (
    <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-2 mt-12 md:mt-8 p-6 md:p-0">
      {actions.map((action, actionIdx) => (
        <div
          key={action.title}
          className={classNames(
            'relative overflow-hidden rounded-lg shadow group',
            action.enabled ? 'bg-white' : 'bg-gray-50 opacity-50 cursor-not-allowed'
          )}
        >
          <div className="p-6">
            <div>
              <span
                className={classNames(
                  action.iconBackground,
                  action.iconForeground,
                  'inline-flex rounded-lg p-3'
                )}
              >
                {action.isLordIcon ? (
                  <lord-icon
                    src={action.iconSrc}
                    trigger="loop"
                    colors={action.iconColors}
                    style={{ width: '40px', height: '40px' }}
                  />
                ) : (
                  <action.icon className="h-6 w-6" aria-hidden="true" />
                )}
              </span>
            </div>
            <div className="mt-8">
              <h3 className="text-base font-semibold leading-6 text-gray-900">
                <Link
                  to={action.enabled ? action.href : '#'}
                  className={action.enabled ? 'focus:outline-none' : 'pointer-events-none'}
                >
                  <span className="absolute inset-0" aria-hidden="true" />
                  {action.title}
                </Link>
              </h3>
              <p className="mt-2 text-sm text-gray-500">{action.description}</p>
              {action.title === t('Startup Support') && (
                <p className="mt-2 text-sm text-gray-500">
                  <br />
                  ⚠️ {t('Only available in Germany')}
                </p>
              )}
            </div>
            <span
              className="pointer-events-none absolute right-6 top-6 text-gray-300 group-hover:text-gray-400"
              aria-hidden="true"
            >
              <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
                <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
              </svg>
            </span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Wizards;