const CollectionDetailsCard = ({ collection, template, userLang, t }) => {
    const formatDate = (dateString) => {
      return new Date(dateString).toLocaleDateString();
    };
  
    return (
      <div className="bg-white rounded-lg shadow p-6 mb-8">
        {/* Basic Collection Info */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
          <div>
            <h3 className="text-sm font-medium text-gray-500">{t('Collection Period')}</h3>
            <p className="mt-1 text-base text-gray-900">{collection.period_value}</p>
          </div>
          <div>
            <h3 className="text-sm font-medium text-gray-500">{t('Started')}</h3>
            <p className="mt-1 text-base text-gray-900">{formatDate(collection.starts_at)}</p>
          </div>
        </div>
  
        {/* Viewers & Respondents */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 border-t border-gray-200 pt-6">
          <div>
            <h3 className="text-sm font-medium text-gray-500 mb-2">{t('Report Viewers')}</h3>
            <ul className="space-y-1">
              {collection.viewers.map((viewer) => (
                <li key={viewer.id} className="text-sm text-gray-600">
                  {viewer.user_name}
                </li>
              ))}
            </ul>
          </div>
          <div>
            <h3 className="text-sm font-medium text-gray-500 mb-2">{t('Respondents')}</h3>
            <ul className="space-y-1">
              {collection.respondents.map((respondent) => (
                <li 
                  key={respondent.id} 
                  className="text-sm flex items-center"
                >
                  <span className="text-gray-600">
                    {respondent.user_name}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    );
  };
  
  export default CollectionDetailsCard;