import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Routes, Route, Link } from 'react-router-dom';
import ReportsTable from '../components/reports/ReportsTable';
import ActiveCollections from '../components/reports/ActiveCollections';
import CreateCollection from '../components/reports/CreateCollection';
import axios from '../axios';
import Notification from '../components/Notification';
import { PlusCircleIcon } from '@heroicons/react/24/outline';

const ReportsOverview = ({ reports, activeCollections, fetchData }) => {
  const { t } = useTranslation();

  return (
    <>
      <h1 className="text-2xl font-bold mb-6">{t('Reports')}</h1>
      <div className="flex flex-col gap-8">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          {/* Completed Collections (Reports) */}
          <div className="bg-gray-200 rounded-lg shadow p-6">
            <h2 className="text-xl font-semibold mb-4">{t('Reports')}</h2>
            <ReportsTable 
              reports={reports}
              refetchReports={fetchData}
            />
          </div>

          {/* Active Collections */}
          <div className="bg-gray-200 rounded-lg shadow p-6">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-semibold pr-4">{t('Active Data Collections')}</h2>
              <Link
                to="/reports/create"
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-primary-color hover:bg-secondary-color"
              >
                <PlusCircleIcon className="h-5 w-5 mr-2" />
                {t('Create New Collection')}
              </Link>
            </div>
            <ActiveCollections 
              collections={activeCollections}
              refetchCollections={fetchData}
            />
          </div>
        </div>
      </div>
    </>
  );
};

const Reports = () => {
  const { t } = useTranslation();
  const [reports, setReports] = useState([]);
  const [activeCollections, setActiveCollections] = useState([]);
  const [notification, setNotification] = useState({ show: false, type: '', message: '' });

  const fetchData = async () => {
    try {
      const response = await axios.get('/api/v1/data_collections', {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('authToken')}` },
      });
      setReports(response.data.reports);
      setActiveCollections(response.data.active_collections);
    } catch (error) {
      setNotification({
        show: true,
        type: 'error',
        message: t('Error fetching data collections'),
      });
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="container mx-auto px-4 py-6">
      <Notification
        show={notification.show}
        setShow={(show) => setNotification((prev) => ({ ...prev, show }))}
        type={notification.type}
        message={notification.message}
      />

      <Routes>
        <Route
          index
          element={
            <ReportsOverview
              reports={reports}
              activeCollections={activeCollections}
              fetchData={fetchData}
            />
          }
        />
        <Route
          path="create"
          element={
            <CreateCollection
              onComplete={() => {
                fetchData();
              }}
            />
          }
        />
      </Routes>
    </div>
  );
};

export default Reports;