import React, { useState, useEffect, Fragment } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { Menu, Transition, Switch } from '@headlessui/react';
import TaskCard from './TaskCard';
import NewTaskCard from './NewTaskCard';
import TaskListView from './TaskListView';
import Notification from './Notification';
import axios from '../axios';
import {
  CircleStackIcon,
  PlayIcon,
  ClipboardDocumentCheckIcon,
  CheckCircleIcon,
  BarsArrowUpIcon,
  BarsArrowDownIcon,
  ArrowsUpDownIcon,
  QueueListIcon,
  ViewColumnsIcon,
  AdjustmentsHorizontalIcon
} from '@heroicons/react/24/outline';

const KanbanBoard = () => {
  const { t } = useTranslation();
  const [tasks, setTasks] = useState([]);
  const [users, setUsers] = useState([]);
  const [showArchived, setShowArchived] = useState(false);
  const [showOnlyMyTasks, setShowOnlyMyTasks] = useState(false);
  const [sortOrder, setSortOrder] = useState('desc');
  const [sortByStatus, setSortByStatus] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationProps, setNotificationProps] = useState({
    type: 'success',
    message: '',
    errors: []
  });

  // Initialize viewType from sessionStorage or default to 'list' if on mobile
  const [viewType, setViewType] = useState(() => {
    const isMobile = window.innerWidth < 1024; // lg breakpoint in Tailwind
    if (isMobile) return 'list';
    return sessionStorage.getItem('taskViewType') || 'kanban';
  });

  // Update sessionStorage when view changes
  const handleViewChange = (newView) => {
    setViewType(newView);
    sessionStorage.setItem('taskViewType', newView);
  };

  // Add resize listener for mobile detection
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1024) { // lg breakpoint
        setViewType('list');
      } else {
        // Restore previous preference when going back to desktop
        const savedView = sessionStorage.getItem('taskViewType') || 'kanban';
        setViewType(savedView);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const columns = [
    { id: 'backlog', title: t('Backlog'), icon: <CircleStackIcon className="h-5 w-5 me-2 inline" /> },
    { id: 'current', title: t('Current'), icon: <PlayIcon className="h-5 w-5 me-2 inline" /> },
    { id: 'approval', title: t('Approval'), icon: <ClipboardDocumentCheckIcon className="h-5 w-5 me-2 inline" /> },
    { id: 'done', title: t('Done'), icon: <CheckCircleIcon className="h-5 w-5 me-2 inline" /> },
  ];

  // Function to show notifications
  const showNotificationMessage = (type, message, errors = []) => {
    setNotificationProps({ type, message, errors });
    setShowNotification(true);
  };

  useEffect(() => {
    fetchTasks();
    fetchUsers();
  }, [showArchived, showOnlyMyTasks]);

  const fetchTasks = async () => {
    try {
      const response = await axios.get('/api/v1/tasks', {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('authToken')}` },
        params: { includeArchived: showArchived },
      });

      let filteredTasks = response.data;

      if (showOnlyMyTasks) {
        const currentUser = JSON.parse(sessionStorage.getItem('user'));
        filteredTasks = filteredTasks.filter(task =>
          task.assigned_to_id === currentUser.id ||
          task.approved_by_id === currentUser.id
        );
      }

      // Apply sorting
      if (sortByStatus) {
        filteredTasks = sortTasksByStatus(filteredTasks);
      } else {
        filteredTasks.sort((a, b) => {
          const dateA = new Date(a.created_at);
          const dateB = new Date(b.created_at);
          return sortOrder === 'asc' ? dateA - dateB : dateB - dateA;
        });
      }

      setTasks(filteredTasks);
    } catch (error) {
      showNotificationMessage('error', t('Error fetching tasks'), [error.message]);
    }
  };

  const fetchUsers = async () => {
    try {
      const response = await axios.get('/api/v1/live_data/user_data', {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('authToken')}` }
      });
      setUsers(response.data.entity.users);
    } catch (error) {
      showNotificationMessage('error', t('Error fetching users'), [error.message]);
    }
  };

  const onDragEnd = async (result) => {
    const { destination, source, draggableId } = result;

    if (!destination || (destination.droppableId === source.droppableId && destination.index === source.index)) {
      return;
    }

    const task = tasks.find(t => t.id.toString() === draggableId);
    const oldStatus = task.status;
    const newStatus = destination.droppableId;

    const currentUser = JSON.parse(sessionStorage.getItem('user'));
    if (
      (newStatus === 'done' && task.approved_by_id !== currentUser.id) ||
      (newStatus !== 'done' && task.assigned_to_id !== currentUser.id)
    ) {
      showNotificationMessage('error', t("You don't have permission to move this task."));
      return;
    }

    const newTasks = tasks.map(t => {
      if (t.id === task.id) {
        return { ...t, status: newStatus };
      }
      return t;
    });
    setTasks(newTasks);

    try {
      await axios.put(`/api/v1/tasks/${task.id}`,
        { task: { status: newStatus } },
        { headers: { Authorization: `Bearer ${sessionStorage.getItem('authToken')}` } }
      );
    } catch (error) {
      setTasks(tasks); // Revert the change
      showNotificationMessage('error', t('Failed to update task'), [error.message]);
    }
  };

  const toggleSortOrder = () => {
    // Disable sorting by status when applying creation date sorting
    setSortByStatus(false);
    setSortOrder(current => {
      const newOrder = current === 'asc' ? 'desc' : 'asc';
      const sortedTasks = [...tasks].sort((a, b) => {
        const dateA = new Date(a.created_at);
        const dateB = new Date(b.created_at);
        return newOrder === 'asc' ? dateA - dateB : dateB - dateA;
      });
      setTasks(sortedTasks);
      return newOrder;
    });
  };

  const toggleSortByStatus = () => {
    // Disable regular sorting when sorting by status
    setSortByStatus(current => {
      if (!current) {
        setSortOrder('desc'); // Reset sort order to default
        setTasks(sortTasksByStatus(tasks));
      }
      return !current;
    });
  };

  const sortTasksByStatus = (tasksToSort) => {
    return [...tasksToSort].sort((a, b) => {
      const priorities = { approval: 0, current: 1, backlog: 2, done: 3 };
      return priorities[a.status] - priorities[b.status];
    });
  };

  return (
    <>
      {/* Add Notification component at the top level */}
      <Notification
        show={showNotification}
        setShow={setShowNotification}
        type={notificationProps.type}
        message={notificationProps.message}
        errors={notificationProps.errors}
      />

      {/* Header Controls */}
      <div className="flex justify-between items-center p-4">
        <div className="flex items-center gap-2">
          <h1 className="text-xl font-bold">{t('Tasks')}</h1>
        </div>

        <div className="flex items-center gap-2">
          {/* Hide view toggle on mobile */}
          <button
            onClick={() => handleViewChange(viewType === 'kanban' ? 'list' : 'kanban')}
            className="hidden lg:inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-500 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-secondary-color"
          >
            {viewType === 'kanban' ? (
              <QueueListIcon className="h-5 w-5" />
            ) : (
              <ViewColumnsIcon className="h-5 w-5" />
            )}
          </button>

          <Menu as="div" className="relative inline-block text-left">
            <div className="flex items-center">
              <Menu.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-500 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-secondary-color">
                <AdjustmentsHorizontalIcon className="h-5 w-5" />
                {(showOnlyMyTasks || showArchived) && (
                  <span className="absolute -top-1 -right-1 flex h-4 w-4 items-center justify-center rounded-full bg-secondary-color text-xs text-white">
                    {[showOnlyMyTasks, showArchived].filter(Boolean).length}
                  </span>
                )}
              </Menu.Button>
            </div>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 z-10 mt-2 w-80 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                {/* Adjusted width to make the dropdown wider */}
                <div className="py-2 px-4">

                  {/* Sort Section */}
                  <div className="mb-3">
                    <span className="text-xs font-semibold text-gray-500 uppercase block mb-2">{t('Sort')}</span>
                    <div className="space-y-2">
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            onClick={toggleSortOrder}
                            className={`flex items-center w-full px-2 py-2 text-sm rounded-md ${active ? 'bg-gray-100' : ''}`}
                          >
                            {sortOrder === 'asc' ? (
                              <BarsArrowUpIcon className="h-6 w-6 mr-3 text-gray-500" />
                            ) : (
                              <BarsArrowDownIcon className="h-6 w-6 mr-3 text-gray-500" />
                            )}
                            <span className="text-left">{t('Sort by Creation Date')}</span>
                          </button>
                        )}
                      </Menu.Item>
                      {viewType === 'list' && (
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              onClick={toggleSortByStatus}
                              className={`flex items-center w-full px-2 py-2 text-sm rounded-md ${active ? 'bg-gray-100' : ''}`}
                            >
                              <ArrowsUpDownIcon className="h-6 w-6 mr-3 text-gray-500" />
                              <span className="text-left">{t('Sort by Status')}</span>
                            </button>
                          )}
                        </Menu.Item>
                      )}
                    </div>
                  </div>

                  {/* Filter Section */}
                  <div className="border-t border-gray-200 my-2" />
                  <div>
                    <span className="text-xs font-semibold text-gray-500 uppercase block mb-2">{t('Filters')}</span>
                    <div className="space-y-2">
                      <Menu.Item>
                        {({ active }) => (
                          <div className={`flex items-center w-full px-2 py-2 ${active ? 'bg-gray-100' : ''}`}>
                            <Switch
                              checked={showOnlyMyTasks}
                              onChange={setShowOnlyMyTasks}
                              className={`${showOnlyMyTasks ? 'bg-secondary-color' : 'bg-gray-200'
                                } relative inline-flex h-6 w-11 items-center rounded-full transition-colors duration-200 ease-in-out`}
                            >
                              <span className="sr-only">{t('Show only my tasks')}</span>
                              <span
                                className={`${showOnlyMyTasks ? 'translate-x-6' : 'translate-x-1'
                                  } inline-block h-4 w-4 transform rounded-full bg-white transition duration-200 ease-in-out`}
                              />
                            </Switch>
                            <span className="ml-3 text-sm text-left">{t('Show only my tasks')}</span>
                          </div>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <div className={`flex items-center w-full px-2 py-2 ${active ? 'bg-gray-100' : ''}`}>
                            <Switch
                              checked={!showArchived}
                              onChange={(checked) => setShowArchived(!checked)}
                              className={`${showArchived ? 'bg-red-700' : 'bg-gray-200'
                                } relative inline-flex h-6 w-11 items-center rounded-full transition-colors duration-200 ease-in-out`}
                            >
                              <span className="sr-only">{t('Show archived tasks')}</span>
                              <span
                                className={`${showArchived ? 'translate-x-6' : 'translate-x-1'
                                  } inline-block h-4 w-4 transform rounded-full bg-white transition duration-200 ease-in-out`}
                              />
                            </Switch>
                            <span className="ml-3 text-sm text-left">{t('Show archived tasks')}</span>
                          </div>
                        )}
                      </Menu.Item>
                    </div>
                  </div>
                </div>
              </Menu.Items>
            </Transition>


          </Menu>
        </div>
      </div>

      <hr className="h-px my-2 bg-gray-200 border-0 dark:bg-gray-700" />
      {viewType === 'kanban' ? (
        <DragDropContext onDragEnd={onDragEnd}>
          <div className="flex space-x-4 p-4">
            {columns.map(column => (
              <div key={column.id} className="flex-1">
                <h2 className="text-lg font-semibold mb-2">
                  {column.icon}
                  {column.title}
                </h2>
                <Droppable droppableId={column.id}>
                  {(provided) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      className="bg-gray-200 p-4 rounded-lg min-h-[200px]"
                    >
                      {tasks
                        .filter(task => task.status === column.id)
                        .map((task, index) => (
                          <Draggable key={task.id} draggableId={task.id.toString()} index={index}>
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <TaskCard
                                  task={task}
                                  users={users}
                                  onUpdate={fetchTasks}
                                  showNotification={showNotificationMessage}
                                />
                              </div>
                            )}
                          </Draggable>
                        ))}
                      {provided.placeholder}
                      {column.id === 'backlog' && (
                        <NewTaskCard
                          onAdd={fetchTasks}
                          showNotification={showNotificationMessage}
                        />
                      )}
                    </div>
                  )}
                </Droppable>
              </div>
            ))}
          </div>
        </DragDropContext>
      ) : (
        <>
          <TaskListView
            tasks={sortByStatus ? sortTasksByStatus(tasks) : tasks}
            users={users}
            onUpdate={() => {
              fetchTasks();
            }}
          />
        </>
      )}
    </>
  );
}

export default KanbanBoard;