import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from '../../axios';
import LiveModeToggle from '../../components/wizard_components/LiveModeToggle';
import { Dialog } from '@headlessui/react';
import BooleanQuestion from './question_components/BooleanQuestion';
import RatingQuestion from './question_components/RatingQuestion';
import TextQuestion from './question_components/TextQuestion';
import CheckboxQuestion from './question_components/CheckBoxQuestion';
import RadioButtonQuestion from './question_components/RadioButtonQuestion';
import RatingBlockQuestion from './question_components/RatingBlockQuestion';
import MultipleNumbersQuestion from './question_components/MultipleNumbersQuestion';
import PercentageQuestion from './question_components/PercentageQuestion';
import { getTranslatedValue } from '../../utils/templateTranslations';
import Notification from '../../components/Notification';
import { CheckCircleIcon } from '@heroicons/react/24/solid';

const DataCollectionResponse = () => {
  const { collectionId, accessToken } = useParams();
  const { t } = useTranslation();
  const [collection, setCollection] = useState(null);
  const [template, setTemplate] = useState(null);
  const [userData, setUserData] = useState(null);
  const [responses, setResponses] = useState({});
  const [notes, setNotes] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [liveMode, setLiveMode] = useState(false);
  const [showSubmitModal, setShowSubmitModal] = useState(false);
  const [currentPartId, setCurrentPartId] = useState(null);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [assignedParts, setAssignedParts] = useState({});
  const [partResponses, setPartResponses] = useState({});
  const [partNotes, setPartNotes] = useState({});
  const [partHasSubmitted, setPartHasSubmitted] = useState({});
  const [partSubmittedBy, setPartSubmittedBy] = useState({});
  const [partStatuses, setPartStatuses] = useState({});
  const [allUsers, setAllUsers] = useState([]);
  const [questionErrors, setQuestionErrors] = useState({});
  const [partQuestionErrors, setPartQuestionErrors] = useState({});
  const [isIndividualCollection, setIsIndividualCollection] = useState(false);
  const [notification, setNotification] = useState({
    show: false,
    type: '', // 'success' or 'error'
    message: '',
    errors: [],
  });

  const showNotification = (type, message, errors = []) => {
    setNotification({
      show: true,
      type,
      message,
      errors,
    });

    // Automatically hide after 5 seconds
    setTimeout(() => {
      setNotification((prev) => ({ ...prev, show: false }));
    }, 5000);
  };

  // Fetch all necessary data
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch user data and collection response
        const [userResponse, collectionResponse] = await Promise.all([
          axios.get('/api/v1/live_data/user_data', {
            headers: { Authorization: `Bearer ${sessionStorage.getItem('authToken')}` }
          }),
          axios.get(`/api/v1/data_collections/respond/${accessToken}`, {
            headers: { Authorization: `Bearer ${sessionStorage.getItem('authToken')}` }
          })
        ]);

        setUserData(userResponse.data);
        setCollection({
          ...collectionResponse.data.collection,
        });
        setAssignedParts(collectionResponse.data.assigned_parts || {});
        setPartStatuses(collectionResponse.data.part_statuses || {});
        setAllUsers(collectionResponse.data.all_users || {});

        console.log("Collection Response:", collectionResponse.data);
        console.log("Assigned Parts:", collectionResponse.data.assigned_parts);

        // Use a local variable for collection type
        const collectionType = collectionResponse.data.collection.collection_type;
        setIsIndividualCollection(collectionType === 'individual');

        // Fetch the template
        if (collectionResponse.data.collection?.template_id) {
          const templateResponse = await axios.get(
            `/api/v1/data_collections/templates/${collectionResponse.data.collection.template_id}`,
            {
              headers: { Authorization: `Bearer ${sessionStorage.getItem('authToken')}` }
            }
          );
          setTemplate(templateResponse.data);
        }

        if (collectionType === 'individual') {
          const existingResponseData = collectionResponse.data.existing_responses?.response_data || {};
          const existingNotesData = collectionResponse.data.existing_responses?.notes_data || {};
          const draftResponseData = collectionResponse.data.draft_responses?.response_data || {};
          const draftNotesData = collectionResponse.data.draft_responses?.notes_data || {};

          if (collectionResponse.data.has_submitted) {
            setResponses(existingResponseData);
            setNotes(existingNotesData);
            setHasSubmitted(true);
          } else {
            setResponses(draftResponseData);
            setNotes(draftNotesData);
            setHasSubmitted(false);
          }
        } else {
          // Initialize responses and notes for parts the user has access to
          const responses = {};
          const notes = {};
          const hasSubmitted = {};
          const submittedBy = {};
          const assignedPartIds = Object.keys(collectionResponse.data.assigned_parts || {});

          assignedPartIds.forEach((partId) => {
            const existingResponseData = collectionResponse.data.existing_responses?.[partId]?.response_data || {};
            const existingNotesData = collectionResponse.data.existing_responses?.[partId]?.notes_data || {};
            const draftResponseData = collectionResponse.data.draft_responses?.[partId]?.response_data || {};
            const draftNotesData = collectionResponse.data.draft_responses?.[partId]?.notes_data || {};

            if (collectionResponse.data.part_statuses[partId]?.status === 'responded') {
              responses[partId] = existingResponseData;
              notes[partId] = existingNotesData;
              hasSubmitted[partId] = true;
              submittedBy[partId] = collectionResponse.data.part_statuses[partId]?.submitted_by_name || '';
            } else {
              responses[partId] = draftResponseData;
              notes[partId] = draftNotesData;
              hasSubmitted[partId] = false;
            }
          });
          setPartResponses(responses);
          setPartNotes(notes);
          setPartHasSubmitted(hasSubmitted);
          setPartSubmittedBy(submittedBy);
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [accessToken]);

  // Function to check if user has access to a part
  const hasAccessToPart = (partId) => {
    if (collection.is_anonymous) return true;
    // assignedParts is an object with part IDs as keys in both collection types
    return assignedParts.hasOwnProperty(partId);
  };

  // Get all parts the user has access to
  const getAccessibleParts = () => {
    if (!template?.template_data?.parts) return [];

    const accessibleParts = template.template_data.parts.filter(part => {
      return hasAccessToPart(part.id);
    });

    return accessibleParts;
  };

  // Modified validation function for individual collections
  const validateIndividualResponses = () => {
    // Only get parts that are actually assigned to the user
    const accessibleParts = template.template_data.parts.filter(part => 
      assignedParts.hasOwnProperty(part.id)
    );
  
    const errors = {};
    let isValid = true;
  
    accessibleParts.forEach(part => {
      part.questions.forEach(question => {
        const questionId = question.id;
        const answer = responses[questionId];
  
        if (question.type === 'rating_block') {
          const items = question.items || [];
          if (!answer || typeof answer !== 'object') {
            errors[questionId] = t('Please rate all items.');
            isValid = false;
          } else {
            items.forEach(item => {
              if (answer[item.id] === undefined || answer[item.id] === null || answer[item.id] === '') {
                errors[questionId] = t('Please rate all items.');
                isValid = false;
              }
            });
          }
        } else {
          if (answer === undefined || answer === null || answer === '' ||
            (Array.isArray(answer) && answer.length === 0)) {
            errors[questionId] = t('Please answer this question/prompt.');
            isValid = false;
          }
        }
      });
    });
  
    setQuestionErrors(errors);
    return isValid;
  };

  const validateCollaborativePart = (partId) => {
    const part = template.template_data.parts.find((p) => p.id === partId);
    if (!part) return true; // If part not found, consider it valid (or handle as needed)

    const partResponsesData = partResponses[partId] || {};
    const partErrors = {};
    let isValid = true;

    part.questions.forEach((question) => {
      const questionId = question.id;
      const answer = partResponsesData[questionId];

      if (question.type === 'rating_block') {
        const items = question.items || [];
        if (!answer || typeof answer !== 'object') {
          partErrors[questionId] = t('Please rate all items.');
          isValid = false;
        } else {
          items.forEach((item) => {
            if (
              answer[item.id] === undefined ||
              answer[item.id] === null ||
              answer[item.id] === ''
            ) {
              partErrors[questionId] = t('Please rate all items.');
              isValid = false;
            }
          });
        }
      } else {
        if (
          answer === undefined ||
          answer === null ||
          answer === '' ||
          (Array.isArray(answer) && answer.length === 0)
        ) {
          partErrors[questionId] = t('Please answer this question/prompt.');
          isValid = false;
        }
      }
    });

    if (!isValid) {
      setPartQuestionErrors((prev) => ({
        ...prev,
        [partId]: partErrors,
      }));
    }

    return isValid;
  };

  const handleResponseChange = (partId, questionId, value) => {
    if (isIndividualCollection) {
      setResponses((prev) => ({
        ...prev,
        [questionId]: value,
      }));

      // Clear error if it exists
      if (questionErrors[questionId]) {
        setQuestionErrors((prev) => {
          const newErrors = { ...prev };
          delete newErrors[questionId];
          return newErrors;
        });
      }
    } else {
      setPartResponses((prev) => ({
        ...prev,
        [partId]: {
          ...prev[partId],
          [questionId]: value,
        },
      }));

      // Clear error if it exists
      if (partQuestionErrors[partId]?.[questionId]) {
        setPartQuestionErrors((prev) => {
          const newPartErrors = { ...prev };
          const partErrors = { ...newPartErrors[partId] };
          delete partErrors[questionId];
          newPartErrors[partId] = partErrors;
          return newPartErrors;
        });
      }
    }

    if (liveMode) {
      saveAsDraft(isIndividualCollection ? null : partId);
    }
  };


  const handleNotesChange = (partId, questionId, value) => {
    if (isIndividualCollection) {
      setNotes(prev => ({
        ...prev,
        [questionId]: value,
      }));
    } else {
      setPartNotes(prev => ({
        ...prev,
        [partId]: {
          ...prev[partId],
          [questionId]: value
        }
      }));
    }

    if (liveMode) {
      saveAsDraft(isIndividualCollection ? null : partId);
    }
  };

  const saveAsDraft = async (partId = null) => {
    try {
      const url = `/api/v1/data_collections/respond/${accessToken}/draft`;
      const data = isIndividualCollection
        ? {
          response_data: responses,
          notes_data: notes,
        }
        : {
          part_id: partId,
          response_data: partResponses[partId] || {},
          notes_data: partNotes[partId] || {},
        };

      await axios.post(url, data, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('authToken')}` },
      });

      showNotification('success', t('Draft saved successfully'));
    } catch (error) {
      console.error('Error saving draft:', error);
      showNotification('error', t('Failed to save draft'), [error.message]);
    }
  };

  const handleSubmit = async (partId = null) => {
    // Clear previous errors
    if (isIndividualCollection) {
      if (!validateIndividualResponses()) {
        showNotification('error', t('Please answer all questions in your assigned parts.'));
        return;
      }
    } else {
      // Existing collaborative validation logic
      setPartQuestionErrors((prev) => ({ ...prev, [partId]: {} }));
      if (!validateCollaborativePart(partId)) {
        showNotification('error', t('Please answer all questions in this part.'));
        return;
      }
    }

    try {
      const url = `/api/v1/data_collections/respond/${accessToken}/submit`;
      const data = isIndividualCollection
        ? {
          response_data: responses,
          notes_data: notes,
        }
        : {
          part_id: partId,
          response_data: partResponses[partId] || {},
          notes_data: partNotes[partId] || {},
        };

      await axios.post(url, data, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('authToken')}` },
      });

      if (isIndividualCollection) {
        setHasSubmitted(true);
      } else {
        setPartHasSubmitted((prev) => ({
          ...prev,
          [partId]: true,
        }));
      }

      showNotification('success', t('Submission successful'));
    } catch (error) {
      console.error('Error submitting responses:', error);
      const errorMessages = error.response?.data?.details || [error.message];
      showNotification('error', t('Failed to submit'), errorMessages);
    }
  };

  if (loading) return <div>{t('Loading...')}</div>;
  if (error) return <div>{t('Error loading data collection')}</div>;
  if (!template || !collection) return <div>{t('No data found')}</div>;

  const userLang = userData?.language
    ? userData.language.slice(0, 2)
    : 'en';

  const isSubmitter = collection.user_roles?.is_submitter ?? false;

  const renderQuestion = (question, partId, readOnly) => {
    const isReadOnly = isIndividualCollection
      ? hasSubmitted
      : readOnly || !assignedParts[partId]?.canEdit;
    const questionId = question.id;

    // Get error message
    const errorMessage = isIndividualCollection
      ? questionErrors[questionId]
      : partQuestionErrors[partId]?.[questionId];

    // Render the appropriate question component based on question type
    let questionComponent;

    switch (question.type) {
      case 'boolean':
        questionComponent = (
          <BooleanQuestion
            key={questionId}
            question={question}
            value={
              isIndividualCollection
                ? responses[questionId]
                : partResponses[partId]?.[questionId]
            }
            onChange={(value) => handleResponseChange(partId, questionId, value)}
            notes={
              isIndividualCollection
                ? notes[questionId]
                : partNotes[partId]?.[questionId]
            }
            onNotesChange={(value) =>
              handleNotesChange(partId, questionId, value)
            }
            showNotes={question.notes}
            notesLabel={getTranslatedValue(question.notes_label, userLang)}
            language={userLang}
            readOnly={isReadOnly}
          />
        );
        break;
      case 'rating':
        questionComponent = (
          <RatingQuestion
            key={questionId}
            question={question}
            value={
              isIndividualCollection
                ? responses[questionId]
                : partResponses[partId]?.[questionId]
            }
            onChange={(value) => handleResponseChange(partId, questionId, value)}
            notes={
              isIndividualCollection
                ? notes[questionId]
                : partNotes[partId]?.[questionId]
            }
            onNotesChange={(value) =>
              handleNotesChange(partId, questionId, value)
            }
            showNotes={question.notes}
            notesLabel={getTranslatedValue(question.notes_label, userLang)}
            language={userLang}
            readOnly={isReadOnly}
          />
        );
        break;
      case 'rating_block':
        questionComponent = (
          <RatingBlockQuestion
            key={questionId}
            question={question}
            value={
              isIndividualCollection
                ? responses[questionId] || {}
                : partResponses[partId]?.[questionId] || {}
            }
            onChange={(value) => handleResponseChange(partId, questionId, value)}
            notes={
              isIndividualCollection
                ? notes[questionId]
                : partNotes[partId]?.[questionId]
            }
            onNotesChange={(value) =>
              handleNotesChange(partId, questionId, value)
            }
            showNotes={question.notes}
            notesLabel={getTranslatedValue(question.notes_label, userLang)}
            language={userLang}
            readOnly={isReadOnly}
          />
        );
        break;
      case 'text':
        questionComponent = (
          <TextQuestion
            key={questionId}
            question={question}
            value={
              isIndividualCollection
                ? responses[questionId]
                : partResponses[partId]?.[questionId]
            }
            onChange={(value) => handleResponseChange(partId, questionId, value)}
            notes={
              isIndividualCollection
                ? notes[questionId]
                : partNotes[partId]?.[questionId]
            }
            onNotesChange={(value) =>
              handleNotesChange(partId, questionId, value)
            }
            showNotes={question.notes}
            notesLabel={getTranslatedValue(question.notes_label, userLang)}
            language={userLang}
            readOnly={isReadOnly}
          />
        );
        break;
      case 'checkbox':
        questionComponent = (
          <CheckboxQuestion
            key={questionId}
            question={question}
            value={
              isIndividualCollection
                ? responses[questionId] || []
                : partResponses[partId]?.[questionId] || []
            }
            onChange={(value) => handleResponseChange(partId, questionId, value)}
            notes={
              isIndividualCollection
                ? notes[questionId]
                : partNotes[partId]?.[questionId]
            }
            onNotesChange={(value) =>
              handleNotesChange(partId, questionId, value)
            }
            showNotes={question.notes}
            notesLabel={getTranslatedValue(question.notes_label, userLang)}
            language={userLang}
            readOnly={isReadOnly}
          />
        );
        break;
      case 'multiple_numbers':
        questionComponent = (
          <MultipleNumbersQuestion
            key={questionId}
            question={question}
            value={
              isIndividualCollection
                ? responses[questionId]
                : partResponses[partId]?.[questionId]
            }
            onChange={(value) => handleResponseChange(partId, questionId, value)}
            notes={
              isIndividualCollection
                ? notes[questionId]
                : partNotes[partId]?.[questionId]
            }
            onNotesChange={(value) =>
              handleNotesChange(partId, questionId, value)
            }
            showNotes={question.notes}
            notesLabel={getTranslatedValue(question.notes_label, userLang)}
            language={userLang}
            readOnly={isReadOnly}
          />
        );
        break;
      case 'percentage':
        questionComponent = (
          <PercentageQuestion
            key={questionId}
            question={question}
            value={
              isIndividualCollection
                ? responses[questionId]
                : partResponses[partId]?.[questionId]
            }
            onChange={(value) => handleResponseChange(partId, questionId, value)}
            notes={
              isIndividualCollection
                ? notes[questionId]
                : partNotes[partId]?.[questionId]
            }
            onNotesChange={(value) =>
              handleNotesChange(partId, questionId, value)
            }
            showNotes={question.notes}
            notesLabel={getTranslatedValue(question.notes_label, userLang)}
            language={userLang}
            readOnly={isReadOnly}
          />
        );
        break;
      case 'radio':
        questionComponent = (
          <RadioButtonQuestion
            key={questionId}
            question={question}
            value={
              isIndividualCollection
                ? responses[questionId]
                : partResponses[partId]?.[questionId]
            }
            onChange={(value) => handleResponseChange(partId, questionId, value)}
            notes={
              isIndividualCollection
                ? notes[questionId]
                : partNotes[partId]?.[questionId]
            }
            onNotesChange={(value) =>
              handleNotesChange(partId, questionId, value)
            }
            showNotes={question.notes}
            notesLabel={getTranslatedValue(question.notes_label, userLang)}
            language={userLang}
            readOnly={isReadOnly}
          />
        );
        break;
      default:
        questionComponent = null;
    }

    return (
      <div key={questionId} className="mb-6">
        {questionComponent}
        {errorMessage && (
          <p className="text-red-600 text-sm mt-1">
            {errorMessage}
          </p>
        )}
      </div>
    );
  };

  const getUserNameById = (userId) => {
    const user = allUsers.find(u => u.id === userId);
    return user ? `${user.first_name} ${user.last_name}` : '';
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">

      {/* Notification Component */}
      <Notification
        show={notification.show}
        setShow={(show) => setNotification((prev) => ({ ...prev, show }))}
        type={notification.type}
        message={notification.message}
        errors={notification.errors}
      />
      <LiveModeToggle onToggle={setLiveMode} />

      <h1 className="text-3xl font-bold text-gray-900">
        {getTranslatedValue(collection.data, userLang)}
      </h1>

      {getAccessibleParts().map((part) => {
  const partId = part.id;
  const permissions = assignedParts[partId];
  let canEdit, canSubmit;
  // Determine if this part has been submitted
  const isSubmitted = isIndividualCollection 
    ? hasSubmitted 
    : partHasSubmitted[partId];

  if (isIndividualCollection) {
    canEdit = permissions?.canEdit ?? false;
    canSubmit = permissions?.canSubmit ?? false;
  } else {
    canEdit = permissions?.canEdit || false;
    canSubmit = permissions?.canSubmit || false;
  }

  const submittedBy = partSubmittedBy[partId] || `${userData?.first_name} ${userData?.last_name}`;
  const containerBgColor = isSubmitted ? 'bg-gray-300' : 'bg-gray-50';

  return (
    <div
      key={partId}
      className={`mt-8 p-6 rounded-lg ${containerBgColor}`}
    >
      {isSubmitted && (
        <div className="mt-2 p-2 text-sm bg-green-100 text-green-800 mb-4 font-semibold rounded">
          <CheckCircleIcon className="h-5 w-5 inline-block mr-2" />
          {t('This part has already been submitted by')} {submittedBy}. {t('You can view the responses below.')}
        </div>
      )}
            <h2 className="text-xl mb-0 pb-0 font-semibold text-gray-900">
              {getTranslatedValue(part.name, userLang)}
            </h2>

            {part.description && (
              <p className="mt-0 text-gray-900">
                {getTranslatedValue(part.description, userLang)}
              </p>
            )}

            {/* Display assignments only for collaborative collections */}
            {!isIndividualCollection && (
              <div className="text-sm text-gray-600 mt-1 border-b pb-4 border-gray-200">
                {t('Editors')}:{' '}
                {collection.part_assignments[partId]?.editors
                  .map((id) => getUserNameById(id))
                  .filter((name) => name !== '')
                  .join(', ')}
                <br />
                {t('Submitters')}:{' '}
                {collection.part_assignments[partId]?.submitters
                  .map((id) => getUserNameById(id))
                  .filter((name) => name !== '')
                  .join(', ')}
              </div>
            )}

            <div className="mt-4 space-y-6">
              {part.questions.map((question) =>
                renderQuestion(
                  question,
                  partId,
                  isSubmitted || (!isIndividualCollection && !canEdit)
                )
              )}
            </div>

            {/* Per-part Save and Submit Buttons for Collaborative Collections */}
            {!isIndividualCollection && (
              <div className="mt-4 flex justify-end space-x-4">
                <button
                  type="button"
                  onClick={() => saveAsDraft(partId)}
                  disabled={!canEdit || isSubmitted}
                  className={`px-4 py-2 text-sm font-medium rounded-md ${canEdit && !isSubmitted
                    ? 'text-gray-700 bg-white border border-gray-300 hover:bg-gray-50'
                    : 'hidden'
                    }`}
                >
                  {t('Save as Draft')}
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setCurrentPartId(partId);
                    setShowSubmitModal(true);
                  }}
                  disabled={!canSubmit || isSubmitted}
                  className={`px-4 py-2 text-sm font-medium text-white rounded-md ${canSubmit && !isSubmitted
                    ? 'bg-primary-color hover:bg-primary-color/90'
                    : 'hidden'
                    }`}
                  title={!canSubmit ? t('You are not authorized to submit this part') : ''}
                >
                  {t('Submit')}
                </button>
              </div>
            )}
          </div>
        );
      })}

      {/* Save and Submit Buttons for Individual Collections */}
      {isIndividualCollection && !hasSubmitted && (
        <div className="mt-8 flex justify-end space-x-4">
          <button
            type="button"
            onClick={() => saveAsDraft()}
            className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
          >
            {t('Save as Draft')}
          </button>
          <button
            type="button"
            onClick={() => setShowSubmitModal(true)}
            className="px-4 py-2 text-sm font-medium text-white bg-primary-color rounded-md hover:bg-primary-color/90"
          >
            {t('Submit')}
          </button>
        </div>
      )}

      {/* Submit Confirmation Modal */}
      <Dialog
        open={showSubmitModal}
        onClose={() => {
          setShowSubmitModal(false);
          setCurrentPartId(null);
        }}
        className="relative z-50"
      >
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
        <div className="fixed inset-0 flex items-center justify-center p-4">
          <Dialog.Panel className="mx-auto max-w-sm rounded bg-white p-6">
            <Dialog.Title className="text-lg font-medium text-gray-900">
              {t('Confirm Submission')}
            </Dialog.Title>
            <Dialog.Description className="mt-2 text-sm text-gray-500">
              {t('Are you sure you want to submit? Once submitted, the data cannot be changed.')}
            </Dialog.Description>

            <div className="mt-4 flex justify-end space-x-4">
              <button
                type="button"
                onClick={() => setShowSubmitModal(false)}
                className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
              >
                {t('Cancel')}
              </button>
              <button
                type="button"
                onClick={() => {
                  handleSubmit(currentPartId);
                  setShowSubmitModal(false);
                  setCurrentPartId(null);
                }}
                className="px-4 py-2 text-sm font-medium text-white bg-primary-color rounded-md hover:bg-primary-color/90"
              >
                {t('Submit')}
              </button>
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>
    </div>
  );
};

export default DataCollectionResponse;