import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import axios from '../../axios';
import RadarChart from './visualizations/RadarChart';
import BarChart from './visualizations/BarChart';
import BoxPlotChart from './visualizations/BoxPlotChart';
import BarChartComparison from './visualizations/BarChartComparison';
import CollectionDetailsCard from './CollectionDetailsCard';
import TrafficLightTable from './visualizations/TrafficLightTable';
import TrafficLightCard from './visualizations/TrafficLightCard';
import StockColumnChart from './visualizations/StockColumnChart';
import GroupedColumnChart from './visualizations/GroupedColumnChart';
import Column100Chart from './visualizations/Column100Chart';
import ColorTextChart from './visualizations/ColorTextChart';
import ComparisonSelector from './ComparisonSelector';
import { getTranslatedValue } from '../../utils/templateTranslations';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';

// Collapsible Section Component
const CollapsibleSection = ({ title, children, defaultOpen = false, isFirst, isLast }) => {
    const [isOpen, setIsOpen] = useState(defaultOpen);

    return (
        <div className={`border-x border-gray-200 
        ${isFirst ? 'border-t rounded-t-lg' : ''} 
        ${isLast ? 'border-b rounded-b-lg' : 'border-b'}`}
        >
            <button
                onClick={() => setIsOpen(!isOpen)}
                className={`w-full flex justify-between items-center mb-0 px-4 py-3 bg-white hover:bg-gray-50
            ${isFirst ? 'rounded-t-lg' : ''}`}
            >
                <h2 className="text-xl font-bold mb-0 pb-0 text-gray-900">{title}</h2>
                {isOpen ? (
                    <ChevronUpIcon className="h-5 w-5 text-gray-500" />
                ) : (
                    <ChevronDownIcon className="h-5 w-5 text-gray-500" />
                )}
            </button>
            {isOpen && (
                <div className="p-4">
                    {children}
                </div>
            )}
        </div>
    );
};

const DataCollectionReport = () => {
    const { accessToken } = useParams();
    const { t } = useTranslation();
    const [collection, setCollection] = useState(null);
    const [template, setTemplate] = useState(null);
    const [userData, setUserData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [comparableCollections, setComparableCollections] = useState([]);
    const [selectedComparison, setSelectedComparison] = useState([]);
    const [comparisonData, setComparisonData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const userResponse = await axios.get('/api/v1/live_data/user_data', {
                    headers: { Authorization: `Bearer ${sessionStorage.getItem('authToken')}` }
                });
                setUserData(userResponse.data);

                const collectionResponse = await axios.get(`/api/v1/data_collections/report/${accessToken}`, {
                    headers: { Authorization: `Bearer ${sessionStorage.getItem('authToken')}` },
                });

                setCollection(collectionResponse.data.collection);
                setTemplate(collectionResponse.data.template);
                setComparableCollections(collectionResponse.data.comparable_collections || []);

                // Log out to see the data structure
            } catch (err) {
                console.error('Error during data fetch:', err);
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [accessToken]);

    const handleComparisonSelect = async (comparisonAccessTokens) => {
        if (!comparisonAccessTokens || comparisonAccessTokens.length === 0) {
            setSelectedComparison([]);
            setComparisonData([]);
            return;
        }

        try {
            const comparisonResults = [];
            for (const token of comparisonAccessTokens) {
                const response = await axios.get(`/api/v1/data_collections/report/${accessToken}`, {
                    headers: { Authorization: `Bearer ${sessionStorage.getItem('authToken')}` },
                    params: { compare_with: token }
                });
                comparisonResults.push(response.data.comparison);

                // Log each comparison as we fetch it
                console.log(`Fetched comparison for token ${token}:`, response.data.comparison);
            }

            setSelectedComparison(comparisonAccessTokens);
            setComparisonData(comparisonResults);
            // Log the final array of comparisons
            console.log("All comparisonData:", comparisonResults);
        } catch (err) {
            console.error('Error fetching comparison data:', err);
        }
    };

    if (loading) return <div className="text-center p-4">{t('Loading...')}</div>;
    if (error) return <div className="text-center p-4 text-red-600">{t('Error loading report')}</div>;
    if (!collection || !template) return <div className="text-center p-4">{t('No data found')}</div>;

    const userLang = userData?.language?.slice(0, 2) || 'en';

    // Get unique comparison groups and their representative questions
    const getComparisonGroupQuestions = () => {
        const groups = new Set();
        const questions = [];

        template.parts.forEach(part => {
            part.questions.forEach(question => {
                if (question.comparison_group && !groups.has(question.comparison_group)) {
                    groups.add(question.comparison_group);
                    questions.push(question);
                }
            });
        });

        return questions;
    };

    // Group questions by part for departmental analysis
    const partAnalyses = template.parts.map(part => {
        const questions = part.questions.filter(q => {
            console.log("Question:", q.id, "Analysis types:", q.analysis_types);
            return q.analysis_types?.some(type => {
                const shouldInclude = type.includes('bar')
                    || type === 'internal_traffic_light'
                    || type === 'stock_over_time_as_multiplecolumn_chart'
                    || type === 'comparison_over_time_as_multiplebar_chart'
                    || type === 'comparison_over_time_as_column_100';
                if (shouldInclude) {
                }
                return shouldInclude;
            });
        });
        return {
            part,
            questions
        };
    });

    const comparisonGroupQuestions = getComparisonGroupQuestions();

    return (
        <div className="w-full mx-auto px-4 py-8">
            <h1 className="text-3xl font-bold mb-6">
                {getTranslatedValue(template.name, userLang)}
            </h1>

            <CollectionDetailsCard
                collection={collection}
                template={template}
                userLang={userLang}
                t={t}
            />

            <ComparisonSelector
                comparableCollections={comparableCollections}
                onComparisonSelect={handleComparisonSelect}
                selectedComparisons={selectedComparison}
                currentPeriodValue={collection.period_value}
                currentPeriodType={collection.period_type}
                t={t}
            />

            {/* Cross-departmental Analysis Section */}
            <section className="mb-12">
                <h2 className="text-3xl font-bold">{t('Integrated Analysis')}</h2>

                {/* Text Analysis Cards */}
                <div className="space-y-8 mb-12">
                    {template.parts.reduce((allQuestions, part) => {
                        const textQuestions = part.questions.filter(q =>
                            q.analysis_types?.some(type => type.startsWith('text_'))
                        );
                        return [...allQuestions, ...textQuestions];
                    }, []).map((question, index) => (
                        <ColorTextChart
                            key={`text-${question.id}-${index}`}
                            question={question}
                            collection={collection}
                            comparisonData={comparisonData}
                            language={userLang}
                        />
                    ))}
                </div>

                {/* Radar Charts - only for questions with radar analysis type */}
                {comparisonGroupQuestions
                    .filter(question =>
                        question.analysis_types?.includes('comparison_external_as_radar_chart')
                    ).length > 0 && (
                        <div className="space-y-8 mb-12">
                            {comparisonGroupQuestions
                                .filter(question =>
                                    question.analysis_types?.includes('comparison_external_as_radar_chart')
                                )
                                .map((question, index) => (
                                    <RadarChart
                                        key={`radar-${question.comparison_group}-${index}`}
                                        question={question}
                                        collection={collection}
                                        template={template}
                                        comparisonData={comparisonData}
                                    />
                                ))}
                        </div>
                    )}

                {/* Traffic Light Table - only for collaborative collections */}
                {collection.collection_type === 'collaborative' && (
                    <div className="space-y-8 mb-12">
                        {(() => {
                            const allQuestions = template.parts.reduce((allQuestions, part) => {
                                const partQuestions = part.questions.filter(q =>
                                    // Only include questions that are compatible with traffic light visualization
                                    q.analysis_types?.includes('summary_external_as_traffic_lights') &&
                                    // Ensure we're only getting questions of types that can be compared
                                    ['radio', 'boolean', 'percentage'].includes(q.type)
                                );
                                return [...allQuestions, ...partQuestions];
                            }, []);

                            return (
                                <TrafficLightTable
                                    questions={allQuestions}
                                    collection={collection}
                                    comparisonData={comparisonData}
                                    language={userLang}
                                />
                            );
                        })()}
                    </div>
                )}

                {/* Box Plot / Bar Charts - for all comparison groups */}
                <div className="space-y-8">
                    {comparisonGroupQuestions
                        .filter(question =>
                            question.analysis_types?.includes('comparison_external_as_boxplot')
                        )
                        .map((question, index) => {
                            if (collection.collection_type === 'individual') {
                                return (
                                    <BoxPlotChart
                                        key={`boxplot-${question.comparison_group}-${index}`}
                                        question={question}
                                        collection={collection}
                                        template={template}
                                        comparisonData={comparisonData} // Add this line
                                    />
                                );
                            } else {
                                return (
                                    <BarChartComparison
                                        key={`bar-comparison-${question.comparison_group}-${index}`}
                                        question={question}
                                        collection={collection}
                                        template={template}
                                        comparisonData={comparisonData} // Add this line
                                    />
                                );
                            }
                        })}
                </div>
            </section>

            {/* Segmented Analysis Section */}
            <section>
                <h2 className="text-3xl font-bold mb-2">{t('Segmented Analysis')}</h2>
                {partAnalyses.map(({ part, questions }, index) => (
                    <CollapsibleSection
                        key={part.id}
                        title={getTranslatedValue(part.name, userLang)}
                        isFirst={index === 0}
                        isLast={index === partAnalyses.length - 1}
                    >
                        {/* Part Assignments */}
                        {collection.part_assignments?.[part.id] && (
                            <div className="bg-gray-50 rounded-lg p-4 mb-6">
                                {collection.collection_type === 'collaborative' ? (
                                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                        <div>
                                            <h3 className="text-sm font-medium text-gray-500 mb-2">{t('Editors')}</h3>
                                            <div className="text-sm text-gray-600">
                                                {collection.part_assignments[part.id].editors.map(userId => {
                                                    const user = collection.respondents.find(r => r.id === userId);
                                                    return user ? user.user_name : '';
                                                }).filter(Boolean).join(', ')}
                                            </div>
                                        </div>
                                        <div>
                                            <h3 className="text-sm font-medium text-gray-500 mb-2">{t('Submitters')}</h3>
                                            <div className="text-sm text-gray-600">
                                                {collection.part_assignments[part.id].submitters.map(userId => {
                                                    const user = collection.respondents.find(r => r.id === userId);
                                                    return user ? user.user_name : '';
                                                }).filter(Boolean).join(', ')}
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div>
                                        <h3 className="text-sm font-medium text-gray-500 mb-2">{t('Assignees')}</h3>
                                        <div className="text-sm text-gray-600">
                                            {collection.part_assignments[part.id].assignees?.map(userId => {
                                                const user = collection.respondents.find(r => r.id === userId);
                                                return user ? user.user_name : '';
                                            }).filter(Boolean).join(', ')}
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
                            {questions.map((question) => (
                                <div key={question.id} className={question.analysis_types.some(type =>
                                    type === 'summary_external_as_traffic_lights'
                                ) ? 'lg:col-span-1' : 'lg:col-span-2'}>
                                    {question.analysis_types.map((analysisType) => {
                                        if (analysisType === 'comparison_internal_as_bar_chart_desc') {
                                            return (
                                                <BarChart
                                                    key={`${question.id}-bar`}
                                                    question={question}
                                                    collection={collection}
                                                />
                                            );
                                        }
                                        if (analysisType === 'summary_external_as_traffic_lights' && collection.collection_type === 'collaborative') {
                                            return (
                                                <TrafficLightCard
                                                    key={`${question.id}-traffic`}
                                                    question={question}
                                                    collection={collection}
                                                    comparisonData={comparisonData}
                                                    language={userLang}
                                                />
                                            );
                                        }
                                        if (analysisType === 'stock_over_time_as_multiplecolumn_chart') {
                                            console.log("Rendering stock column chart for question", question.id);
                                            return (
                                                <StockColumnChart
                                                    key={`${question.id}-stock`}
                                                    question={question}
                                                    collection={collection}
                                                    comparisonData={comparisonData}
                                                    language={userLang}
                                                />
                                            );
                                        }
                                        if (analysisType === 'comparison_over_time_as_multiplebar_chart') {
                                            return (
                                                <GroupedColumnChart
                                                    key={`${question.id}-grouped`}
                                                    question={question}
                                                    collection={collection}
                                                    comparisonData={comparisonData}
                                                    language={userLang}
                                                />
                                            );
                                        }
                                        if (analysisType === 'comparison_over_time_as_column_100') {
                                            console.log("Rendering column 100 chart for question", question.id);
                                            return (
                                                <Column100Chart
                                                    key={`${question.id}-column-100`}
                                                    question={question}
                                                    collection={collection}
                                                    comparisonData={comparisonData}
                                                    language={userLang}
                                                />
                                            );
                                        }
                                        return null;
                                    })}
                                </div>
                            ))}
                        </div>
                    </CollapsibleSection>
                ))}
            </section>
        </div>
    );
};

export default DataCollectionReport;