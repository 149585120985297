// components/questions/PercentageQuestion.js
import React from 'react';
import { getTranslatedValue } from '../../../utils/templateTranslations';

const PercentageQuestion = ({
    question,
    value,
    onChange,
    notes,
    onNotesChange,
    showNotes,
    notesLabel,
    language,
    readOnly
  }) => {
    const handleChange = (e) => {
      let val = e.target.value;
      // Allow empty string for clearing the input
      if (val === '') {
        onChange('');
        return;
      }
  
      // Convert to number and validate
      val = parseFloat(val);
      if (!isNaN(val) && val >= question.min && val <= question.max) {
        onChange(val);
      }
    };
  
    return (
      <div className="space-y-4 mt-10">
        <div className="text-base font-medium text-gray-900">
          {getTranslatedValue(question.text, language)}
        </div>
  
        <div className="flex items-center space-x-2">
          <input
            type="number"
            value={value ?? ''}
            onChange={handleChange}
            min={question.min}
            max={question.max}
            step={question.step || 0.1}
            disabled={readOnly}
            className={`block w-32 rounded-md shadow-sm sm:text-sm ${
              readOnly
                ? 'bg-gray-200 text-gray-700 border-gray-200 cursor-default'
                : 'border-gray-300 focus:border-secondary-color focus:ring-secondary-color'
            }`}
          />
          <span className="text-gray-700">%</span>
        </div>
  
        {showNotes && (
          <div>
            <label className="block text-sm font-medium text-gray-700">
              {notesLabel}
            </label>
            <textarea
              value={notes || ''}
              onChange={(e) => onNotesChange(e.target.value)}
              rows={3}
              disabled={readOnly}
              className={`mt-1 block w-full rounded-md shadow-sm sm:text-sm ${
                readOnly
                  ? 'bg-gray-200 text-gray-700 border-gray-200 cursor-default'
                  : 'border-gray-300 focus:border-secondary-color focus:ring-secondary-color'
              }`}
            />
          </div>
        )}
      </div>
    );
  };
  
  export default PercentageQuestion;