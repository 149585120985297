import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import { RadioGroup } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { Helmet } from 'react-helmet';

const questions = [
    {
        id: 'capital',
        questionKey: 'legalForm.questions.capital.question',
        options: [
            {
                labelKey: 'legalForm.questions.capital.options.little',
                value: 'little',
                score: { UG: 3 },
                recommendationTextKeys: {
                    UG: 'legalForm.recommendations.capital.little.forUG',
                    GmbH: 'legalForm.recommendations.capital.little.forGmbH'
                }
            },
            {
                labelKey: 'legalForm.questions.capital.options.more',
                value: 'more',
                score: { GmbH: 3 },
                recommendationTextKeys: {
                    UG: 'legalForm.recommendations.capital.more.forUG',
                    GmbH: 'legalForm.recommendations.capital.more.forGmbH'
                }
            },
        ],
    },
    {
        id: 'profits',
        questionKey: 'legalForm.questions.profits.question',
        options: [
            {
                labelKey: 'legalForm.questions.profits.options.yes',
                value: 'yes',
                score: { GmbH: 1 },
                recommendationTextKeys: {
                    UG: 'legalForm.recommendations.profits.yes.forUG',
                    GmbH: 'legalForm.recommendations.profits.yes.forGmbH'
                }
            },
            {
                labelKey: 'legalForm.questions.profits.options.no',
                value: 'no',
                score: { UG: 1 },
                recommendationTextKeys: {
                    UG: 'legalForm.recommendations.profits.no.forUG',
                    GmbH: 'legalForm.recommendations.profits.no.forGmbH'
                }
            },
        ],
    },
    {
        id: 'scope',
        questionKey: 'legalForm.questions.scope.question',
        options: [
            {
                labelKey: 'legalForm.questions.scope.options.small',
                value: 'small',
                score: { UG: 1 },
                recommendationTextKeys: {
                    UG: 'legalForm.recommendations.scope.small.forUG',
                    GmbH: 'legalForm.recommendations.scope.small.forGmbH'
                }
            },
            {
                labelKey: 'legalForm.questions.scope.options.large',
                value: 'large',
                score: { GmbH: 2 },
                recommendationTextKeys: {
                    UG: 'legalForm.recommendations.scope.large.forUG',
                    GmbH: 'legalForm.recommendations.scope.large.forGmbH'
                }
            },
        ],
    },
    {
        id: 'priority',
        questionKey: 'legalForm.questions.priority.question',
        options: [
            {
                labelKey: 'legalForm.questions.priority.options.growth',
                value: 'growth',
                score: { GmbH: 2 },
                recommendationTextKeys: {
                    UG: 'legalForm.recommendations.priority.growth.forUG',
                    GmbH: 'legalForm.recommendations.priority.growth.forGmbH'
                }
            },
            {
                labelKey: 'legalForm.questions.priority.options.bootstrap',
                value: 'bootstrap',
                score: { UG: 1 },
                recommendationTextKeys: {
                    UG: 'legalForm.recommendations.priority.bootstrap.forUG',
                    GmbH: 'legalForm.recommendations.priority.bootstrap.forGmbH'
                }
            },
        ],
    },
    {
        id: 'scaling',
        questionKey: 'legalForm.questions.scaling.question',
        options: [
            {
                labelKey: 'legalForm.questions.scaling.options.fast',
                value: 'fast',
                score: { GmbH: 2 },
                recommendationTextKeys: {
                    UG: 'legalForm.recommendations.scaling.fast.forUG',
                    GmbH: 'legalForm.recommendations.scaling.fast.forGmbH'
                }
            },
            {
                labelKey: 'legalForm.questions.scaling.options.slow',
                value: 'slow',
                score: { UG: 1 },
                recommendationTextKeys: {
                    UG: 'legalForm.recommendations.scaling.slow.forUG',
                    GmbH: 'legalForm.recommendations.scaling.slow.forGmbH'
                }
            },
        ],
    },
    {
        id: 'milestone',
        questionKey: 'legalForm.questions.milestone.question',
        options: [
            {
                labelKey: 'legalForm.questions.milestone.options.less',
                value: 'less',
                score: { UG: 1 },
                recommendationTextKeys: {
                    UG: 'legalForm.recommendations.milestone.less.forUG',
                    GmbH: 'legalForm.recommendations.milestone.less.forGmbH'
                }
            },
            {
                labelKey: 'legalForm.questions.milestone.options.more',
                value: 'more',
                score: { GmbH: 3 },
                recommendationTextKeys: {
                    UG: 'legalForm.recommendations.milestone.more.forUG',
                    GmbH: 'legalForm.recommendations.milestone.more.forGmbH'
                }
            },
        ],
    },
    {
        id: 'financing',
        questionKey: 'legalForm.questions.financing.question',
        options: [
            {
                labelKey: 'legalForm.questions.financing.options.medium',
                value: 'medium',
                score: { GmbH: 2 },
                recommendationTextKeys: {
                    UG: 'legalForm.recommendations.financing.medium.forUG',
                    GmbH: 'legalForm.recommendations.financing.medium.forGmbH'
                }
            },
            {
                labelKey: 'legalForm.questions.financing.options.small',
                value: 'small',
                score: { UG: 1 },
                recommendationTextKeys: {
                    UG: 'legalForm.recommendations.financing.small.forUG',
                    GmbH: 'legalForm.recommendations.financing.small.forGmbH'
                }
            },
        ],
    },
    {
        id: 'risk',
        questionKey: 'legalForm.questions.risk.question',
        options: [
            {
                labelKey: 'legalForm.questions.risk.options.low',
                value: 'low',
                score: { UG: 1 },
                recommendationTextKeys: {
                    UG: 'legalForm.recommendations.risk.low.forUG',
                    GmbH: 'legalForm.recommendations.risk.low.forGmbH'
                }
            },
            {
                labelKey: 'legalForm.questions.risk.options.moderate',
                value: 'moderate',
                score: { GmbH: 2 },
                recommendationTextKeys: {
                    UG: 'legalForm.recommendations.risk.moderate.forUG',
                    GmbH: 'legalForm.recommendations.risk.moderate.forGmbH'
                }
            },
        ],
    },
    {
        id: 'founderRole',
        questionKey: 'legalForm.questions.founderRole.question',
        options: [
            {
                labelKey: 'legalForm.questions.founderRole.options.small',
                value: 'small',
                score: { UG: 1 },
                recommendationTextKeys: {
                    UG: 'legalForm.recommendations.founderRole.small.forUG',
                    GmbH: 'legalForm.recommendations.founderRole.small.forGmbH'
                }
            },
            {
                labelKey: 'legalForm.questions.founderRole.options.mid',
                value: 'mid',
                score: { GmbH: 2 },
                recommendationTextKeys: {
                    UG: 'legalForm.recommendations.founderRole.mid.forUG',
                    GmbH: 'legalForm.recommendations.founderRole.mid.forGmbH'
                }
            },
        ],
    },
    {
        id: 'adminEffort',
        questionKey: 'legalForm.questions.adminEffort.question',
        options: [
            {
                labelKey: 'legalForm.questions.adminEffort.options.minimal',
                value: 'minimal',
                score: { UG: 1 },
                recommendationTextKeys: {
                    UG: 'legalForm.recommendations.adminEffort.minimal.forUG',
                    GmbH: 'legalForm.recommendations.adminEffort.minimal.forGmbH'
                }
            },
            {
                labelKey: 'legalForm.questions.adminEffort.options.moderate',
                value: 'moderate',
                score: { GmbH: 2 },
                recommendationTextKeys: {
                    UG: 'legalForm.recommendations.adminEffort.moderate.forUG',
                    GmbH: 'legalForm.recommendations.adminEffort.moderate.forGmbH'
                }
            },
        ],
    },
    {
        id: 'market',
        questionKey: 'legalForm.questions.market.question',
        options: [
            {
                labelKey: 'legalForm.questions.market.options.local',
                value: 'local',
                score: { UG: 1 },
                recommendationTextKeys: {
                    UG: 'legalForm.recommendations.market.local.forUG',
                    GmbH: 'legalForm.recommendations.market.local.forGmbH'
                }
            },
            {
                labelKey: 'legalForm.questions.market.options.national',
                value: 'national',
                score: { GmbH: 2 },
                recommendationTextKeys: {
                    UG: 'legalForm.recommendations.market.national.forUG',
                    GmbH: 'legalForm.recommendations.market.national.forGmbH'
                }
            },
        ],
    },
    {
        id: 'founderCount',
        questionKey: 'legalForm.questions.founderCount.question',
        options: [
            {
                labelKey: 'legalForm.questions.founderCount.options.few',
                value: 'few',
                score: { UG: 1 },
                recommendationTextKeys: {
                    UG: 'legalForm.recommendations.founderCount.few.forUG',
                    GmbH: 'legalForm.recommendations.founderCount.few.forGmbH'
                }
            },
            {
                labelKey: 'legalForm.questions.founderCount.options.many',
                value: 'many',
                score: { GmbH: 2 },
                recommendationTextKeys: {
                    UG: 'legalForm.recommendations.founderCount.many.forUG',
                    GmbH: 'legalForm.recommendations.founderCount.many.forGmbH'
                }
            },
        ],
    },
    {
        id: 'profitDistribution',
        questionKey: 'legalForm.questions.profitDistribution.question',
        options: [
            {
                labelKey: 'legalForm.questions.profitDistribution.options.yes',
                value: 'yes',
                score: { GmbH: 2 },
                recommendationTextKeys: {
                    UG: 'legalForm.recommendations.profitDistribution.yes.forUG',
                    GmbH: 'legalForm.recommendations.profitDistribution.yes.forGmbH'
                }
            },
            {
                labelKey: 'legalForm.questions.profitDistribution.options.no',
                value: 'no',
                score: { UG: 1 },
                recommendationTextKeys: {
                    UG: 'legalForm.recommendations.profitDistribution.no.forUG',
                    GmbH: 'legalForm.recommendations.profitDistribution.no.forGmbH'
                }
            },
        ],
    },
    {
        id: 'credibility',
        questionKey: 'legalForm.questions.credibility.question',
        options: [
            {
                labelKey: 'legalForm.questions.credibility.options.important',
                value: 'important',
                score: { GmbH: 2 },
                recommendationTextKeys: {
                    UG: 'legalForm.recommendations.credibility.important.forUG',
                    GmbH: 'legalForm.recommendations.credibility.important.forGmbH'
                }
            },
            {
                labelKey: 'legalForm.questions.credibility.options.notVery',
                value: 'notVery',
                score: { UG: 1 },
                recommendationTextKeys: {
                    UG: 'legalForm.recommendations.credibility.notVery.forUG',
                    GmbH: 'legalForm.recommendations.credibility.notVery.forGmbH'
                }
            },
        ],
    },
];

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function LegalFormLanding() {
    const { t } = useTranslation();
    const [answers, setAnswers] = useState({});
    const [allQuestionsAnswered, setAllQuestionsAnswered] = useState(false);
    const [scores, setScores] = useState({ UG: 0, GmbH: 0, AG: 0 });
    const [isBannerVisible, setIsBannerVisible] = useState(true);
    const [isAnimating, setIsAnimating] = useState(false);

    // Check localStorage for banner dismissal status
    useEffect(() => {
        const dismissed = localStorage.getItem('bannerDismissed');
        if (dismissed) {
            setIsBannerVisible(false);
        }
    }, []);

    // Handle dismiss action
    const handleBannerDismiss = () => {
        setIsAnimating(true);
        setTimeout(() => {
            setIsBannerVisible(false);
            localStorage.setItem('bannerDismissed', 'true');
        }, 300); // Match this duration with the Tailwind duration class
    };

    const handleLinkClick = (category, action, name) => {
        window._paq.push(['trackEvent', category, action, name]);
    };

    const calculateScores = () => {
        const newScores = { UG: 0, GmbH: 0, AG: 0 };
        Object.entries(answers).forEach(([questionId, answer]) => {
            const question = questions.find(q => q.id === questionId);
            const option = question.options.find(o => o.value === answer);
            if (option && option.score) {
                Object.entries(option.score).forEach(([form, score]) => {
                    newScores[form] += score;
                });
            }
        });
        setScores(newScores);
    };

    const getRecommendedLegalForm = () => {
        return Object.entries(scores).reduce((a, b) => a[1] > b[1] ? a : b)[0];
    };


    // Check if all questions are answered
    useEffect(() => {
        const answeredCount = Object.keys(answers).length;
        setAllQuestionsAnswered(answeredCount === questions.length);
    }, [answers]);

    const calculateRecommendation = () => {
        const newScores = { UG: 0, GmbH: 0 };

        // Calculate scores
        Object.entries(answers).forEach(([questionId, answer]) => {
            const question = questions.find(q => q.id === questionId);
            const option = question.options.find(o => o.value === answer);

            if (option && option.score) {
                Object.entries(option.score).forEach(([form, score]) => {
                    newScores[form] += score;
                });
            }
        });

        // Determine recommended form
        const recommendedForm = newScores.UG > newScores.GmbH ? 'UG' : 'GmbH';

        // Generate recommendation text using translation keys
        const recommendationText = questions
            .map(question => {
                const answer = answers[question.id];
                if (answer) {
                    const option = question.options.find(o => o.value === answer);
                    return t(option.recommendationTextKeys[recommendedForm]);
                }
                return '';
            })
            .filter(Boolean)
            .join(' ');

        return {
            scores: newScores,
            recommendedForm,
            text: recommendationText
        };
    };

    return (
        <div className="bg-white min-h-screen">

            {/* SEO Meta Tags */}
            <Helmet>
                {/* Basic SEO */}
                <title>Cenedril - Your Guide to a Smarter, Safer Organization.</title>
                <meta
                    name="description"
                    content="Cenedril is an online management system software that supports you in building a more compliant, secure, and efficient organization."
                />
                <meta name="robots" content="index, follow" />
                <meta name="author" content="Cenedril Team" />
                <meta name="keywords" content="management system, compliance software, GDPR, task management, startups, cybersecurity, ISO 27001, online tools for businesses" />
                <meta name="theme-color" content="#ffffff" />

                {/* Favicon */}
                <link rel="icon" href="/favicon.ico" />
                <link rel="apple-touch-icon" href="/apple-touch-icon.png" />

                {/* Structured Data */}
                <script type="application/ld+json">
                    {`
                        {
                            "@context": "https://schema.org",
                            "@type": "WebSite",
                            "name": "Cenedril - Your Guide to a Smarter, Safer Organization.",
                            "description": "Discover the first management system you will fall in love with.",
                            "url": "https://cenedril.net",
                            "inLanguage": "en",
                            "publisher": {
                            "@type": "Organization",
                            "name": "Cenedril",
                            "logo": {
                                "@type": "ImageObject",
                                "url": "https://cenedril.net/logo_twocolor.svg",
                                "width": 512,
                                "height": 512
                            }
                            },
                            "potentialAction": {
                            "@type": "SearchAction",
                            "target": "https://cenedril.net/?search={search_term_string}",
                            "query-input": "required name=search_term_string"
                            }
                        }
                        `}
                </script>

                {/* Open Graph */}
                <meta property="og:title" content="Cenedril - Your Guide to a Smarter, Safer Organization." />
                <meta property="og:description" content="Discover the first management system you will fall in love with." />
                <meta property="og:image" content="https://cenedril.net/logo_twocolor.svg" />
                <meta property="og:image:alt" content="Cenedril Logo" />
                <meta property="og:image:width" content="512" />
                <meta property="og:image:height" content="512" />
                <meta property="og:type" content="website" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:site_name" content="Cenedril" />
                <meta property="og:url" content="https://cenedril.net" />

                {/* Twitter Card */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Cenedril - Your Guide to a Smarter, Safer Organization." />
                <meta name="twitter:description" content="Discover the first management system you will fall in love with." />
                <meta name="twitter:image" content="https://dev.cenedril.net/logo_twocolor.svg" />
                <meta name="twitter:image:alt" content="Cenedril Logo" />
                <meta name="twitter:site" content="@CenedrilOfficial" />
                <meta name="twitter:creator" content="@CenedrilOfficial" />

                {/* Additional Metadata */}
                <link rel="canonical" href="https://cenedril.net" />
                <meta property="og:updated_time" content={new Date().toISOString()} />
            </Helmet>


            {/* Banner */}
            {isBannerVisible && (
                <div
                    className={`fixed top-0 w-full z-40 flex items-center gap-x-6 bg-secondary-color px-6 py-2.5 sm:px-3.5 sm:before:flex-1 transition-transform duration-300 ${isAnimating ? '-translate-y-full' : 'translate-y-0'
                        }`}
                >
                    <p className="text-xs text-white">
                        <strong className="font-semibold">{t('betabanner1')}</strong>
                        <svg viewBox="0 0 2 2" aria-hidden="true" className="mx-2 inline h-0.5 w-0.5 fill-current">
                            <circle r={1} cx={1} cy={1} />
                        </svg>
                        {t('betabanner2')}
                        <strong className="font-semibold">{t('betabanner3')}</strong>
                    </p>
                    <div className="flex flex-1 justify-end">
                        <button
                            type="button"
                            className="-m-3 p-3 focus-visible:outline-offset-[-4px]"
                            onClick={handleBannerDismiss}
                        >
                            <span className="sr-only">Dismiss</span>
                            <XMarkIcon aria-hidden="true" className="h-5 w-5 text-white" />
                        </button>
                    </div>
                </div>
            )}

            {/* Header with Logo */}
            <div className="relative isolate">
                <div className="px-6 pt-24 lg:px-8">
                    <div className="flex justify-center">
                        <img src="/logo_twocolor.svg" alt={t('common.logoAlt')} className="h-20" />
                    </div>
                </div>

                {/* Hero Section */}
                <div className="mx-auto max-w-4xl pt-16 pb-12 px-6">
                    <div className="text-center">
                        <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                            {t('legalFormlandingtitle')}
                        </h1>
                        <p className="mt-6 text-xl leading-8 text-gray-600">
                            {t('legalFormlandingsubtitle')}
                        </p>
                    </div>
                </div>

                {/* Legal Form Wizard Section */}
                <div className="mx-auto max-w-7xl px-6 pb-24">
                    <div className="space-y-8">
                        {questions.map((question) => (
                            <div key={question.id} className="space-y-4">
                                <h3 className="text-sm font-sembiold leading-6 text-gray-900">
                                    {t(question.questionKey)}
                                </h3>
                                <RadioGroup
                                    value={answers[question.id]}
                                    onChange={(value) => setAnswers({ ...answers, [question.id]: value })}
                                >
                                    <div className="space-y-4">
                                        {question.options.map((option) => (
                                            <RadioGroup.Option
                                            key={option.value}
                                            value={option.value}
                                            className={({ checked }) =>
                                                classNames(
                                                    'relative border rounded-lg p-4 flex cursor-pointer focus:outline-none',
                                                    checked ? 'bg-[#2A9D8F]/10 border-[#2A9D8F]' : 'bg-white border-gray-200 hover:bg-gray-50'
                                                )
                                            }
                                        >
                                            {({ checked }) => (
                                                <>
                                                    <div className="self-center flex-shrink-0">
                                                        <span
                                                            className={classNames(
                                                                'flex h-5 w-5 items-center justify-center rounded-full border',
                                                                checked ? 'bg-[#2A9D8F] border-transparent' : 'bg-white border-gray-300'
                                                            )}
                                                            aria-hidden="true"
                                                        >
                                                            {checked && (
                                                                <span className="rounded-full bg-white w-1.5 h-1.5" />
                                                            )}
                                                        </span>
                                                    </div>
                                                    <div className="ml-3 flex-grow">
                                                        <RadioGroup.Label
                                                            as="span"
                                                            className={classNames(
                                                                'block text-sm font-medium',
                                                                checked ? 'text-[#2A9D8F]' : 'text-gray-900'
                                                            )}
                                                        >
                                                            {t(option.labelKey)}
                                                        </RadioGroup.Label>
                                                    </div>
                                                </>
                                            )}
                                        </RadioGroup.Option>
                                        ))}
                                    </div>
                                </RadioGroup>
                            </div>
                        ))}

                        {allQuestionsAnswered && (
                            <div className="mt-8 rounded-lg bg-[#2A9D8F] p-6">
                                <h3 className="text-xl font-bold text-white mb-4">
                                    {t('legalForm.recommendation.title', {
                                        form: calculateRecommendation().recommendedForm
                                    })}
                                </h3>
                                <p className="text-white">
                                    {calculateRecommendation().text}
                                </p>
                            </div>
                        )}
                    </div>

                    {/* CTA Section */}
                    {allQuestionsAnswered && (
                        <div className="mt-8 bg-[#2A9D8F]/10 rounded-lg p-8 text-center">
                            <p className="text-lg text-gray-800 mb-8">
                                {t('legalFormlandingCTA')}
                            </p>
                            <div className="flex justify-center gap-4">
                                <Link
                                    to="/signup"
                                    className="rounded-md bg-[#2A9D8F] px-6 py-3 text-base font-semibold text-white hover:text-white shadow-sm hover:bg-[#2A9D8F]/80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#2A9D8F]"
                                >
                                    {t('common.getStarted')}
                                </Link>
                                <Link
                                    to="/"
                                    className="rounded-md px-6 py-3 text-base font-semibold text-[#2A9D8F] hover:text-[#2A9D8F] ring-1 ring-inset ring-[#2A9D8F] hover:bg-[#2A9D8F]/10"
                                >
                                    {t('tellMeMore')}
                                </Link>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className="h-24"></div>
        </div>
    );
}