import React from 'react';
import { getTranslatedValue } from '../../../utils/templateTranslations';

const RadioButtonQuestion = ({
  question,
  value,
  onChange,
  notes,
  onNotesChange,
  showNotes,
  notesLabel,
  language,
  readOnly
}) => {
  const options = getTranslatedValue(question.options, language) || [];

  // Convert text value to index if needed (for backward compatibility)
  const selectedIndex = typeof value === 'number' ? value : options.indexOf(value);

  return (
    <div className="space-y-4 mt-10">
      <div className="text-base font-medium text-gray-900">
        {getTranslatedValue(question.text, language)}
      </div>

      <div className="space-y-2">
        {options.map((option, idx) => (
          <div key={idx} className="flex items-center">
            <input
              id={`${question.id}-${idx}`}
              type="radio"
              name={question.id}
              value={idx}
              checked={selectedIndex === idx}
              onChange={() => onChange(idx)}
              disabled={readOnly}
              className="h-4 w-4 text-secondary-color border-gray-300 focus:ring-secondary-color"
            />
            <label
              htmlFor={`${question.id}-${idx}`}
              className="ml-2 block text-sm text-gray-700"
            >
              {option}
            </label>
          </div>
        ))}
      </div>

      {showNotes && (
        <div>
          <label className="block text-sm font-medium text-gray-700">
            {notesLabel}
          </label>
          <textarea
            value={notes || ''}
            onChange={(e) => onNotesChange(e.target.value)}
            rows={3}
            disabled={readOnly}
            className={`mt-1 block w-full rounded-md shadow-sm sm:text-sm ${
              readOnly
                ? 'bg-gray-200 text-gray-700 border-gray-200 cursor-default'
                : 'border-gray-300 focus:border-secondary-color focus:ring-secondary-color'
            }`}
          />
        </div>
      )}
    </div>
  );
};

export default RadioButtonQuestion;