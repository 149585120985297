import React from 'react';
import { useTranslation } from 'react-i18next';
import { CheckCircleIcon } from '@heroicons/react/24/outline';

const FinishStep = ({ formData, saving, error }) => {
  const { t } = useTranslation();

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString();
  };

  return (
    <div className="space-y-6">
      <div className="bg-green-50 border-l-4 border-green-400 p-4">
        <div className="flex">
          <CheckCircleIcon className="h-6 w-6 text-green-400" />
          <div className="ml-3">
            <p className="text-sm text-green-700">
              {t('Please review your data collection settings before saving')}
            </p>
          </div>
        </div>
      </div>

      {/* Summary Section */}
      <div className="bg-white rounded-lg shadow p-6">
        <h3 className="text-lg font-medium text-gray-900 mb-4">
          {t('Collection Summary')}
        </h3>

        <div className="space-y-4">
          {/* Template */}
          <div>
            <h4 className="text-sm font-medium text-gray-500">{t('Template')}</h4>
            <p className="mt-1 text-sm text-gray-900">{formData.templateName}</p>
          </div>

          {/* Collection Type */}
          <div>
            <h4 className="text-sm font-medium text-gray-500">{t('Collection Type')}</h4>
            <p className="mt-1 text-sm text-gray-900">
              {formData.isAnonymous ? t('Anonymous') : t('Named')} - {' '}
              {formData.collectionType === 'individual' ? t('Individual Entry') : t('Collaborative Entry')}
            </p>
          </div>

          {/* People */}
          <div>
            <h4 className="text-sm font-medium text-gray-500">{t('People')}</h4>
            <div className="mt-1 space-y-2">
              <p className="text-sm text-gray-900">
                <span className="font-medium">{t('Viewers')}:</span> {formData.viewers.length}
              </p>
              <p className="text-sm text-gray-900">
                <span className="font-medium">{t('Respondents')}:</span> {formData.respondents.length}
              </p>
            </div>
          </div>

          {/* Timing */}
          <div>
            <h4 className="text-sm font-medium text-gray-500">{t('Timing')}</h4>
            <div className="mt-1 space-y-2">
              {formData.deadline ? (
                <p className="text-sm text-gray-900">
                  <span className="font-medium">{t('Deadline')}:</span> {formatDate(formData.deadline)}
                </p>
              ) : (
                <p className="text-sm text-gray-900">{t('No deadline set')}</p>
              )}
              {formData.reminders.length > 0 && (
                <p className="text-sm text-gray-900">
                  <span className="font-medium">{t('Reminders')}:</span> {formData.reminders.map(r => t(r)).join(', ')}
                </p>
              )}
              {formData.periodType && (
                <p className="text-sm text-gray-900">
                  <span className="font-medium">{t('Repetition')}:</span> {t(formData.periodType)}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>

      {error && (
        <div className="rounded-md bg-red-50 p-4">
          <p className="text-sm text-red-700">{error}</p>
        </div>
      )}

      {saving && (
        <div className="text-center text-sm text-gray-500">
          {t('Saving data collection...')}
        </div>
      )}
    </div>
  );
};

export default FinishStep;