import React, { useState, useEffect } from 'react';
import BlockAveragesTable from './BlockAveragesTable';
import IndividualResponsesTable from './IndividualResponsesTable';
import ResponderAnalysisTable from './ResponderAnalysisTable';
import QuestionAverageTable from './QuestionAveragesTable';
import CollapsibleSection from './CollapsibleSection';
import { getTranslatedValue } from '../../../utils/templateTranslations';
import axios from '../../../axios';

const DataAnalysisSection = ({
    responses,
    question,
    template,
    getQuestionText,
    getUserName,
    t,
    isReversed,
    range,
    collectionType
}) => {
    // Helper function to get questions in comparison group
    const getQuestionsInComparisonGroup = () => {
        const targetGroup = question.comparison_group;
        const questions = new Set();

        template.parts.forEach(part => {
            part.questions.forEach(q => {
                if (q.comparison_group === targetGroup) {
                    questions.add(q.id);
                }
            });
        });

        return questions;
    };

    const [minValue, maxValue] = range;

    const processResponsesForTable = () => {
        const questionsInGroup = getQuestionsInComparisonGroup();
        const processedResponses = [];

        responses.forEach(response => {
            const identifier = response.is_anonymous ? response.anonymous_id : response.user_id;
            
            Object.entries(response.response_data).forEach(([questionId, data]) => {
                if (questionsInGroup.has(questionId) && typeof data === 'object') {
                    Object.entries(data).forEach(([itemId, value]) => {
                        processedResponses.push({
                            value,
                            questionId: `${questionId}.${itemId}`,
                            userId: identifier,
                            isAnonymous: response.is_anonymous,
                            partName: questionId.split('_')[0]
                                .split('_')
                                .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                                .join(' '),
                            uniqueKey: `${identifier}-${questionId}.${itemId}-${value}`
                        });
                    });
                }
            });
        });

        return processedResponses;
    };

    // Calculate averages for each question
    const calculateQuestionAverages = () => {
        const questionsInGroup = getQuestionsInComparisonGroup();
        const questionAverages = {};

        responses.forEach(response => {
            Object.entries(response.response_data).forEach(([questionId, data]) => {
                if (questionsInGroup.has(questionId) && typeof data === 'object') {
                    if (!questionAverages[questionId]) {
                        questionAverages[questionId] = { sum: 0, count: 0 };
                    }
                    Object.values(data).forEach(value => {
                        questionAverages[questionId].sum += value;
                        questionAverages[questionId].count += 1;
                    });
                }
            });
        });

        return Object.entries(questionAverages)
            .map(([questionId, data]) => ({
                questionId,
                average: data.count > 0 ? data.sum / data.count : 0
            }))
            .sort((a, b) => b.average - a.average);
    };

    // Calculate user averages and extremes
    const calculateUserStats = () => {
        const questionsInGroup = getQuestionsInComparisonGroup();
        const userStats = {};

        responses.forEach(response => {
            const identifier = response.is_anonymous ? response.anonymous_id : response.user_id;
            
            if (!userStats[identifier]) {
                userStats[identifier] = {
                    sum: 0,
                    count: 0,
                    maxScores: 0,
                    minScores: 0,
                    isAnonymous: response.is_anonymous
                };
            }

            Object.entries(response.response_data).forEach(([questionId, data]) => {
                if (questionsInGroup.has(questionId) && typeof data === 'object') {
                    Object.values(data).forEach(value => {
                        userStats[identifier].sum += value;
                        userStats[identifier].count += 1;
                        if (value === maxValue) userStats[identifier].maxScores += 1;
                        if (value === minValue) userStats[identifier].minScores += 1;
                    });
                }
            });
        });

        return Object.entries(userStats)
            .map(([identifier, stats]) => ({
                userId: identifier,
                isAnonymous: stats.isAnonymous,
                average: stats.count > 0 ? stats.sum / stats.count : 0,
                maxScores: stats.maxScores,
                minScores: stats.minScores,
                responsesCount: stats.count
            }))
            .sort((a, b) => b.average - a.average);
    };

    const averages = calculateQuestionAverages();
    const userStats = calculateUserStats();

    const [userLang, setUserLang] = useState('en');

    const getUserNameForDisplay = (userId, isAnonymous) => {
        if (isAnonymous) {
            return `${t('Anonymous')} ${userId.split('_')[1]}`;
        }
        return getUserName(userId);
    };

    useEffect(() => {
        const fetchUserLang = async () => {
            try {
                const response = await axios.get('/api/v1/live_data/user_data', {
                    headers: { Authorization: `Bearer ${sessionStorage.getItem('authToken')}` },
                });
                if (response.data.language) {
                    setUserLang(response.data.language.slice(0, 2));
                }
            } catch (error) {
                console.error('Error fetching user language:', error);
            }
        };

        fetchUserLang();
    }, []);

    return (
        <div className="space-y-6 mt-6">
            <CollapsibleSection title={t('Block Averages')}>
                <BlockAveragesTable
                    averages={averages}
                    template={template}
                    userLang={userLang}
                    t={t}
                    isReversed={isReversed}
                />
            </CollapsibleSection>

            {collectionType === 'individual' && (
                <CollapsibleSection title={t('Question Averages')}>
                    <QuestionAverageTable
                        responses={responses}
                        template={template}
                        question={question}
                        userLang={userLang}
                        t={t}
                        isReversed={isReversed}
                    />
                </CollapsibleSection>
            )}

            <CollapsibleSection title={t('Responder Analysis')}>
                <ResponderAnalysisTable
                    userStats={userStats}
                    getUserName={getUserNameForDisplay}
                    t={t}
                    isReversed={isReversed}
                />
            </CollapsibleSection>

            <CollapsibleSection title={t('Individual Responses')}>
                <IndividualResponsesTable
                    responses={processResponsesForTable()}
                    getQuestionText={getQuestionText}
                    getUserName={getUserNameForDisplay}
                    t={t}
                    isReversed={isReversed}
                />
            </CollapsibleSection>
        </div>
    );
};

export default DataAnalysisSection;