const ResponderAnalysisTable = ({ userStats, getUserName, t, isReversed }) => {
    // Get only the highest and lowest scorer
    const highestResponder = userStats[0]; // Already sorted by average in descending order
    const lowestResponder = userStats[userStats.length - 1];

    const getColorClass = (isHighest) => {
        if (isReversed) {
            return isHighest ? 'text-red-600' : 'text-green-600';
        }
        return isHighest ? 'text-green-600' : 'text-red-600';
    };

    const formatResponderName = (responder) => {
        if (responder.isAnonymous) {
            return t('Anonymous User {{number}}', { 
                number: parseInt(responder.userId.split('_')[1]) + 1 
            });
        }
        return getUserName(responder.userId);
    };

    return (
        <table className="min-w-full divide-y divide-gray-200">
            <thead>
                <tr>
                    <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">
                        {t('User')}
                    </th>
                    <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">
                        {t('Average Score')}
                    </th>
                    <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">
                        {t('Maximum Scores')}
                    </th>
                    <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">
                        {t('Minimum Scores')}
                    </th>
                    <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">
                        {t('Responses')}
                    </th>
                </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
                <tr className="bg-gray-50">
                    <td className="px-4 py-2 text-sm">{formatResponderName(highestResponder)}</td>
                    <td className={`px-4 py-2 text-sm font-medium ${getColorClass(true)}`}>
                        {highestResponder.average.toFixed(2)}
                    </td>
                    <td className="px-4 py-2 text-sm font-semibold">{highestResponder.maxScores}</td>
                    <td className="px-4 py-2 text-sm">{highestResponder.minScores}</td>
                    <td className="px-4 py-2 text-sm">{highestResponder.responsesCount}</td>
                </tr>
                <tr>
                    <td colSpan="5" className="px-4 py-2 text-center text-sm text-gray-500">
                        {t('...')}
                    </td>
                </tr>
                <tr className="bg-white">
                    <td className="px-4 py-2 text-sm">{formatResponderName(lowestResponder)}</td>
                    <td className={`px-4 py-2 text-sm font-medium ${getColorClass(false)}`}>
                        {lowestResponder.average.toFixed(2)}
                    </td>
                    <td className="px-4 py-2 text-sm">{lowestResponder.maxScores}</td>
                    <td className="px-4 py-2 text-sm font-semibold">{lowestResponder.minScores}</td>
                    <td className="px-4 py-2 text-sm">{lowestResponder.responsesCount}</td>
                </tr>
            </tbody>
        </table>
    );
};

export default ResponderAnalysisTable;