import React from 'react';
import { getTranslatedValue } from '../../../utils/templateTranslations';
import { ArrowUpIcon, ArrowUpRightIcon, ArrowRightIcon, ArrowDownRightIcon, ArrowDownIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';

const getTrafficLightColor = (value, questionType, logic, optionsLength) => {
    if (questionType === 'boolean') {
      let boolVal = value;
      if (typeof boolVal === 'string') {
        const lowerVal = boolVal.toLowerCase();
        if (lowerVal === 'yes' || lowerVal === 'true') boolVal = true;
        else if (lowerVal === 'no' || lowerVal === 'false') boolVal = false;
        else boolVal = undefined;
      }
  
      if (logic === 'one_best') {
        return boolVal === true ? 'bg-green-600' : (boolVal === false ? 'bg-red-600' : 'bg-gray-400');
      } else {
        return boolVal === true ? 'bg-red-600' : (boolVal === false ? 'bg-green-600' : 'bg-gray-400');
      }
    }
  
    if (questionType === 'radio') {
      const index = typeof value === 'number' ? value : undefined;
  
      if (logic === 'one_best') {
        if (optionsLength === 3) {
          switch (index) {
            case 0: return 'bg-green-600';
            case 1: return 'bg-lime-400';
            case 2: return 'bg-red-600';
            default: return 'bg-gray-400';
          }
        } else {
          switch (index) {
            case 0: return 'bg-green-600';
            case 1: return 'bg-lime-400';
            case 2: return 'bg-yellow-400';
            case 3: return 'bg-orange-400';
            case 4: return 'bg-red-600';
            default: return 'bg-gray-400';
          }
        }
      } else if (logic === 'middle_best') {
        const middle = Math.floor(optionsLength / 2);
        if (typeof index !== 'number') return 'bg-gray-400';
  
        const distance = Math.abs(middle - index);
        switch (distance) {
          case 0: return 'bg-green-600';
          case 1: return 'bg-yellow-400';
          default: return 'bg-red-600';
        }
      }
    }
  
    return 'bg-gray-400';
  };
  
  const getBackgroundColor = (trafficLightColor) => {
    switch (trafficLightColor) {
      case 'bg-green-600': return 'bg-green-50';
      case 'bg-red-600': return 'bg-red-50';
      case 'bg-lime-400': return 'bg-lime-50';
      case 'bg-yellow-400': return 'bg-yellow-50';
      case 'bg-orange-400': return 'bg-orange-50';
      default: return 'bg-gray-50';
    }
  };
  
  const getArrowComponent = (color) => {
    switch (color) {
      case 'bg-green-600': return ArrowUpIcon;
      case 'bg-lime-400': return ArrowUpRightIcon;
      case 'bg-yellow-400': return ArrowRightIcon;
      case 'bg-orange-400': return ArrowDownRightIcon;
      case 'bg-red-600': return ArrowDownIcon;
      default: return null;
    }
  };
  
  const TrafficLightDot = ({ value, question, size = 'h-8 w-8' }) => {
    const color = getTrafficLightColor(
      value,
      question.type,
      question.traffic_light_logic,
      question.options ? Object.values(question.options)[0].length : 2
    );
    
    const Arrow = getArrowComponent(color);
    
    // Get appropriate arrow size class based on container size
    const getArrowClasses = () => {
      if (size.includes('h-12')) return 'w-7 h-7';
      if (size.includes('h-8')) return 'w-4 h-4';
      if (size.includes('h-6')) return 'w-3 h-3';
      return 'w-4 h-4'; // default size
    };
  
    return (
      <div className={`${size} rounded-full ${color} flex items-center justify-center`}>
        {Arrow && <Arrow className={`${getArrowClasses()} text-white stroke-[3]`} />}
      </div>
    );
  };
  
  const TrafficLightCard = ({
    question,
    collection,
    comparisonData = [],
    language = 'en'
  }) => {
    const { t } = useTranslation(); 

    const findLatestResponseForQuestion = (questionId, responses) => {
      for (let i = responses.length - 1; i >= 0; i--) {
        const respVal = responses[i]?.response_data?.[questionId];
        const noteVal = responses[i]?.notes_data?.[questionId];
        if (respVal !== undefined) {
          return { value: respVal, note: noteVal };
        }
      }
      return { value: undefined, note: undefined };
    };
  
    const currentResponse = findLatestResponseForQuestion(question.id, collection.responses);
    const trafficLightColor = getTrafficLightColor(
      currentResponse.value,
      question.type,
      question.traffic_light_logic,
      question.options ? Object.values(question.options)[0].length : 2
    );
  
    return (
      <div className="w-full">
        <div className={`rounded-lg shadow-sm border ${getBackgroundColor(trafficLightColor)}`}>
          <div className="flex p-6">
            {/* Left Column - Question and Notes */}
            <div className="w-2/3 pr-6">
              <h3 className="text-base font-semibold text-gray-900">
                {getTranslatedValue(question.text, language)}
              </h3>
              {currentResponse.note && (
                <p className="mt-4 text-sm text-gray-600">
                  {currentResponse.note}
                </p>
              )}
            </div>
  
            {/* Right Column - Traffic Lights */}
            <div className="w-1/3 flex flex-col items-center">
              {/* Current Value */}
              <div className="flex flex-col items-center">
                <TrafficLightDot 
                  value={currentResponse.value} 
                  question={question} 
                  size="h-12 w-12"
                />
                <span className="text-sm text-gray-500 mt-2">{t('Current')}</span>
              </div>
  
              {/* Comparison Values */}
              {comparisonData.length > 0 && (
                <div className="flex flex-col items-center w-full mt-4">
                  <div className="h-px w-full bg-gray-200 mb-4" />
                  <div className="flex flex-row gap-2 flex-wrap justify-center">
                    {comparisonData.map((comparison, idx) => {
                      const comparisonValue = comparison.responses?.comparison?.[question.id]?.resolved_value;
                      return (
                        <div key={idx} className="flex flex-col items-center">
                          <TrafficLightDot 
                            value={comparisonValue} 
                            question={question} 
                            size="h-6 w-6"
                          />
                          <span className="text-xs text-gray-500 mt-1">
                            {comparison.collection_info.period_value}
                          </span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };
  
  export default TrafficLightCard;