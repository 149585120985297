// src/components/reports/CreateCollection/ProgressTracker.js
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { motion, AnimatePresence } from 'framer-motion';

const ProgressTracker = ({ steps, currentStep, setCurrentStep }) => {
  const { t } = useTranslation();
  const [expandedStep, setExpandedStep] = useState(null);

  const handleStepClick = (stepId) => {
    if (stepId === currentStep) return;
    if (expandedStep === stepId) {
      // If step is expanded, navigate to it if allowed
      if (stepId < currentStep) {
        // Only allow going back to previous steps
        setCurrentStep(stepId);
      }
    } else {
      // If step is not expanded, expand it
      setExpandedStep(stepId);
    }
  };

  return (
    <nav aria-label="Progress" className="overflow-x-auto">
      {/* Desktop version */}
      <ol role="list" className="hidden lg:flex lg:space-x-8">
        {steps.map((step) => (
          <li key={step.name} className="lg:flex-1">
            <div
              className={`group flex flex-col border-t-4 pb-6 pt-4 ${
                currentStep === step.id
                  ? 'border-primary-color'
                  : 'border-gray-200 hover:border-gray-300'
              }`}
            >
              <span className={`text-xs font-medium ${
                currentStep === step.id
                  ? 'text-primary-color'
                  : 'text-gray-500 group-hover:text-primary-color'
              }`}>
                {t(`CreateCollection.step${step.id}`)}
              </span>
              <span className={`text-xs font-bold ${
                currentStep === step.id
                  ? 'text-black'
                  : 'text-gray-500 group-hover:text-gray-700'
              }`}>
                {t(step.name)}
              </span>
            </div>
          </li>
        ))}
      </ol>

      {/* Mobile version */}
      <ol role="list" className="lg:hidden flex items-center px-2 mt-8">
        {steps.map((step) => {
          const isActive = currentStep === step.id;
          const isExpanded = expandedStep === step.id;

          return (
            <li key={step.name} className="flex-shrink-0">
              <motion.div
                className="flex items-center"
                initial={false}
                animate={{ width: isActive || isExpanded ? 'auto' : '20px' }}
                transition={{ duration: 0.3 }}
              >
                <button
                  onClick={() => handleStepClick(step.id)}
                  className={`flex items-center ${isActive ? 'cursor-default' : 'cursor-pointer'}`}
                >
                  <div
                    className={`flex items-center rounded-full transition-colors ${
                      isActive
                        ? 'h-5 bg-primary-color mr-2'
                        : isExpanded
                          ? 'h-5 bg-gray-400 hover:bg-gray-300 mr-2'
                          : 'w-3 h-3 bg-gray-400 hover:bg-gray-300'
                    }`}
                  >
                    <AnimatePresence>
                      {(isActive || isExpanded) && (
                        <motion.div
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          exit={{ opacity: 0 }}
                          transition={{ duration: 0.2 }}
                          className="flex items-center space-x-2 px-3"
                        >
                          <span className={`text-xs font-bold whitespace-nowrap ${
                            isActive ? 'text-white' : 'text-gray-700'
                          }`}>
                            {t(step.name)}
                          </span>
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </div>
                </button>
              </motion.div>
            </li>
          );
        })}
      </ol>
    </nav>
  );
};

export default ProgressTracker;