import React, { useContext, useEffect } from 'react';
import { AuthContext } from '../context/AuthContext';
import { TaskContext } from '../context/TaskContext';
import KanbanBoard from '../components/KanbanBoard';

export default function Tasks() {
  const { user } = useContext(AuthContext);
  const { resetNewTasks } = useContext(TaskContext);

  useEffect(() => {
    resetNewTasks(); // Reset the counter when the Tasks page is visited
  }, []);

  return (
    <div className="container mx-auto px-4 lg:py-8 py-16">
      <KanbanBoard />
    </div>
  );
}