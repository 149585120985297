import React from 'react';
import Chart from 'react-apexcharts';
import { getTranslatedValue } from '../../../utils/templateTranslations';
import { useTranslation } from 'react-i18next';

const StockColumnChart = ({
    question,
    collection,
    comparisonData = [],
    language = 'en'
}) => {
    const { t } = useTranslation();

    console.log('StockColumnChart - component props:', {
        question,
        collection,
        comparisonData
    });

    // Function to process a single collection's response data
    const processCollectionData = (collectionData, isComparison = false) => {
        console.log('Processing collection data:', {
            collectionData,
            isComparison,
            questionType: question.type
        });

        // Since we know this is a multiple_numbers type question
        let responseData;
        if (isComparison) {
            responseData = collectionData.responses?.comparison?.inventory_value?.components || {};
        } else {
            const response = collectionData.responses?.find(response =>
                response.response_data?.inventory_value !== undefined
            )?.response_data?.inventory_value;
            responseData = response || {};
        }

        console.log('Found response data:', responseData);

        // Get values for each component and total
        const total = parseFloat(responseData.total?.value) || 0;
        const component1 = parseFloat(responseData.component1?.value) || 0;
        const component2 = parseFloat(responseData.component2?.value) || 0;
        const component3 = parseFloat(responseData.component3?.value) || 0;
        const component4 = parseFloat(responseData.component4?.value) || 0;
        const component5 = parseFloat(responseData.component5?.value) || 0;

        console.log('Parsed component values:', {
            total,
            component1,
            component2,
            component3,
            component4,
            component5
        });

        // Calculate other as total minus sum of components
        const componentsSum = component1 + component2 + component3 + component4 + component5;
        const other = Math.max(0, total - componentsSum);

        // Get component labels from the question template
        const comp1Label = responseData.component1?.text || getTranslatedValue(question.labels.component1, language);
        const comp2Label = responseData.component2?.text || getTranslatedValue(question.labels.component2, language);
        const comp3Label = responseData.component3?.text || getTranslatedValue(question.labels.component3, language);
        const comp4Label = responseData.component4?.text || getTranslatedValue(question.labels.component4, language);
        const comp5Label = responseData.component5?.text || getTranslatedValue(question.labels.component5, language);

        const result = {
            values: [component1, component2, component3, component4, component5, other],
            labels: [comp1Label, comp2Label, comp3Label, comp4Label, comp5Label, t('Rest')]
        };

        console.log('Processed result:', result);
        return result;
    };

    // Process current and comparison data
    const currentData = processCollectionData(collection);
    const comparisonDataProcessed = comparisonData.map((comp, index) => {
        console.log(`Processing comparison data ${index}:`, comp);
        return processCollectionData(comp, true);
    });

    // Prepare series data
    const series = currentData.labels.map((label, index) => ({
        name: label || `Component ${index + 1}`,
        data: [
            currentData.values[index],
            ...comparisonDataProcessed.map(comp => comp.values[index])
        ]
    }));

    // Prepare categories (x-axis labels)
    const categories = [
        t('Current'),
        ...comparisonData.map(comp => comp.collection_info.period_value)
    ];

    const options = {
        chart: {
            type: 'bar',
            stacked: true,
            toolbar: {
                show: false
            },
            animations: {
                enabled: true,
                speed: 800,
                animateGradually: {
                    enabled: true,
                    delay: 150
                },
                dynamicAnimation: {
                    enabled: true,
                    speed: 350
                }
            }
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '50%',
                borderRadius: 10,
                borderRadiusApplication: 'end',
                borderRadiusWhenStacked: 'last',
            },
        },
        xaxis: {
            categories,
            labels: {
                style: {
                    colors: '#6b7280',
                    fontSize: '12px'
                }
            }
        },
        yaxis: {
            title: {
                text: getTranslatedValue(question.units, language),
                style: {
                    color: '#6b7280'
                }
            },
            labels: {
                style: {
                    colors: '#6b7280'
                },
                formatter: (value) => value.toLocaleString()
            }
        },
        colors: [
            '#264653', // Component 1
            '#287271', // Component 2
            '#2A9D8F', // Component 3
            '#69BCB6', // Component 4
            '#A8DBDD', // Component 5
            '#B6B9BD', // Other
        ],
        legend: {
            position: 'bottom',
            horizontalAlign: 'center',
            labels: {
                colors: '#6b7280'
            }
        },
        dataLabels: {
            enabled: true,
            formatter: function (val) {
                return val.toLocaleString();
            }
        },
        theme: {
            mode: 'light'
        },
        tooltip: {
            y: {
                formatter: (value) => `${value.toLocaleString()} ${getTranslatedValue(question.units, language)}`
            }
        }
    };

    return (
        <div className="w-full bg-white rounded-lg shadow-sm">
            <div className="p-6">
                <Chart
                    options={options}
                    series={series}
                    type="bar"
                    height={500}
                    width="100%"
                />
            </div>
        </div>
    );
};

export default StockColumnChart;