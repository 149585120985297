// translationMappings.js

/**
 * Utility function to normalize the language code
 * It maps any variation of 'en-*' to 'en' and 'de-*' to 'de'
 */
const normalizeLanguageCode = (languageCode) => {
  if (languageCode.startsWith('en')) {
    return 'en';
  } else if (languageCode.startsWith('de')) {
    return 'de';
  }
  return 'en'; // Default to 'en' if any other language is detected
};

/**
 * Storage constants - these are the values that get stored in the database
 */
export const BOOLEAN_VALUES = {
  YES: 'YES',
  NO: 'NO'
};
Object.freeze(BOOLEAN_VALUES);

export const MEETING_LOCATIONS = {
  STATUTORY_SEAT: 'STATUTORY_SEAT',
  REMOTE: 'REMOTE',
  ANYWHERE: 'ANYWHERE'
};
Object.freeze(MEETING_LOCATIONS);

export const EXTRAORDINARY_RESOLUTIONS = {
  INSOLVENCY: 'INSOLVENCY',
  ARTICLES_AMENDMENT: 'ARTICLES_AMENDMENT',
  CAPITAL_MEASURES: 'CAPITAL_MEASURES',
  FINANCIAL_ACCOUNTS: 'FINANCIAL_ACCOUNTS',
  MANAGEMENT_BOARD: 'MANAGEMENT_BOARD',
  MANAGING_DIRECTORS: 'MANAGING_DIRECTORS',
  LIQUIDATION: 'LIQUIDATION',
  MERGER_ACQUISITION: 'MERGER_ACQUISITION',
  ENTERPRISE_AGREEMENTS: 'ENTERPRISE_AGREEMENTS',
  ASSET_DISPOSAL: 'ASSET_DISPOSAL',
  STOCK_EXCHANGE: 'STOCK_EXCHANGE',
  SHARE_ACQUISITION: 'SHARE_ACQUISITION',
  SHARE_OPERATIONS: 'SHARE_OPERATIONS',
  CONVERTIBLE_RIGHTS: 'CONVERTIBLE_RIGHTS',
  MANAGEMENT_RULES: 'MANAGEMENT_RULES',
  SUBSIDIARY_MEASURES: 'SUBSIDIARY_MEASURES',
  EMPLOYEE_INCENTIVE: 'EMPLOYEE_INCENTIVE'
};
Object.freeze(EXTRAORDINARY_RESOLUTIONS);

/**
 * Maps UI values to storage values
 * This ensures consistent storage regardless of UI language
 */
export const UI_TO_STORAGE_MAP = {
  // Boolean mappings
  'Ja': BOOLEAN_VALUES.YES,
  'Nein': BOOLEAN_VALUES.NO,
  'Yes': BOOLEAN_VALUES.YES,
  'No': BOOLEAN_VALUES.NO,

  // Meeting locations mappings
  'at the statutory seat of the company': MEETING_LOCATIONS.STATUTORY_SEAT,
  'am Sitz der Gesellschaft': MEETING_LOCATIONS.STATUTORY_SEAT,
  'via telephone or video conference': MEETING_LOCATIONS.REMOTE,
  'per Telefon oder Videokonferenz': MEETING_LOCATIONS.REMOTE,
  'at any location with the consent of all shareholders': MEETING_LOCATIONS.ANYWHERE,
  'an jedem Ort mit Zustimmung aller Gesellschafter': MEETING_LOCATIONS.ANYWHERE,
};

/**
 * Maps storage values to document values by language
 * Used when generating documents or displaying stored values
 */
export const STORAGE_TO_DOCUMENT_MAP = {
  en: {
    // Boolean values
    [BOOLEAN_VALUES.YES]: 'Yes',
    [BOOLEAN_VALUES.NO]: 'No',

    // Meeting locations
    [MEETING_LOCATIONS.STATUTORY_SEAT]: 'at the statutory seat of the company',
    [MEETING_LOCATIONS.REMOTE]: 'via telephone or video conference',
    [MEETING_LOCATIONS.ANYWHERE]: 'at any location with the consent of all shareholders',

    // Extraordinary resolutions
    [EXTRAORDINARY_RESOLUTIONS.INSOLVENCY]: 'Application for the opening of insolvency proceedings over the assets of the company',
    [EXTRAORDINARY_RESOLUTIONS.ARTICLES_AMENDMENT]: "Any amendment of the company's articles of association",
    [EXTRAORDINARY_RESOLUTIONS.CAPITAL_MEASURES]: 'Any capital measures',
    [EXTRAORDINARY_RESOLUTIONS.FINANCIAL_ACCOUNTS]: 'Approval of the annual financial accounts',
    [EXTRAORDINARY_RESOLUTIONS.MANAGEMENT_BOARD]: 'All measures concerning the management board',
    [EXTRAORDINARY_RESOLUTIONS.MANAGING_DIRECTORS]: 'Granting discharge to the managing directors',
    [EXTRAORDINARY_RESOLUTIONS.LIQUIDATION]: 'Liquidating, dissolving or winding up the affairs of the company',
    [EXTRAORDINARY_RESOLUTIONS.MERGER_ACQUISITION]: 'Effecting any type of merger or acquisition',
    [EXTRAORDINARY_RESOLUTIONS.ENTERPRISE_AGREEMENTS]: 'Conclusion, amendment and termination of enterprise agreements',
    [EXTRAORDINARY_RESOLUTIONS.ASSET_DISPOSAL]: "Sale, lease, exclusive licensing, transfer or other disposal of all or significant parts of the company's assets",
    [EXTRAORDINARY_RESOLUTIONS.STOCK_EXCHANGE]: 'Request for admission of shares in the company to stock exchange trading',
    [EXTRAORDINARY_RESOLUTIONS.SHARE_ACQUISITION]: 'Acquisition of own shares by the Company',
    [EXTRAORDINARY_RESOLUTIONS.SHARE_OPERATIONS]: 'Split, merger and redemption of shares',
    [EXTRAORDINARY_RESOLUTIONS.CONVERTIBLE_RIGHTS]: 'Any grant of rights or issuance of instruments with a right to convert into or exchange for shares in the company',
    [EXTRAORDINARY_RESOLUTIONS.MANAGEMENT_RULES]: 'Adoption, amendment and cancellation of rules of procedure for the management board of the company',
    [EXTRAORDINARY_RESOLUTIONS.SUBSIDIARY_MEASURES]: 'Any of the measures above if undertaken on the level of a subsidiary',
    [EXTRAORDINARY_RESOLUTIONS.EMPLOYEE_INCENTIVE]: 'Implementation of an employee incentive plan of the company'
  },
  de: {
    // Boolean values
    [BOOLEAN_VALUES.YES]: 'Ja',
    [BOOLEAN_VALUES.NO]: 'Nein',

    // Meeting locations
    [MEETING_LOCATIONS.STATUTORY_SEAT]: 'am Sitz der Gesellschaft',
    [MEETING_LOCATIONS.REMOTE]: 'per Telefon oder Videokonferenz',
    [MEETING_LOCATIONS.ANYWHERE]: 'an jedem Ort mit Zustimmung aller Gesellschafter',

    // Extraordinary resolutions
    [EXTRAORDINARY_RESOLUTIONS.INSOLVENCY]: 'Antrag auf Eröffnung eines Insolvenzverfahrens über das Vermögen der Gesellschaft',
    [EXTRAORDINARY_RESOLUTIONS.ARTICLES_AMENDMENT]: 'Jede Änderung der Satzung der Gesellschaft',
    [EXTRAORDINARY_RESOLUTIONS.CAPITAL_MEASURES]: 'Alle Kapitalmaßnahmen',
    [EXTRAORDINARY_RESOLUTIONS.FINANCIAL_ACCOUNTS]: 'Feststellung des Jahresabschlusses',
    [EXTRAORDINARY_RESOLUTIONS.MANAGEMENT_BOARD]: 'Alle Maßnahmen bezüglich der Geschäftsführung',
    [EXTRAORDINARY_RESOLUTIONS.MANAGING_DIRECTORS]: 'Entlastung der Geschäftsführer',
    [EXTRAORDINARY_RESOLUTIONS.LIQUIDATION]: 'Liquidation, Auflösung oder Abwicklung der Gesellschaft',
    [EXTRAORDINARY_RESOLUTIONS.MERGER_ACQUISITION]: 'Durchführung jeglicher Art von Fusion oder Übernahme',
    [EXTRAORDINARY_RESOLUTIONS.ENTERPRISE_AGREEMENTS]: 'Abschluss, Änderung und Beendigung von Unternehmensverträgen',
    [EXTRAORDINARY_RESOLUTIONS.ASSET_DISPOSAL]: 'Verkauf, Vermietung, exklusive Lizenzierung, Übertragung oder sonstige Verfügung über alle oder wesentliche Teile des Gesellschaftsvermögens',
    [EXTRAORDINARY_RESOLUTIONS.STOCK_EXCHANGE]: 'Antrag auf Zulassung von Gesellschaftsanteilen zum Börsenhandel',
    [EXTRAORDINARY_RESOLUTIONS.SHARE_ACQUISITION]: 'Erwerb eigener Anteile durch die Gesellschaft',
    [EXTRAORDINARY_RESOLUTIONS.SHARE_OPERATIONS]: 'Teilung, Zusammenlegung und Einziehung von Geschäftsanteilen',
    [EXTRAORDINARY_RESOLUTIONS.CONVERTIBLE_RIGHTS]: 'Gewährung von Rechten oder Ausgabe von Instrumenten mit einem Recht zur Umwandlung in oder zum Umtausch gegen Gesellschaftsanteile',
    [EXTRAORDINARY_RESOLUTIONS.MANAGEMENT_RULES]: 'Erlass, Änderung und Aufhebung der Geschäftsordnung für die Geschäftsführung der Gesellschaft',
    [EXTRAORDINARY_RESOLUTIONS.SUBSIDIARY_MEASURES]: 'Alle oben genannten Maßnahmen, wenn sie auf der Ebene einer Tochtergesellschaft durchgeführt werden',
    [EXTRAORDINARY_RESOLUTIONS.EMPLOYEE_INCENTIVE]: 'Einführung eines Mitarbeiterbeteiligungsprogramms der Gesellschaft'
  }
};

/**
 * Utility functions for converting between UI, storage, and document values
 */
export const convertUIToStorage = (uiValue) => {
  return UI_TO_STORAGE_MAP[uiValue] || uiValue;
};

export const convertStorageToDocument = (storageValue, language = 'en') => {
  const normalizedLanguage = normalizeLanguageCode(language);
  return STORAGE_TO_DOCUMENT_MAP[normalizedLanguage]?.[storageValue] || storageValue;
};

/**
 * Helper function to get all options for a specific field in the current language
 */
export const getOptionsForField = (field, language = 'en') => {
  const normalizedLanguage = normalizeLanguageCode(language);
  switch (field) {
    case 'meetingLocations':
      return Object.values(MEETING_LOCATIONS).map(location => ({
        value: location,
        label: STORAGE_TO_DOCUMENT_MAP[normalizedLanguage][location]
      }));
    case 'extraordinaryResolutions':
      return Object.values(EXTRAORDINARY_RESOLUTIONS).map(resolution => ({
        value: resolution,
        label: STORAGE_TO_DOCUMENT_MAP[normalizedLanguage][resolution]
      }));
    case 'boolean':
      return [
        { value: BOOLEAN_VALUES.YES, label: STORAGE_TO_DOCUMENT_MAP[normalizedLanguage][BOOLEAN_VALUES.YES] },
        { value: BOOLEAN_VALUES.NO, label: STORAGE_TO_DOCUMENT_MAP[normalizedLanguage][BOOLEAN_VALUES.NO] }
      ];
    default:
      return [];
  }
};