import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { initI18n } from './i18n';
import Glossary from './Glossary';
import { TaskProvider } from './context/TaskContext';

const LoadingFallback = () => (
  <div className="flex items-center justify-center h-screen">
    <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900"></div>
  </div>
);

initI18n().then(() => {
  const root = ReactDOM.createRoot(document.getElementById('root'));
  root.render(
    <Glossary>
      <TaskProvider>
        <Suspense fallback={<LoadingFallback />}>
          <App />
        </Suspense>
      </TaskProvider>
    </Glossary>
  );
});