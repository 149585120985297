import React from 'react';
import Chart from 'react-apexcharts';
import { getTranslatedValue } from '../../../utils/templateTranslations';
import { useTranslation } from 'react-i18next';

const Column100Chart = ({
  question,
  collection,
  comparisonData = [],
  language = 'en'
}) => {
  const { t } = useTranslation();

  // Function to process a single collection's response data
  const processCollectionData = (collectionData, isComparison = false) => {
    let responseData;
    if (isComparison) {
      // We need to look for the value in the comparison structure correctly
      responseData = collectionData.responses?.comparison?.on_time_delivery?.value ||
                    collectionData.responses?.comparison?.on_time_delivery?.average || 0;
      console.log('Comparison response data:', responseData);
    } else {
      const response = collectionData.responses?.find(response => 
        response.response_data?.on_time_delivery !== undefined
      )?.response_data?.on_time_delivery;
      responseData = response || 0;
      console.log('Current response data:', responseData);
    }

    // Ensure the value is between 0 and 100
    const value = Math.min(Math.max(parseFloat(responseData) || 0, 0), 100);
    return value;
  };

  // Process current and comparison data
  const currentData = processCollectionData(collection);
  const comparisonDataProcessed = comparisonData.map(comp => 
    processCollectionData(comp, true)
  );

  // Prepare series data - each column will have two parts:
  // 1. The actual percentage (green)
  // 2. The remainder to 100% (red)
  const series = [
    {
      name: t('On Time'),
      data: [
        currentData,
        ...comparisonDataProcessed
      ]
    },
    {
      name: t('Delayed'),
      data: [
        100 - currentData,
        ...comparisonDataProcessed.map(value => 100 - value)
      ]
    }
  ];

  // Prepare categories (x-axis labels)
  const categories = [
    t('Current'),
    ...comparisonData.map(comp => comp.collection_info.period_value)
  ];

  const options = {
    chart: {
      type: 'bar',
      stacked: true,
      stackType: '100%',
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
      }
    },
    xaxis: {
      categories,
      labels: {
        style: {
          colors: '#6b7280',
          fontSize: '12px'
        }
      }
    },
    yaxis: {
      labels: {
        style: {
          colors: '#6b7280'
        },
        formatter: (value) => `${value.toFixed(1)}%`
      }
    },
    colors: [
      '#22c55e', // Green for on-time
      '#ef4444'  // Red for delayed
    ],
    legend: {
      position: 'bottom',
      horizontalAlign: 'center',
      labels: {
        colors: '#6b7280'
      }
    },
    dataLabels: {
      enabled: true,
    },
    theme: {
      mode: 'light'
    },
    tooltip: {
      y: {
        formatter: (value) => `${value.toFixed(1)}%`
      }
    }
  };

  return (
    <div className="w-full bg-white rounded-lg shadow-sm">
      <div className="p-6">
        <h3 className="text-lg font-semibold mb-6 text-gray-900">
          {getTranslatedValue(question.text, language)}
        </h3>
        <Chart
          options={options}
          series={series}
          type="bar"
          height={500}
          width="100%"
        />
      </div>
    </div>
  );
};

export default Column100Chart;