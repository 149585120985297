import React from 'react';
import { RadioGroup } from '@headlessui/react';
import { getTranslatedValue } from '../../../utils/templateTranslations';

const BooleanQuestion = ({
  question,
  value,
  onChange,
  notes,
  onNotesChange,
  showNotes,
  notesLabel,
  language,
  readOnly
}) => {
  const options = getTranslatedValue(question.options, language);
  
  // Convert text value to boolean if needed (for backward compatibility)
  const boolValue = typeof value === 'boolean' ? value : value?.toLowerCase() === options[0].toLowerCase();

  return (
    <div className="space-y-4 mt-10">
      <div className="text-base font-medium text-gray-900">
        {getTranslatedValue(question.text, language)}
      </div>

      <RadioGroup
        value={boolValue}
        onChange={val => onChange(val)}
        className="mt-2"
        disabled={readOnly}
      >
        <div className="flex space-x-4">
          {[true, false].map((optionValue, index) => {
            const isSelected = boolValue === optionValue;
            return (
              <RadioGroup.Option
                key={index}
                value={optionValue}
                className={({ checked }) => `
                  relative flex cursor-pointer rounded-lg px-5 py-0.5 border focus:outline-none
                  ${readOnly ? '' : 'hover:bg-gray-50 hover:border-gray-300'}
                  ${
                    readOnly
                      ? isSelected
                        ? 'bg-gray-400 text-white border-gray-400 cursor-default'
                        : 'bg-gray-200 text-gray-700 border-gray-200 cursor-default'
                      : checked
                        ? 'bg-secondary-color text-white border-secondary-color'
                        : 'bg-white text-gray-900 border-gray-200'
                  }
                `}
              >
                {({ checked }) => (
                  <div className="flex items-center justify-between w-full">
                    <div className="flex items-center">
                      <div className="text-sm">
                        <RadioGroup.Label
                          as="p"
                          className={`font-medium ${
                            checked ? 'text-white' : 'text-gray-900'
                          }`}
                        >
                          {options[optionValue ? 0 : 1]}
                        </RadioGroup.Label>
                      </div>
                    </div>
                  </div>
                )}
              </RadioGroup.Option>
            );
          })}
        </div>
      </RadioGroup>

      {showNotes && (
        <div className="mt-4">
          <label className="block text-sm font-medium text-gray-700">
            {notesLabel}
          </label>
          <textarea
            value={notes || ''}
            onChange={(e) => onNotesChange(e.target.value)}
            rows={3}
            disabled={readOnly}
            className={`mt-1 block w-full rounded-md shadow-sm sm:text-sm ${
              readOnly
                ? 'bg-gray-200 text-gray-700 border-gray-200 cursor-default'
                : 'border-gray-300 focus:border-secondary-color focus:ring-secondary-color'
            }`}
          />
        </div>
      )}
    </div>
  );
};

export default BooleanQuestion;