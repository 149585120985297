import React, { useState, useEffect } from 'react';
import { Switch } from '@headlessui/react';
import { useTranslation } from 'react-i18next';

export default function LiveModeToggle({ onToggle }) {
  const { t } = useTranslation();
  const [enabled, setEnabled] = useState(() => {
    const savedState = localStorage.getItem('liveModeEnabled');
    return savedState === 'true';
  });

  // Check if screen is mobile using a more reliable method
  useEffect(() => {
    const checkMobile = () => {
      // Use visual viewport width instead of window.innerWidth
      const viewportWidth = window.visualViewport?.width || window.innerWidth;
      const isMobile = viewportWidth < 768;
      
      if (isMobile && enabled) {
        setEnabled(false);
      }
    };

    // Check initially
    checkMobile();

    // Set up multiple listeners to catch all possible viewport changes
    window.visualViewport?.addEventListener('resize', checkMobile);
    window.addEventListener('resize', checkMobile);
    
    // Force a recheck after any potential animations
    const recheckTimeout = setTimeout(checkMobile, 1000);

    // Cleanup
    return () => {
      window.visualViewport?.removeEventListener('resize', checkMobile);
      window.removeEventListener('resize', checkMobile);
      clearTimeout(recheckTimeout);
    };
  }, [enabled]);

  useEffect(() => {
    localStorage.setItem('liveModeEnabled', enabled);
    onToggle(enabled);
  }, [enabled, onToggle]);

  const handleToggle = (newState) => {
    setEnabled(newState);
  };

  return (
    <div className="fixed top-3 right-4 z-40 bg-white hidden md:flex items-center space-x-2">
      <Switch
        checked={enabled}
        onChange={handleToggle}
        className={`${
          enabled ? 'bg-primary-color' : 'bg-gray-200'
        } relative inline-flex h-5 w-9 flex-shrink-0 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-primary-color focus:ring-offset-2`}
      >
        <span
          className={`${
            enabled ? 'translate-x-5' : 'translate-x-1'
          } inline-block h-3 w-3 transform rounded-full bg-white transition-transform`}
        />
      </Switch>
      <span className="text-xs whitespace-nowrap">
        <b>{t('Live modus')}</b>
        {t('(save drafts automatically)')}      
      </span>
    </div>
  );
}